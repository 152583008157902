import { BarChartDataPoint, BoxChartDataPoint, VisDataPoint } from '../types';
import { compareDates, getWeekday, WEEKDAYS, WEEKDAYS_ORDER } from './date.utils';

const median = (arr: any[]) => {
    const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export function visDataToChartData(data: VisDataPoint[], exchangeRate: number): BarChartDataPoint[] {
    return data.map((dataPoint) => {
        const { date } = dataPoint;
        let { priceOpt, priceOptMod } = dataPoint;

        if (exchangeRate !== 1) {
            priceOpt = exchangeRate * priceOpt;
            priceOptMod = exchangeRate * priceOptMod;
        }

        const priceOptDiff = priceOptMod - priceOpt;
        const barChartDataPoint: BarChartDataPoint = {
            date: date,
            daysBeforeDeparture: dataPoint.daysBeforeDeparture,
            dayBeforeDeparture: dataPoint.daysBeforeDeparture[0],
            currentPeriod: dataPoint.currentPeriod,
            alpha: Math.round(100 * (priceOptDiff / priceOpt)),
        };

        if (priceOptDiff === 0) {
            barChartDataPoint['base'] = priceOpt;
        } else if (priceOptDiff > 0) {
            barChartDataPoint['base'] = priceOpt;
            barChartDataPoint['increased'] = priceOptDiff;
        } else if (priceOptDiff < 0) {
            barChartDataPoint['base'] = priceOptMod;
            barChartDataPoint['decreased'] = -priceOptDiff;
        }

        return barChartDataPoint;
    });
}

export function visDataToWeekdayChartData(data: VisDataPoint[], exchangeRate: number): BoxChartDataPoint[] {
    const dataPerWeekday: Record<string, Record<string, number[]>> = {};
    for (const dataPoint of data) {
        if (!dataPoint.currentPeriod) continue;

        const weekday = getWeekday(dataPoint.date);
        let { priceOpt, priceOptMod } = dataPoint;

        if (exchangeRate !== 1) {
            priceOpt = exchangeRate * priceOpt;
            priceOptMod = exchangeRate * priceOptMod;
        }

        if (weekday in dataPerWeekday) {
            dataPerWeekday[weekday] = {
                priceOpt: dataPerWeekday[weekday].priceOpt.concat(priceOpt),
                priceOptMod: dataPerWeekday[weekday].priceOptMod.concat(priceOptMod),
            };
        } else {
            dataPerWeekday[weekday] = {
                priceOpt: [priceOpt],
                priceOptMod: [priceOptMod],
            };
        }
    }
    // Iterate over tempData to calculate max, min & median for each weekday
    const finalVisData: any[] = [];
    for (const weekday in dataPerWeekday) {
        const priceOpts = dataPerWeekday[weekday].priceOpt;
        const medianPriceOpt = median(priceOpts);
        const minPriceOpt = Math.min(...priceOpts);
        const maxPriceOpt = Math.max(...priceOpts);

        const priceOptMods = dataPerWeekday[weekday].priceOptMod;
        const medianPriceOptMod = median(priceOptMods);
        const minPriceOptMod = Math.min(...priceOptMods);
        const maxPriceOptMod = Math.max(...priceOptMods);

        finalVisData.push({
            weekday: weekday,
            medianPriceOpt: medianPriceOpt,
            minPriceOpt: minPriceOpt,
            maxPriceOpt: maxPriceOpt,
            range: [minPriceOpt, maxPriceOpt],
            medianPriceOptMod: medianPriceOptMod,
            minPriceOptMod: minPriceOptMod,
            maxPriceOptMod: maxPriceOptMod,
            rangeMod: [minPriceOptMod, maxPriceOptMod],
        });
    }

    const ret: any = {};
    Object.keys(WEEKDAYS).forEach((key: any) => {
        ret[WEEKDAYS[key]] = key;
    });
    finalVisData.sort(function (a, b) {
        return WEEKDAYS_ORDER[ret[a.weekday]] - WEEKDAYS_ORDER[ret[b.weekday]];
    });

    return finalVisData;
}

export function visDataToCurrentChartData(
    data: VisDataPoint[],
    selectedWeekday: string,
    exchangeRate: number,
): BarChartDataPoint[] {
    const currentData: VisDataPoint[] = [];
    data.forEach((dataPoint) => {
        if (dataPoint.currentPeriod) {
            if (getWeekday(dataPoint.date) === selectedWeekday || selectedWeekday === '') {
                currentData.push(dataPoint);
            }
        }
    });
    return visDataToChartData(currentData, exchangeRate);
}

export function visDataToPBPChartData(
    data: VisDataPoint[],
    selectedDate: Date | undefined,
    exchangeRate: number,
): BarChartDataPoint[] {
    const selecteDateData: VisDataPoint[] = [];
    if (selectedDate) {
        data.forEach((dataPoint) => {
            if (compareDates(selectedDate, dataPoint.date)) selecteDateData.push(dataPoint);
        });
    }
    return visDataToChartData(selecteDateData, exchangeRate);
}

export function findPriceDomain(data: VisDataPoint[], exchangeRate: number): [number, number] {
    if (data.length === 0) {
        return [0, 0];
    }

    const prices = [...data.map((item) => item.priceOpt), ...data.map((item) => item.priceOptMod)];
    return [0, Math.ceil(exchangeRate * Math.max(...prices))];
}

export function findAlphaDomain(data: BarChartDataPoint[]): [number, number] {
    const alphas = data.map((item) => item.alpha);
    return [Math.floor(Math.min(...alphas)), Math.ceil(Math.max(...alphas))];
}

export function removePastPBPs(data: VisDataPoint[]): VisDataPoint[] {
    if (data.length > 0) {
        // Assuming the data is ordered!
        data.sort((a, b) =>
            a.date === b.date
                ? a.daysBeforeDeparture[0] - b.daysBeforeDeparture[1]
                : a.date.getTime() - b.date.getTime(),
        );

        const selectedData: VisDataPoint[] = [];
        let tempDate = data[0].date;
        let tempCurrent = false;
        data.forEach((dataPoint) => {
            if (compareDates(tempDate, dataPoint.date)) {
                if (tempCurrent || dataPoint.currentPeriod) {
                    tempCurrent = true;
                    selectedData.push(dataPoint);
                }
            } else {
                tempDate = dataPoint.date;
                tempCurrent = false;
            }
        });
        return selectedData;
    }
    return data;
}

export function visDataPriceToYield(data: VisDataPoint[], distance: number): VisDataPoint[] {
    return data.map((dataPoint) => {
        return {
            date: dataPoint.date,
            priceOpt: (100 * dataPoint.priceOpt) / distance,
            priceOptMod: (100 * dataPoint.priceOptMod) / distance,
            daysBeforeDeparture: dataPoint.daysBeforeDeparture,
            currentPeriod: dataPoint.currentPeriod,
        };
    });
}
