import React from 'react';

import { Box, Grid, GridCol, Text } from '@flixbus/honeycomb-react';

import styles from './CustomTooltip.module.scss';

const CustomTooltip = (props: any) => {
    const { active, payload, distanceUnit, currencySymbol, displayKPI } = props;
    if (active && payload && payload.length) {
        const maxPriceOpt = payload[1]['payload']['maxPriceOpt'];
        const medianPriceOpt = payload[1]['payload']['medianPriceOpt'];
        const minPriceOpt = payload[1]['payload']['minPriceOpt'];
        const maxPriceOptMod = payload[1]['payload']['maxPriceOptMod'];
        const medianPriceOptMod = payload[1]['payload']['medianPriceOptMod'];
        const minPriceOptMod = payload[1]['payload']['minPriceOptMod'];
        const weekday = payload[1]['payload']['weekday'];

        const displayMetric = displayKPI === 'yield' ? 'Yield' : 'Price';
        const unit = displayKPI === 'yield' ? `${currencySymbol} / 100${distanceUnit}` : `${currencySymbol}`;

        return (
            <Box highlighted extraClasses={styles.tooltipBox}>
                <Grid>
                    <GridCol size={12}>
                        <Text extraClasses={styles.tooltipTitle}>{weekday}</Text>
                    </GridCol>
                    <GridCol size={12}>
                        <Text small>
                            {`Max. ${displayMetric}: ${maxPriceOpt.toFixed(2)} - ${maxPriceOptMod.toFixed(2)} ${unit}`}
                        </Text>
                    </GridCol>
                    <GridCol size={12}>
                        <Text small>
                            {`Median ${displayMetric}: ${medianPriceOpt.toFixed(2)} - ${medianPriceOptMod.toFixed(
                                2,
                            )} ${unit}`}
                        </Text>
                    </GridCol>
                    <GridCol size={12}>
                        <Text small>
                            {`Min. ${displayMetric}: ${minPriceOpt.toFixed(2)} - ${minPriceOptMod.toFixed(2)} ${unit}`}
                        </Text>
                    </GridCol>
                </Grid>
            </Box>
        );
    }
    return null;
};

export default CustomTooltip;
