import { useContext } from 'react';

import { FilterDataContext } from './filterData.context';
import { useToast } from '../../features/toasts-container/useToast';

export function useFilterData() {
    const { addToast } = useToast();
    const useFilterData = useContext(FilterDataContext);

    if (useFilterData === undefined) {
        const message = 'Error! useFilterData hook used outside of the context provider.';
        addToast({
            type: 'danger',
            content: message,
        });
        throw new Error(message);
    }

    return useFilterData;
}
