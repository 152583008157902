import HeadingWithTooltip from '../../../../../features/heading-with-tooltip/HeadingWithTooltip';
import styles from './AlphaInfluenceBox.module.scss';

import { Box, Grid, GridCol, Quantity, Text } from '@flixbus/honeycomb-react';
import { Icon, IconAttention } from '@flixbus/honeycomb-icons-react';
import React, { useCallback, useEffect, useState } from 'react';

const MIN_ALPHA = -0.8;
const MAX_ALPHA = 30;
const STEP = 0.1;

// Tooltip with information about Alpha Box
const TOOLTIP = (
    <>
        <Text small>
            Percentage increase/decrease applied on the forecast price, not on the final price offered to the customers.
        </Text>
    </>
);

type Props = {
    value: number;
    onValueChange: (value: number) => void;
    onHeightChange: (value: number) => void;
    error: boolean;
    infoError: string;
};

const AlphaInfluenceBoxComp: React.FC<Props> = ({ value, onValueChange, onHeightChange, error, infoError }) => {
    const [showInfo, setShowInfo] = useState<boolean>(false);
    useEffect(() => {
        if (value > 1 || value < -0.5) {
            setShowInfo(true);
        } else {
            setShowInfo(false);
        }
    }, [value]);

    const measuredRef = useCallback(
        (node: any) => {
            if (node !== null) {
                onHeightChange(node.getBoundingClientRect().height);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onHeightChange, error, showInfo],
    );

    return (
        <Box extraClasses={styles.box}>
            <div ref={measuredRef}>
                <Grid align="center">
                    <GridCol key="influence-box-heading" size={12}>
                        <HeadingWithTooltip title="Influence" tooltip={TOOLTIP} tooltipSize="medium" />
                    </GridCol>
                    <GridCol key="influence-box-input" size={12} extraClasses={styles.quantityWidth}>
                        <Quantity
                            label="Alpha"
                            id="alpha-influence"
                            name="alpha-influence"
                            value={Math.round(100 * value)}
                            min={100 * MIN_ALPHA}
                            max={100 * MAX_ALPHA}
                            plusBtnProps={{
                                onClick: () => {
                                    onValueChange(Math.min(Math.round(100 * (value + STEP)) / 100, MAX_ALPHA));
                                },
                            }}
                            minusBtnProps={{
                                onClick: () => {
                                    onValueChange(Math.max(Math.round(100 * (value - STEP)) / 100, MIN_ALPHA));
                                },
                            }}
                            onBlur={(e: any) => {
                                const value = parseInt(e.target.value) / 100;
                                if (value > MAX_ALPHA) {
                                    onValueChange(MAX_ALPHA);
                                } else if (value < MIN_ALPHA) {
                                    onValueChange(MIN_ALPHA);
                                } else {
                                    onValueChange(value);
                                }
                            }}
                        />
                        <Text extraClasses={styles.percentageText}>%</Text>
                    </GridCol>
                    {showInfo && (
                        <GridCol>
                            <Text small extraClasses={styles.infoText}>
                                <Icon InlineIcon={IconAttention} extraClasses={styles.infoIcon} /> Be aware of the
                                extreme influence
                            </Text>
                        </GridCol>
                    )}
                    {error && (
                        <GridCol>
                            <Text small extraClasses={styles.errorText}>
                                {infoError}
                            </Text>
                        </GridCol>
                    )}
                </Grid>
            </div>
        </Box>
    );
};

export const AlphaInfluenceBox = React.memo(AlphaInfluenceBoxComp);
