import React, { ReactNode } from 'react';

import { MsalProvider } from '@azure/msal-react';
import { Configuration, RedirectRequest } from '@azure/msal-browser';

import { createMsalInstance } from './authprovider.utils';

import { APP_ID, TOKEN_CLAIMS_API } from '../../constants';

// MSAL configuration
const msalConfig: Configuration = {
    auth: {
        // TODO: find a way to disable for test and local development
        clientId: APP_ID!,
        authority: process.env.REACT_APP_AZURE_AUTHORITY_URL,
        redirectUri: document.location.origin,
        postLogoutRedirectUri: document.location.origin,
    },
    cache: {
        storeAuthStateInCookie: false,
        cacheLocation: 'localStorage',
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const apiAccessRequest: RedirectRequest = {
    scopes: TOKEN_CLAIMS_API?.split(',') || [],
};

export const msalInstance = createMsalInstance(msalConfig);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

export default React.memo(AuthProvider);
