import { DateRange, DaysOfTheWeek } from '../types';

export const WEEKDAYS: Record<number, string> = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
};

export const WEEKDAYS_ORDER: Record<number, number> = {
    0: 6,
    1: 0,
    2: 1,
    3: 2,
    4: 3,
    5: 4,
    6: 5,
};

export const dateToDEString = (date: Date) => {
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
};

export const dateToISOString = (date: Date) => {
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
};

export const dateRangeToString = (dateRange: DateRange): string =>
    `${dateToDEString(dateRange[0])} - ${dateToDEString(dateRange[1])}`;

export const dateRangesToString = (dateRanges: DateRange[]): string[] =>
    dateRanges.map((dateRange) => dateRangeToString(dateRange));

export const monthsInBetweenDates = (dateRange: DateRange): number => {
    const diffInMiliSecs = dateRange[1].getTime() - dateRange[0].getTime();
    return diffInMiliSecs / (30 * 24 * 60 * 60 * 1000);
};

export const calculateSelectedDates = (
    includedDates: DateRange[],
    excludedDates: DateRange[],
    daysOfTheWeek: DaysOfTheWeek,
): Date[] => {
    if (includedDates.length > 0) {
        const includedDatesArray: Date[] = [];
        let currentDate = includedDates[0][0];
        const stopDate = includedDates[includedDates.length - 1][1];
        const daysOfTheWeekValues = daysOfTheWeek.map((value, index) => {
            if (value) {
                return index + 1 == 7 ? 0 : index + 1;
            }
        });

        while (currentDate <= stopDate) {
            if (daysOfTheWeekValues.includes(currentDate.getDay())) {
                if (includedDates.some((r) => currentDate >= r[0] && currentDate <= r[1])) {
                    if (!excludedDates.some((r) => currentDate >= r[0] && currentDate <= r[1])) {
                        includedDatesArray.push(currentDate);
                    }
                }
            }
            const nextDate = new Date(currentDate);
            nextDate.setDate(currentDate.getDate() + 1);
            currentDate = nextDate;
        }

        return includedDatesArray;
    } else {
        return [];
    }
};

export const departuresLength = (
    includedDates: DateRange[],
    excludedDates: DateRange[],
    daysOfTheWeek: DaysOfTheWeek,
): number => calculateSelectedDates(includedDates, excludedDates, daysOfTheWeek).length;

export const isFilterRangeContained = (
    filterDates: DateRange | undefined,
    includedDates: DateRange[],
    excludedDates: DateRange[],
    daysOfTheWeek: DaysOfTheWeek,
): boolean => {
    if (filterDates) {
        let currentDate = filterDates[0];
        const stopDate = filterDates[1];
        const daysOfTheWeekValues = daysOfTheWeek.map((value, index) => {
            if (value) {
                return index + 1 == 7 ? 0 : index + 1;
            }
        });

        while (currentDate <= stopDate) {
            if (daysOfTheWeekValues.includes(currentDate.getDay())) {
                if (includedDates.some((r) => currentDate >= r[0] && currentDate <= r[1])) {
                    return true;
                    if (!excludedDates.some((r) => currentDate >= r[0] && currentDate <= r[1])) {
                        return true;
                    }
                }
            }
            const nextDate = new Date(currentDate);
            nextDate.setDate(currentDate.getDate() + 1);
            currentDate = nextDate;
        }

        return false;
    }
    return true;
};

/** dateRanges are assumed to be ordered by the initial date of each range */
export const hasOverlappingRanges = (dateRanges: DateRange[]): boolean => {
    let previousDateRange = undefined;
    for (const dateRange of dateRanges) {
        if (previousDateRange) {
            if (previousDateRange[1] >= dateRange[0]) {
                return true;
            }
        }
        previousDateRange = dateRange;
    }
    return false;
};

export const isAPastDateRange = (dateRange: DateRange): boolean | undefined => {
    const now = new Date(Date.UTC(0, 0, 0, 0, 0, 0));
    return dateRange[0] < now || dateRange[1] < now;
};

export const dateRangeToISORange = (dateRange: DateRange): [string, string] => [
    dateToISOString(dateRange[0]),
    dateToISOString(dateRange[1]),
];

export const dateRangesToISORanges = (dateRanges: DateRange[]): [string, string][] =>
    dateRanges.map((dateRange) => dateRangeToISORange(dateRange));

export const getWeek = (date: Date) => {
    const d: any = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
};

export const getWeekday = (date: Date) => WEEKDAYS[date.getDay()];

export const compareDates = (date1: Date | string, date2: Date | string): boolean => {
    const date1Casted = typeof date1 === 'string' ? date1 : date1.toISOString().slice(0, 10);
    const date2Casted = typeof date2 === 'string' ? date2 : date2.toISOString().slice(0, 10);
    return date1Casted === date2Casted;
};
