import _ from 'lodash';
import { addDataDogError } from '../../utils/datadog/datadog.utils';
import { cityPairArrayToString } from '../../utils/cityPairs.utils';
import { ENDPOINT_URLS } from '../../constants';
import { useAccount } from '@azure/msal-react';
import { useAxios } from '../../utils/transport/useAxios';
import { useToast } from '../../features/toasts-container';

import { CityPairArray, RuleData } from '../../types';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

type RuleDataContext = {
    loading: boolean;
    rulesData: RuleData[];
    refreshRulesData: () => void;
    updateRuleProperty: (ruleId: number, update: Partial<RuleData>) => void;
};

export const RuleDataContext = createContext<RuleDataContext | undefined>(undefined);

export function useRules() {
    const ruleContext = useContext(RuleDataContext);

    if (ruleContext === undefined) {
        const message = 'Error! useRules hook used outside of the context provider.';
        throw new Error(message);
    }

    return ruleContext;
}

export function RuleDataProvider({ children }: { children?: ReactNode }) {
    const [loading, setLoading] = useState<boolean>(true);
    const [updateAvailable, setUpdateAvailable] = useState<boolean>(true);
    const [rulesData, setRulesData] = useState<RuleData[]>([]);
    const apiClient = useAxios();
    const { addToast } = useToast();
    const account = useAccount();

    useEffect(() => {
        const fetchRules = async () => {
            setLoading(true);
            try {
                const res = await apiClient.get(ENDPOINT_URLS.GET_RULES);

                if (res.status !== 200) {
                    throw new Error(`Error with status ${res.status}`);
                }

                const parsedData = parseRulesData(res.data);
                setRulesData(parsedData);
            } catch (error) {
                addDataDogError(error as Error);
                addToast({
                    type: 'danger',
                    content: `Fetching Rules API call failed: ${(error as Error).message}.`,
                });
                setRulesData([]);
            } finally {
                setLoading(false);
            }
        };

        if (updateAvailable) {
            fetchRules();
            setUpdateAvailable(false);
        }
    }, [addToast, apiClient, updateAvailable]);

    useEffect(() => {
        async function reactToUpdateFromRuleAPI(event: MessageEvent) {
            if (event.data.meta === 'workbox-broadcast-update') {
                const { cacheName, updatedURL } = event.data.payload;
                // TODO: replace magic strings with constants
                if (cacheName === 'apiCache' && updatedURL.includes('rule_list')) {
                    console.info('Received background update from API: ', updatedURL);
                    const cache = await caches.open(cacheName);
                    const updatedResponse = await cache.match(updatedURL);
                    const rawData = await updatedResponse?.json();
                    setRulesData(parseRulesData(rawData));
                }
            }
        }
        navigator.serviceWorker.addEventListener('message', reactToUpdateFromRuleAPI);
        return () => navigator.serviceWorker.removeEventListener('message', reactToUpdateFromRuleAPI);
    }, []);

    function updateRuleProperty(ruleId: number, update: Partial<RuleData>): void {
        setRulesData(
            rulesData.map((rule) =>
                rule.id == ruleId
                    ? {
                          ...rule,
                          ...update,
                          lastModifiedAt: new Date(),
                          lastModifiedBy: account ? account.username : '',
                      }
                    : rule,
            ),
        );
    }

    return (
        <RuleDataContext.Provider
            value={{
                loading,
                rulesData,
                refreshRulesData: () => setUpdateAvailable(true),
                updateRuleProperty,
            }}
        >
            {children}
        </RuleDataContext.Provider>
    );
}

export const parseRulesData = (data: Record<string, any>[]): RuleData[] => {
    const parsedData = data.map((elem: object) =>
        _.mapKeys(elem, (_v, k) => {
            return _.camelCase(k);
        }),
    );

    return parsedData.map((item: any) => {
        item.cityPairs = item.cityPairs.map((cityPair: CityPairArray) => cityPairArrayToString(cityPair));
        item.createdAt = new Date(item.createdAt);
        item.lastModifiedAt = new Date(item.lastModifiedAt);
        item.includedDates = item.includedDates.map((dateRange: [string, string]) => {
            return dateRange.map((date) => {
                const d = date.split('-').map(Number);
                const included = new Date(Date.UTC(d[0], d[1] - 1, d[2], 0, 0, 0));
                return included;
            });
        });
        item.excludedDates = item.excludedDates.map((dateRange: [string, string]) => {
            return dateRange.map((date) => {
                const d = date.split('-').map(Number);
                const excluded = new Date(Date.UTC(d[0], d[1] - 1, d[2], 0, 0, 0));
                return excluded;
            });
        });

        return item as RuleData;
    });
};
