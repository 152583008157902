import { addDataDogError } from '../../utils/datadog/datadog.utils';
import { ENDPOINT_URLS } from '../../constants';
import { useAxios } from '../../utils/transport/useAxios';
import { useToast } from '../../features/toasts-container';

export function useFetchRuleExists() {
    const apiClient = useAxios();
    const { addToast } = useToast();

    const checkRuleExists = async (title: string): Promise<boolean> => {
        let ruleExisits = true;
        try {
            const res = await apiClient.get(ENDPOINT_URLS.RULE_EXSITS, {
                params: { title: title },
            });

            if (res.status !== 200) {
                throw new Error(`Error with status ${res.status}`);
            }

            ruleExisits = (res.data as any).rule_exists;
        } catch (error) {
            addDataDogError(error as Error);
            addToast({
                type: 'danger',
                content: `Fetching Rules API call failed: ${(error as Error).message}.`,
            });
        }

        return ruleExisits;
    };

    return { checkRuleExists };
}
