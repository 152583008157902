import { cityPairStringToArray } from '../../../utils/cityPairs.utils';
import styles from './ChartTitle.module.scss';
import { CityPairArray, CityPairString } from '../../../types';

import { Button, Grid, GridCol, Heading, Tag } from '@flixbus/honeycomb-react';
import { Icon, IconArrowLeft, IconArrowRight, IconCalendar, IconTrip } from '@flixbus/honeycomb-icons-react';

import React from 'react';

type Props = {
    dates: string;
    cityPair: CityPairString;
    includeAlpha?: boolean;
    selectedWeekday?: string;
    onClickPreviousDay?: () => void;
    onClickNextDay?: () => void;
    onClickWeekdayTag?: () => void;
};

const ChartTitle: React.FC<Props> = ({
    dates,
    cityPair,
    selectedWeekday = undefined,
    includeAlpha = true,
    onClickPreviousDay = undefined,
    onClickNextDay = undefined,
    onClickWeekdayTag = undefined,
}) => {
    const cityPairArray: CityPairArray = cityPairStringToArray(cityPair);
    const showDayArrows = onClickPreviousDay || onClickNextDay;
    return (
        <Grid extraClasses={styles.container}>
            <GridCol size={4}>
                <Heading size={3} flushSpace>
                    <Icon InlineIcon={IconTrip} /> {`${cityPairArray[0]} - ${cityPairArray[1]}`}
                </Heading>
            </GridCol>
            <GridCol size={4}>
                <Heading size={3} flushSpace>
                    {showDayArrows && (
                        <Button
                            extraClasses={onClickPreviousDay ? styles.buttonLeft : styles.hiddenButton}
                            display="square"
                            link
                            title="Previous Day"
                            onClick={onClickPreviousDay}
                        >
                            <Icon InlineIcon={IconArrowLeft} />
                        </Button>
                    )}
                    <Icon InlineIcon={IconCalendar} /> {dates}
                    {onClickWeekdayTag && (
                        <Tag
                            extraClasses={styles.tag}
                            small
                            closeProps={{
                                label: 'Drop weekday',
                                onClick: onClickWeekdayTag,
                            }}
                        >
                            {selectedWeekday}s
                        </Tag>
                    )}
                    {showDayArrows && (
                        <Button
                            extraClasses={onClickNextDay ? styles.buttonRight : styles.hiddenButton}
                            display="square"
                            link
                            title="Next Day"
                            onClick={onClickNextDay}
                        >
                            <Icon extraClasses={styles.icon} InlineIcon={IconArrowRight} />
                        </Button>
                    )}
                </Heading>
            </GridCol>
            <GridCol extraClasses={styles.legendContaniner}>
                <div className={styles.increasedLegend}>Increased</div>
                <div className={styles.decreasedLegend}>Decreased</div>
                {includeAlpha && <div className={styles.alphaLegend}>Alpha</div>}
            </GridCol>
        </Grid>
    );
};

export default ChartTitle;
