import React, { useMemo } from 'react';

import cn from 'classnames';

import { Box, FormRow, Grid, GridCol, Heading, Legend, Spinner } from '@flixbus/honeycomb-react';

import RuleCell from '../../../../../features/rule-cell/RuleCell';

import styles from './OverlapsSummary.module.scss';
import { useFormState } from '../../../../../data/form-state/useFormState';

const OverlapsSummaryComp: React.FC = () => {
    // Get form context state
    const { overlappingRules, overlapsDataLoading } = useFormState();

    // Split active from user deactivated rules
    const activeOverlappingRules = useMemo(
        () => overlappingRules.filter((rule) => rule.active === true),
        [overlappingRules],
    );
    const deactivatedOverlappingRules = useMemo(
        () => overlappingRules.filter((rule) => rule.active === false),
        [overlappingRules],
    );

    return (
        <Box extraClasses={styles.container}>
            <FormRow extraClasses={styles.headingRow}>
                <Heading sectionHeader size={4}>
                    Overlaps Summary
                </Heading>
            </FormRow>
            {
                <FormRow>
                    {overlapsDataLoading ? (
                        <GridCol size={12} extraClasses={styles.spinnerRow}>
                            <Spinner size="md" />
                        </GridCol>
                    ) : overlappingRules.length ? (
                        <Grid>
                            <GridCol size={12}>
                                {activeOverlappingRules.map((rule, index) => (
                                    <FormRow
                                        extraClasses={cn(styles.row, {
                                            [styles.firstRow]: index === 0,
                                        })}
                                        key={`from-row-${index}`}
                                    >
                                        <RuleCell key={`rule-cell-${rule.id}`} compact rule={rule} />
                                    </FormRow>
                                ))}
                            </GridCol>
                            {deactivatedOverlappingRules.length !== 0 && (
                                <GridCol size={12}>
                                    <Legend>Deactivated rules</Legend>
                                </GridCol>
                            )}
                            <GridCol size={12}>
                                {deactivatedOverlappingRules.map((rule, index) => (
                                    <FormRow extraClasses={styles.row} key={`from-row-${index}`}>
                                        <RuleCell key={`rule-cell-${rule.id}`} compact rule={rule} />
                                    </FormRow>
                                ))}
                            </GridCol>
                        </Grid>
                    ) : (
                        <FormRow extraClasses={styles.noOverlapsRow}>There are no overlapping rules.</FormRow>
                    )}
                </FormRow>
            }
        </Box>
    );
};

export const OverlapsSummary = React.memo(OverlapsSummaryComp);
