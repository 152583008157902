import { useEffect, useState } from 'react';

import _ from 'lodash';

import { addDataDogError } from '../../utils/datadog/datadog.utils';
import { useAxios } from '../../utils/transport/useAxios';
import { useToast } from '../../features/toasts-container';
import { ENDPOINT_URLS, Region } from '../../constants';

const parseExchangeRate = (data: { exchange_rate: number }): number => {
    return data['exchange_rate'];
};

export function useFetchExchangeRate(currency: string): number {
    const apiClient = useAxios();
    const { addToast } = useToast();
    const [exchangeRate, setExchangeRate] = useState<number>(1);

    useEffect(() => {
        const fetchRules = async () => {
            try {
                const res = await apiClient.get(ENDPOINT_URLS.EXCHANGE_RATE, {
                    params: { currency: currency },
                });

                if (res.status !== 200) {
                    throw new Error(`Error with status ${res.status}`);
                }

                const parsedData = parseExchangeRate(res.data);
                setExchangeRate(parsedData);
            } catch (error) {
                addDataDogError(error as Error);
                addToast({
                    type: 'danger',
                    content: `Fetching exchange rate API call failed: ${(error as Error).message}.`,
                });
                setExchangeRate(1);
            }
        };
        if (currency === Region.EURO) {
            setExchangeRate(1);
        } else {
            fetchRules();
        }
    }, [addToast, apiClient, currency]);

    return exchangeRate;
}
