import {
    Autocomplete,
    AutocompleteInput,
    AutocompleteOptions,
    AutocompleteOptionType,
    AutocompleteSelectedOptions,
} from '@flixbus/honeycomb-react';
import React, { useEffect, useState } from 'react';

type Prop = {
    label: string;
    placeholder?: string;
    info?: string;
    value: string[];
    autocompleteData: AutocompleteOptionType[];
    allowAddingNew?: boolean;
    onValueChange: (value: string[]) => void;
};

// Most part of component based on code on:
// https://honeycomb-react.hive.flix.tech/#/Experimental/Autocomplete
const MultiAutocompleteInput: React.FC<Prop> = ({
    label,
    placeholder = '',
    info = '',
    value,
    autocompleteData,
    allowAddingNew = false,
    onValueChange,
}) => {
    // Dataset we'll be working with
    const [data, setData] = useState<AutocompleteOptionType[]>([]);
    // Stores selection options, get's populated from dataset
    const [selectedOptions, setSelectedOptions] = useState<AutocompleteOptionType[]>([]);
    // Stores the value typed on the input field
    const [typedValue, setTypedValue] = useState<string>('');

    const filterAutocompleteData = (
        searchQuery: string,
        data: AutocompleteOptionType[],
    ): Promise<AutocompleteOptionType[]> =>
        new Promise((resolve) => {
            setTimeout(() => {
                const res = data.filter((item) => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
                resolve(res);
            }, 200);
        });

    // Narrows down suggested options list based on user input
    const filterOptionsList = (e: { target: { value: string } }) => {
        filterAutocompleteData(e.target.value, autocompleteData).then((options) => {
            if (allowAddingNew && options.length === 0) {
                options.push({
                    title: e.target.value,
                    subtitle: 'Add as new tag',
                });
            }
            setData(options);
        });
    };

    // Updating selected options every time a value gets updated
    useEffect(() => {
        let selectedValues: AutocompleteOptionType[] = [];
        if (allowAddingNew) {
            selectedValues = Array.from(new Set(value)).map((item) => {
                return { title: item };
            });
        } else {
            selectedValues = autocompleteData.filter((option) => value.includes(option.title));
        }
        setSelectedOptions(selectedValues);
    }, [value, autocompleteData, allowAddingNew]);

    // Pushes selected option value to the state
    const handleItemSelect = (selectedItem: { title: string }) => {
        if (!value.includes(selectedItem.title)) {
            onValueChange(value.concat([selectedItem.title]));
        }
        setData([]); // hiding the options list
        setTypedValue('');
    };

    // Removes deleted option value from the state
    const handleItemDelete = (selectedItem: { title: string }) => {
        onValueChange(value.filter((valueItem) => valueItem !== selectedItem.title));
    };

    // Clears value
    const handleReset = () => {
        onValueChange([]);
    };

    // Control value on input field
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTypedValue(e.target.value);
    };

    // Clear input field on blur
    const handleBlur = () => {
        setTypedValue('');
    };

    return (
        <Autocomplete
            id="autocomplete-with-multiselect"
            value={typedValue}
            options={data}
            // filters the data based on value in the state filling out selected options list
            optionsSelected={selectedOptions}
            onDebounce={filterOptionsList}
            // onFocus={() => {
            //   setData(autocompleteData);
            // }} // allows option list to be shown on focus
            onSelect={handleItemSelect}
            onDelete={handleItemDelete}
            onReset={handleReset}
            onChange={handleChange}
            onFocus={(event) => {
                event.target.setAttribute('autocomplete', 'off');
            }}
        >
            <AutocompleteInput
                id="autocomplete"
                label={label}
                placeholder={placeholder}
                info={info}
                onBlur={handleBlur}
            />
            <AutocompleteOptions label={label} optionsToDisplay={4} optionHasSubtitle={false} />
            <AutocompleteSelectedOptions
                deleteAllItemsLabel="Clear selection"
                deleteItemLabel="Remove item from selection"
            />
        </Autocomplete>
    );
};

export default MultiAutocompleteInput;
