import ChartTitle from '../common/ChartTitle';
import CustomTicks from './components/CustomTicks';
import CustomTooltip from './components/CustomTooltip';
import { dateToDEString } from '../../../utils/date.utils';
import styles from './ChartPerDeparture.module.scss';
import { visDataToCurrentChartData } from '../../../utils/visualization.utils';
import { CityPairString, DateRange, DisplayKPIs, VisDataPoint } from '../../../types';

import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Label,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { Button, Grid, GridCol } from '@flixbus/honeycomb-react';
import { Icon, IconArrowLeft, IconArrowRight } from '@flixbus/honeycomb-icons-react';
import React, { useMemo, useReducer } from 'react';
import { Region, REGION_MAP } from '../../../constants';

const TRACE_COLORS = {
    base: styles.basecolor,
    increased: styles.increasecolor,
    decreased: styles.decreasecolor,
    alpha: styles.alphacolor,
};
const MAX_POINTS_TO_VIS = 30;
const VIS_NAVIGATION_POINTS = 7;
function navigationReducer(state: [number, number], action: { type: string; payload: number }): [number, number] {
    const dataLength = action.payload;
    switch (action.type) {
        case 'back':
            if (state[0] - VIS_NAVIGATION_POINTS < 0) {
                return [0, MAX_POINTS_TO_VIS];
            } else {
                return [state[0] - VIS_NAVIGATION_POINTS, state[1] - VIS_NAVIGATION_POINTS];
            }
        case 'forward':
            if (state[1] + VIS_NAVIGATION_POINTS > dataLength) {
                return [dataLength - MAX_POINTS_TO_VIS, dataLength];
            } else {
                return [state[0] + VIS_NAVIGATION_POINTS, state[1] + VIS_NAVIGATION_POINTS];
            }
        default:
            throw new Error(`navigationReducer does not have action ${action.type}`);
    }
}

type Props = {
    cityPair: CityPairString;
    data: VisDataPoint[];
    displayDates: DateRange | undefined;
    selectedWeekday: string;
    priceDomain: [number, number];
    alphaDomain: [number, number];
    region: Region;
    exchangeRate: number;
    onClickChart: (e: any) => void;
    onClickWeekdayTag: () => void;
    displayKPI: DisplayKPIs;
};

const ChartPerDeparture: React.FC<Props> = ({
    cityPair,
    data,
    displayDates,
    selectedWeekday,
    priceDomain,
    alphaDomain,
    region,
    exchangeRate,
    onClickChart,
    onClickWeekdayTag,
    displayKPI,
}) => {
    const currencySymbol = REGION_MAP[region].symbol;
    const distanceUnit = REGION_MAP[region].distanceUnit;
    const yLabel =
        displayKPI === 'yield'
            ? `Forecast Yield (${currencySymbol} / 100${distanceUnit})`
            : `Forecast Price (${currencySymbol})`;
    const yPosition = displayKPI === 'yield' ? 75 : 50;

    // Prepare chart data
    const currentChartdata = visDataToCurrentChartData(data, selectedWeekday, exchangeRate);

    // Calculate plot navigation logic
    const dataLength: number = currentChartdata.length;
    const [dataIndexes, dispatchDataIndexes] = useReducer(navigationReducer, [0, MAX_POINTS_TO_VIS]);
    const dataToVis = useMemo(
        () =>
            dataLength <= MAX_POINTS_TO_VIS ? currentChartdata : currentChartdata.slice(dataIndexes[0], dataIndexes[1]),
        [currentChartdata, dataIndexes, dataLength],
    );

    const dateTitle = useMemo(() => {
        return `${dateToDEString(displayDates ? displayDates[0] : new Date())} - ${dateToDEString(
            displayDates ? displayDates[1] : new Date(),
        )}`;
    }, [displayDates]);

    return (
        <Grid>
            <GridCol size={12}>
                <ChartTitle
                    cityPair={cityPair}
                    dates={dateTitle}
                    selectedWeekday={selectedWeekday}
                    onClickWeekdayTag={selectedWeekday !== '' ? onClickWeekdayTag : undefined}
                />
            </GridCol>
            <GridCol size={12}>
                <ResponsiveContainer width="100%" height={275}>
                    <ComposedChart
                        className={styles.chart}
                        data={dataToVis}
                        barSize={24}
                        margin={{
                            top: 30,
                            right: 10,
                            left: 15,
                            bottom: 5,
                        }}
                        onClick={onClickChart}
                    >
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <YAxis yAxisId="left" orientation="left" type="number" domain={priceDomain}>
                            <Label value={yLabel} angle={-90} position="insideLeft" offset={0} dx={5} dy={yPosition} />
                        </YAxis>
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            type="number"
                            domain={alphaDomain}
                            allowDecimals={false}
                        >
                            <Label
                                value="Alpha Influence (%)"
                                angle={-90}
                                position="right"
                                offset={0}
                                dx={-10}
                                dy={50}
                            />{' '}
                        </YAxis>
                        <XAxis dataKey="date" interval={0} tick={<CustomTicks data={dataToVis} />} height={60} />
                        <Tooltip
                            content={
                                <CustomTooltip
                                    distanceUnit={distanceUnit}
                                    currencySymbol={currencySymbol}
                                    displayKPI={displayKPI}
                                />
                            }
                        />
                        <Bar
                            id="ID01"
                            yAxisId="left"
                            stackId="a"
                            dataKey="base"
                            fill={TRACE_COLORS.base}
                            opacity={0.8}
                        />
                        <Bar id="ID02" yAxisId="left" stackId="a" dataKey="increased" fill={TRACE_COLORS.increased} />
                        <Bar id="ID03" yAxisId="left" stackId="a" dataKey="decreased" fill={TRACE_COLORS.decreased} />
                        <Scatter id="ID04" yAxisId="right" type="monotone" dataKey="alpha" fill={TRACE_COLORS.alpha} />
                        {/* TODO: The last scatter plot is hacky way to make 
                      daysBeforeDeparture reach the tooltip.
                      Find a better solution! */}
                        <YAxis yAxisId="PBP" hide={true} />
                        <Scatter id="ID05" yAxisId="PBP" dataKey="daysBeforeDeparture" width={0} />
                    </ComposedChart>
                </ResponsiveContainer>
            </GridCol>
            {dataLength > MAX_POINTS_TO_VIS && (
                <GridCol size={1}>
                    {dataIndexes[0] > 0 && (
                        <Button
                            aria-label="Prev Data Point"
                            extraClasses={styles.arrowButton}
                            display="square"
                            onClick={() => dispatchDataIndexes({ type: 'back', payload: dataLength })}
                        >
                            <Icon InlineIcon={IconArrowLeft} />
                        </Button>
                    )}
                </GridCol>
            )}
            {dataLength > MAX_POINTS_TO_VIS && (
                <GridCol extraClasses={styles.arrowButtonRightContainer} size={11}>
                    {dataIndexes[1] < dataLength && (
                        <Button
                            aria-label="Next Data Point"
                            extraClasses={styles.arrowButton}
                            display="square"
                            onClick={() => dispatchDataIndexes({ type: 'forward', payload: dataLength })}
                        >
                            <Icon InlineIcon={IconArrowRight} />
                        </Button>
                    )}
                </GridCol>
            )}
        </Grid>
    );
};

export default ChartPerDeparture;
