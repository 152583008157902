import logo from './assets/turbo.svg';

import { themeKamil, themeLegacy, Themes } from '@flixbus/honeycomb-react';

export const NOOP = () => undefined;

export const THEME =
    process.env.REACT_APP_BRANCH == 'master'
        ? { name: 'legacy' as Themes, theme: { legacy: themeLegacy } }
        : { name: 'kamil' as Themes, theme: { kamil: themeKamil } };

export const APP_PATHS = {
    LOGOUT: '/logout',
    HOME: '/',
    FORM_CREATE: '/create',
    FORM_CLONE: '/clone',
    FORM_EDIT: '/edit',
    FORM_STEP_FILTER: 'filter',
    FORM_STEP_INFLUENCE: 'influence',
    FORM_STEP_SUBMIT: 'submit',
};

export const BRAND = {
    alt: 'TurboTouch Frontend',
    image: logo,
    href: '/',
};

export const DATE_RANGE_MAX_DAYS = 365;

export const STANDARD_PBP_BOUNDARIES = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 18, 21, 24, 28, 35, 42, 49, 63, 77, 91, 365,
];

export const MAX_TITLE_LENGTH = 250;

let BACKEND_SERVER = '';
let CLIENT_ID = process.env.REACT_APP_AZURE_APPLICATION_ID;
let CLAIMS_API = process.env.REACT_APP_AZURE_ACCESS_TOKEN_CLAIMS_API;
if (process.env.REACT_APP_BRANCH === 'master') {
    BACKEND_SERVER = 'https://turbo-touch-api.gr.flix.tech';
    CLIENT_ID = process.env.REACT_APP_AZURE_APPLICATION_ID_PROD;
    CLAIMS_API = process.env.REACT_APP_AZURE_ACCESS_TOKEN_CLAIMS_API_PROD;
} else {
    BACKEND_SERVER = 'https://turbo-touch-api.gr-dev.flix.tech';
}

export const TURBOTOUCH_API = BACKEND_SERVER;
export const APP_ID = CLIENT_ID;
export const TOKEN_CLAIMS_API = CLAIMS_API;
export const ENDPOINT_URLS = {
    FILTER_DATA: '/api-rules/create_rule_filter_user_input/',
    GET_RULES: '/api-rules/rule_list/',
    CREATE_RULE: '/api-rules/create_rule/',
    EDIT_RULE: '/api-rules/edit_rule/',
    UPDATE_RULE_ACTIVE_STATUS: '/api-rules/update_rule_active_status/',
    VISUALIZATION_DATA: '/api-rules/visualization_data/',
    EXCHANGE_RATE: '/api-rules/exchange_rate/',
    GET_OVERLAPPING_RULES: '/api-rules/get_overlapping_rules/',
    GENERATE_TASKS: '/api-rules/generate_tasks/',
    RULE_EXSITS: '/api-rules/rule_exists/',
};

export enum Region {
    EURO = 'EUR',
    USA = 'USD',
}

export enum DistanceUnit {
    KM = 'km',
    MILES = 'miles',
}

export type RegionMapItem = {
    name: string;
    isoCode: Region;
    symbol: string;
    countryCode: string;
    distanceUnit: DistanceUnit;
};

type RegionMap = Record<Region, RegionMapItem>;
export const REGION_MAP: RegionMap = {
    [Region.EURO]: {
        name: 'Euro - KM',
        isoCode: Region.EURO,
        symbol: '€',
        countryCode: 'eu',
        distanceUnit: DistanceUnit.KM,
    },
    [Region.USA]: {
        name: 'US Dollar - Miles',
        isoCode: Region.USA,
        symbol: '$',
        countryCode: 'us',
        distanceUnit: DistanceUnit.MILES,
    },
};

const stepValues = [0, 1, 2];
export const steps = [
    { text: 'Filter', value: stepValues[0] },
    { text: 'Influence', value: stepValues[1] },
    { text: 'Submit', value: stepValues[2] },
];
export type StepsType = (typeof stepValues)[number];
