import AlphaPill from '../../../../../features/alpha-pill/alphaPill';
import CityPairsTable from '../../../../../features/city-pairs-table/CityPairsTable';
import { dateRangesToString } from '../../../../../utils/date.utils';
import DaysOfTheWeekInput from '../../../../../features/days-of-the-week-input/DaysOfTheWeekInput';
import React from 'react';
import styles from './RuleSummary.module.scss';

import { CityPairString, DateRange, DaysBeforeDeparture, DaysOfTheWeek } from '../../../../../types';
import { Divider, Grid, GridCol, Heading, Legend, Text } from '@flixbus/honeycomb-react';
import { Icon, IconArrowBigRight } from '@flixbus/honeycomb-icons-react';

type Props = {
    cityPairs: CityPairString[];
    includedDates: DateRange[];
    excludedDates: DateRange[];
    daysOfTheWeek: DaysOfTheWeek;
    daysBeforeDeparture: DaysBeforeDeparture;
    alpha: number;
};

const RuleSummaryComp: React.FC<Props> = ({
    cityPairs,
    includedDates,
    excludedDates,
    daysOfTheWeek,
    daysBeforeDeparture,
    alpha,
}) => {
    // Format values for displaying
    const includedDeparturesString = dateRangesToString(includedDates);
    const excludedDeparturesString = dateRangesToString(excludedDates);
    const PBPsString = daysBeforeDeparture ? (
        <>
            {daysBeforeDeparture[0]} <Icon InlineIcon={IconArrowBigRight} size={4} /> {daysBeforeDeparture[1]}
        </>
    ) : (
        ''
    );

    return (
        <div className={styles.box}>
            <Grid align="top">
                <GridCol key="summary-heading" size={12}>
                    <Heading sectionHeader size={2} extraClasses={styles.heading}>
                        Summary
                    </Heading>
                </GridCol>
                <GridCol key="city-pairs-info" size={12}>
                    <Legend>City pairs</Legend>
                    <CityPairsTable cityPairsData={cityPairs} pairsAsIds={true}></CityPairsTable>
                </GridCol>
                <GridCol key="divider-1" size={12}>
                    <Divider extraClasses={styles.summaryDivider} />
                </GridCol>
                <GridCol key="included-departures-info" size={6} extraClasses={styles.row}>
                    <Legend>Included departures</Legend>
                    {includedDeparturesString.map((dateRange) => (
                        <Text key={`included-date-${dateRange}`} extraClasses={styles.dateRangeText}>
                            {dateRange}
                        </Text>
                    ))}
                </GridCol>
                <GridCol key="excluded-departures-info" size={6} extraClasses={styles.row}>
                    <Legend>Excluded departures</Legend>
                    {excludedDeparturesString.map((dateRange) => (
                        <Text key={`excluded-date-${dateRange}`} extraClasses={styles.dateRangeText}>
                            {dateRange}
                        </Text>
                    ))}
                </GridCol>
                <GridCol key="days-of-week-info" size={12}>
                    <Legend>Weekdays</Legend>
                    <DaysOfTheWeekInput daysOfTheWeek={daysOfTheWeek} />
                </GridCol>
                <GridCol key="divider-2" size={12}>
                    <Divider extraClasses={styles.summaryDivider} />
                </GridCol>
                <GridCol key="influence-info" size={6}>
                    <Legend>Alpha influence</Legend>
                    <AlphaPill alpha={alpha} />
                </GridCol>
                <GridCol key="pbp-info" size={6}>
                    <Legend>Pre-Booking Period</Legend>
                    <Text>{PBPsString}</Text>
                </GridCol>
            </Grid>
        </div>
    );
};

export const RuleSummary = React.memo(RuleSummaryComp);
