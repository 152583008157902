import { UNSAFE_NavigationContext } from 'react-router-dom';

import type { Blocker, History, Transition } from 'history';

// Implementation based on:
// https://github.com/Bilal-Bangash/detecting-route-change-react-route-dom-v6
import * as React from 'react';

export function useNavigationBlocker(blocker: Blocker, when = true): void {
    const navigator = React.useContext(UNSAFE_NavigationContext).navigator as History;

    React.useEffect(() => {
        if (!when) return;

        const unblock = navigator.block((tx: Transition) => {
            const autoUnblockingTx = {
                ...tx,
                retry: () => {
                    unblock();
                    tx.retry();
                },
            };

            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [navigator, blocker, when]);
}
