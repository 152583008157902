import React, { ReactNode, useCallback, useState } from 'react';

import { NotificationProps } from '@flixbus/honeycomb-react';

import { NOOP } from '../../constants';
import { ToastsContainer } from './ToastsContainer';

export type Toast = {
    id: number;
    type?: NotificationProps['appearance'];
    content: ReactNode;
    description?: string;
    duration?: number;
    position?: 'top-right' | 'bottom-left';
};

type Context = {
    addToast: (t: Omit<Toast, 'id'>) => void;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ToastContext = React.createContext<Context>({
    addToast: NOOP,
});

let id = 0;

const ToastsProviderComp: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [toasts, setToasts] = useState<Toast[]>([]);

    const removeToast = useCallback((id: number) => setToasts((toasts) => toasts.filter((t) => t.id !== id)), []);

    const addToast = useCallback(
        (toast: Omit<Toast, 'id'>) => {
            const payload = {
                id: id++,
                // duration: 4000,
                ...toast,
            };
            setToasts((toasts) => [payload, ...toasts]);
            // setTimeout(() => removeToast(payload.id), payload.duration);
        },
        [], // [removeToast]
    );

    return (
        <ToastContext.Provider value={{ addToast }}>
            {toasts.length > 0 && <ToastsContainer toasts={toasts} removeToast={removeToast} />}
            {children}
        </ToastContext.Provider>
    );
};

export const ToastsProvider = React.memo(ToastsProviderComp);
