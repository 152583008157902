import { DateRange } from '../../../types';
import { dateRangeToString } from '../../../utils/date.utils';
import styles from './DateRangeInput.module.scss';
import useOutsideClick from '../../../utils/hooks/useOutsideClicks';

import { Calendar, Input, Tag } from '@flixbus/honeycomb-react';
import { Icon, IconCalendar } from '@flixbus/honeycomb-icons-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';

const DAYS_IN_THE_FUTURE = 366;

type Props = {
    label: string;
    dateRange: DateRange | undefined;
    includePast?: boolean;
    valid?: boolean;
    infoError?: string;
    onDateRangeChange: (dateRange: DateRange | undefined) => void;
};

const DateRangeInput: React.FC<Props> = ({
    label,
    dateRange,
    includePast = false,
    valid = undefined,
    infoError = '',
    onDateRangeChange,
}) => {
    const calendarContainerRef = useRef<HTMLDivElement>(null);

    // Set internal date range state
    const [internalRange, setInternalRange] = useState<[Date | undefined, Date | undefined]>([undefined, undefined]);

    // Define the calendar range
    const calendarStart = includePast ? new Date(2020, 0, 0) : new Date();
    const calendarEnd = new Date();
    calendarEnd.setDate(calendarEnd.getDate() + DAYS_IN_THE_FUTURE);

    // Define the value to be shown on the date input field
    const dateRangeString = useMemo(() => dateRange && dateRangeToString(dateRange), [dateRange]);

    // Define calendart show logic when inputting
    const [show, setShow] = useState<boolean>(false);
    const showCalendar = () => setShow(true);
    const hideCalendar = () => setShow(false);

    // Register callback to close calendar on outside click
    useOutsideClick(calendarContainerRef, () => {
        hideCalendar();
    });

    // Handle date selection
    const onDateSelected = (date: Date) => {
        const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
        if (!internalRange[0]) {
            setInternalRange([d, undefined]);
        } else if (!internalRange[1]) {
            if (d >= internalRange[0]) {
                setInternalRange([internalRange[0], d]);
                hideCalendar();
            }
        } else {
            setInternalRange([d, undefined]);
        }
    };

    // Handle clearing value
    const onClearDateRange = () => {
        setInternalRange([undefined, undefined]);
        onDateRangeChange(undefined);
    };

    // Set external date range
    useEffect(() => {
        if (internalRange[0] && internalRange[1]) {
            onDateRangeChange([internalRange[0], internalRange[1]]);
        }
    }, [internalRange, onDateRangeChange]);

    return (
        <>
            <Input
                id="date-range-input"
                label={label}
                iconLeft={<Icon InlineIcon={IconCalendar} />}
                valid={valid}
                infoError={infoError}
                onClick={showCalendar}
            />
            <div id="floating-calendar" style={{ position: 'absolute', zIndex: 10 }} ref={calendarContainerRef}>
                <Calendar
                    id="date-range-picker"
                    hidden={!show}
                    startDate={calendarStart}
                    endDate={calendarEnd}
                    defaultMonth={new Date()}
                    appearance={'compact'}
                    startSelected={internalRange[0]}
                    endSelected={internalRange[1]}
                    handleSelect={onDateSelected}
                />
            </div>
            {dateRange && (
                <Tag
                    closeProps={{
                        label: 'Close date range tag',
                        onClick: () => onClearDateRange(),
                    }}
                    extraClasses={styles.dateRangeTag}
                >
                    {dateRangeString}
                </Tag>
            )}
        </>
    );
};

export default DateRangeInput;
