import _ from 'lodash';
import { addDataDogError } from '../../utils/datadog/datadog.utils';
import { dateRangesToISORanges } from '../../utils/date.utils';
import { ENDPOINT_URLS } from '../../constants';
import { useAxios } from '../../utils/transport/useAxios';
import { useToast } from '../../features/toasts-container';

import { DateRange, DaysBeforeDeparture, DaysOfTheWeek, VisDataPoint } from '../../types';
import { useEffect, useState } from 'react';

const parseRulesData = (data: Record<string, any>[]): VisDataPoint[] => {
    if (data.length) {
        const parsedData = data.map((elem: object) =>
            _.mapKeys(elem, (_v, k) => {
                return _.camelCase(k);
            }),
        );

        return parsedData.map((item: any) => {
            const d = item.date.split('T')[0].split('-').map(Number);
            item.date = new Date(Date.UTC(d[0], d[1] - 1, d[2], 0, 0, 0));
            return item;
        });
    }
    return [];
};

export function useFetchVisData(
    selectedCityPair: string[],
    includedDates: DateRange[],
    excludedDates: DateRange[],
    daysOfTheWeek: DaysOfTheWeek,
    daysBeforeDeparture: DaysBeforeDeparture,
    alpha: number,
    refreshData: number,
    excludeRuleId: number | undefined = undefined,
): {
    data: VisDataPoint[];
    loading: boolean;
} {
    const apiClient = useAxios();
    const { addToast } = useToast();
    const [visData, setVisData] = useState<VisDataPoint[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchVisData = async () => {
            if (selectedCityPair) {
                setLoading(true);
                try {
                    const request: Record<string, any> = {
                        city_pair: selectedCityPair,
                        included_dates: JSON.stringify(dateRangesToISORanges(includedDates)),
                        excluded_dates: JSON.stringify(dateRangesToISORanges(excludedDates)),
                        days_of_the_week: daysOfTheWeek,
                        days_before_departure:
                            daysBeforeDeparture[0] === 365 && daysBeforeDeparture[1] === 0 ? [] : daysBeforeDeparture,
                        alpha: alpha,
                    };
                    if (excludeRuleId) {
                        request['exclude_rule_id'] = excludeRuleId;
                    }
                    const res = await apiClient.get(ENDPOINT_URLS.VISUALIZATION_DATA, {
                        params: request,
                    });

                    if (res.status < 200 && res.status >= 30) {
                        throw new Error(`Error with status ${res.status}`);
                    }

                    const parsedData = parseRulesData(res.data);
                    setVisData(parsedData);
                } catch (error) {
                    addDataDogError(error as Error);
                    addToast({
                        type: 'danger',
                        content: `Visualization data API call failed: ${(error as Error).message}.`,
                    });
                    setVisData([]);
                } finally {
                    setLoading(false);
                }
            } else {
                setVisData([]);
            }
        };

        fetchVisData();
    }, [
        addToast,
        apiClient,
        selectedCityPair,
        includedDates,
        excludedDates,
        daysOfTheWeek,
        daysBeforeDeparture,
        alpha,
        refreshData,
        excludeRuleId,
    ]);

    return {
        data: visData,
        loading: loading,
    };
}
