import { kmToCurrRegionDistance } from '../utils/distance.utils';

import { City, CityPairArray, CityPairString } from '../types';
import { Region, REGION_MAP } from '../constants';

export function cityPairArrayToString(cityPairArray: CityPairArray): CityPairString {
    return `${cityPairArray[0]}::${cityPairArray[1]}`;
}

export function cityPairStringToArray(cityPairString: CityPairString): CityPairArray {
    return cityPairString.split('::') as CityPairArray;
}

export function symmetricalCityPair(cityPairString: CityPairString): CityPairString {
    const spliPair = cityPairStringToArray(cityPairString);
    const returnPair = cityPairArrayToString([spliPair[1], spliPair[0]]);
    return returnPair;
}

export function sortCityPair(cityPair: CityPairString): CityPairString {
    return cityPairArrayToString([...cityPairStringToArray(cityPair)].sort() as CityPairArray);
}

export function sortCityPairs(cityPairs: CityPairString[]): CityPairString[] {
    const cityPairsArray = cityPairs.map((cityPair) => cityPairStringToArray(cityPair));
    cityPairsArray.sort((a, b) => (a[0] === b[0] ? a[1].localeCompare(b[1]) : a[0].localeCompare(b[0])));
    return cityPairsArray.map((cityPair) => cityPairArrayToString(cityPair));
}

export function prepareCityPairsVisData(
    cityPairs: CityPairString[],
    cities: Map<string, City>,
    getCityPairDistance: (cityPair: CityPairString) => number,
    region: Region,
): Map<string, CityPairString> {
    const data = sortCityPairs(cityPairs);
    const result = new Map();
    data.forEach((cityPair) => {
        const distance = kmToCurrRegionDistance(getCityPairDistance(cityPair), region);
        const displayValue =
            cityPairStringToArray(cityPair)
                .map((cityUuid) => cities.get(cityUuid)!.name)
                .join(' - ') + ` (${distance.toFixed(0)} ${REGION_MAP[region].distanceUnit})`;
        result.set(displayValue, cityPair);
    });
    return result;
}
