import { AxiosError } from 'axios';

import { addDataDogError } from '../../utils/datadog/datadog.utils';
import { cityPairStringToArray } from '../../utils/cityPairs.utils';
import { dateRangesToISORanges } from '../../utils/date.utils';
import { ENDPOINT_URLS } from '../../constants';
import { useAxios } from '../../utils/transport/useAxios';
import { CityPairString, DateRange, DaysBeforeDeparture, DaysOfTheWeek } from '../../types';

export function usePutEditRule() {
    const apiClient = useAxios();

    const editRule = async (
        ruleId: number | undefined,
        title: string,
        tags: string[],
        active: boolean,
        cityPairs: CityPairString[],
        includedDates: DateRange[],
        excludedDates: DateRange[],
        daysOfTheWeek: DaysOfTheWeek,
        daysBeforeDeparture: DaysBeforeDeparture,
        alpha: number,
    ): Promise<[boolean, string]> => {
        if (!ruleId) {
            // RuleId should have been initialized, if that is not
            // the case force an error to be diplayed on the ErrorBoundary
            throw 'Empty RuleId while editing';
        }

        let success = false;
        let errorMessage = '';
        try {
            const cityUUIDs = cityPairs.map((cityPair) => cityPairStringToArray(cityPair));
            if (cityUUIDs.length > 0) {
                const res = await apiClient.put(ENDPOINT_URLS.EDIT_RULE, {
                    rule_id: ruleId,
                    title: title,
                    tags: tags,
                    active: active,
                    city_pairs: cityUUIDs,
                    included_dates: dateRangesToISORanges(includedDates),
                    excluded_dates: dateRangesToISORanges(excludedDates),
                    days_of_the_week: daysOfTheWeek,
                    days_before_departure:
                        daysBeforeDeparture[0] === 365 && daysBeforeDeparture[1] === 0 ? [] : daysBeforeDeparture,
                    alpha: alpha,
                });

                if (res.status < 200 && res.status >= 300) {
                    throw `Error with status ${res.headers}`;
                }

                success = true;
            }
        } catch (e) {
            if (e instanceof AxiosError) {
                addDataDogError(e as Error);
                errorMessage = e.message;
            } else {
                addDataDogError(e as Error);
                errorMessage = e as string;
            }
        }

        return [success, errorMessage];
    };

    return { editRule };
}
