import React from 'react';
import { RegionSwitcher } from '../../region-switcher/RegionSwitcher';
import styles from './AuthHeader.module.scss';
import { useAccount } from '@azure/msal-react';

import { APP_PATHS, BRAND } from '../../../constants';
import {
    Header,
    HeaderBrand,
    HeaderNavigation,
    HeaderSubNavigation,
    HeaderUserWidget,
    HeaderWidgets,
    NavItem,
} from '@flixbus/honeycomb-react';
import { NavLink, useLocation } from 'react-router-dom';

const AuthHeaderMemo = () => {
    const account = useAccount();
    const currLocation = useLocation();

    const headerBrand = (
        <HeaderBrand
            key="header-brand"
            alt={BRAND.alt}
            src={BRAND.image}
            href={BRAND.href}
            RouterLink={NavLink}
            appearance="tall"
            width="124"
        />
    );

    const createPath = `${APP_PATHS.FORM_CREATE}/${APP_PATHS.FORM_STEP_FILTER}`;
    const headerNavigation = (
        <HeaderNavigation key="header-navigation" aria-label="header-navigation">
            <NavItem href={APP_PATHS.HOME} RouterLink={NavLink} active={currLocation.pathname === APP_PATHS.HOME}>
                List
            </NavItem>
            <NavItem
                href={createPath}
                RouterLink={NavLink}
                active={currLocation.pathname.includes(APP_PATHS.FORM_CREATE)}
            >
                Create
            </NavItem>
        </HeaderNavigation>
    );

    return (
        <Header fixed={false} extraClasses={styles.whiteLink}>
            {account
                ? [
                      headerBrand,
                      headerNavigation,
                      <HeaderWidgets key="header-widgets">
                          <div className={styles.widgetContainer}>
                              <RegionSwitcher />
                              <HeaderUserWidget
                                  extraClasses={styles.avatarLink}
                                  data-dd-privacy="mask"
                                  subMenu={
                                      <HeaderSubNavigation id="user-widged-sub-menu" data-dd-privacy="allow">
                                          <NavItem href={APP_PATHS.LOGOUT}>Logout</NavItem>
                                          <NavItem Elem="span">Version: {process.env.REACT_APP_TT_VERSION}</NavItem>
                                      </HeaderSubNavigation>
                                  }
                              >
                                  {account.name || ''}
                              </HeaderUserWidget>
                          </div>
                      </HeaderWidgets>,
                  ]
                : headerBrand}
        </Header>
    );
};

export const AuthHeader = React.memo(AuthHeaderMemo);
