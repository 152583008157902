import React, { createContext, ReactNode, useEffect, useState } from 'react';

import { useCookies } from 'react-cookie';

import { Region } from '../../constants';
import { useFetchExchangeRate } from './useFetchExchangeRate';

type RegionState = {
    region: Region;
    setRegion: (newRegion: Region) => void;
    exchangeRate: number;
};

export const RegionStateContext = createContext<RegionState | undefined>(undefined);

export function RegionStateProvider({ children }: { children?: ReactNode }) {
    const [cookies, setCookie] = useCookies(['region']);

    // Use initial region from cookies if existing and valid,
    // otherwise choose Europe
    const initialRegion = cookies.region
        ? Object.values(Region).includes(cookies.region)
            ? cookies.region
            : Region.EURO
        : Region.EURO;
    const [region, setRegion] = useState<Region>(initialRegion);

    // Set cookie anytime the region picker is changed
    useEffect(
        () =>
            setCookie('region', region, {
                path: '/',
                sameSite: 'strict',
                secure: true,
                maxAge: 365 * 24 * 60 * 60, // secs, expires in 1 years
            }),
        [region, setCookie],
    );

    // Get new exchange rate anytime region is changed
    const exchangeRate = useFetchExchangeRate(region);

    return (
        <RegionStateContext.Provider
            value={{
                region: region,
                setRegion: setRegion,
                exchangeRate: exchangeRate,
            }}
        >
            {children}
        </RegionStateContext.Provider>
    );
}
