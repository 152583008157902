import RuleCell from '../../../../features/rule-cell/RuleCell';
import { RuleData } from '../../../../types';
import styles from './RulesGrid.module.scss';
import { Virtuoso } from 'react-virtuoso';

import { Button, Dropdown, FormRow, Grid, GridCol, Legend } from '@flixbus/honeycomb-react';
import { Icon, IconArrowDown } from '@flixbus/honeycomb-icons-react';
import React, { useEffect, useState } from 'react';

type Props = {
    data: RuleData[];
    updateRuleActiveStatus: (ruleId: number, update: Partial<RuleData>) => void;
    initializeCloneForm: (ruleId: number) => void;
    initializeEditForm: (ruleId: number) => void;
};

const RulesGrid: React.FC<Props> = ({ data, updateRuleActiveStatus, initializeCloneForm, initializeEditForm }) => {
    const [rules, setRules] = useState(data);

    // Define sorting options and behaviour
    // Note this is a first naïve implementation, if it is to slow once there
    // is a lot of rules data, this might be re-implemented in backend!!
    const [sortState, setSortState] = useState<string>('');
    useEffect(() => {
        setRules(data);
        setSortState('');
    }, [data]);
    const linkArray = [
        {
            text: 'Last Modified At (DESC)',
            value: 0,
            href: '#',
            onClick: () => {
                setSortState('Last Modified At (DESC)');
                setRules(rules.sort((a, b) => b.lastModifiedAt.getTime() - a.lastModifiedAt.getTime()));
            },
        },
        {
            text: 'Last Modified At (ASC)',
            value: 1,
            href: '#',
            onClick: () => {
                setSortState('Last Modified At (ASC)');
                setRules(rules.sort((a, b) => a.lastModifiedAt.getTime() - b.lastModifiedAt.getTime()));
            },
        },
        {
            text: 'Alpha (DESC)',
            value: 2,
            href: '#',
            onClick: () => {
                setSortState('Alpha (DESC)');
                setRules(rules.sort((a, b) => b.alpha - a.alpha));
            },
        },
        {
            text: 'Alpha (ASC)',
            value: 3,
            href: '#',
            onClick: () => {
                setSortState('Alpha (ASC)');
                setRules(rules.sort((a, b) => a.alpha - b.alpha));
            },
        },
    ];
    // Set in sorting dropdown currently active sort mode
    const sortOptions = linkArray.map((option) => {
        return {
            ...option,
            activeItem: sortState === option.text ? true : false,
        };
    });

    return (
        <>
            <FormRow extraClasses={styles.headerRow}>
                <Grid align="bottom">
                    <GridCol key="rule-length-indicator" size={1}>
                        <Legend>{data.length} rules</Legend>
                    </GridCol>
                    <GridCol key="rule-sorting" size={1} push={10}>
                        <Dropdown links={sortOptions}>
                            <Button link>
                                Sort By <Icon InlineIcon={IconArrowDown} />
                            </Button>
                        </Dropdown>
                    </GridCol>
                </Grid>
            </FormRow>
            <Virtuoso
                useWindowScroll
                totalCount={rules.length}
                itemContent={(index) => (
                    <FormRow extraClasses={styles.row} key={`from-row-${index}`}>
                        <RuleCell
                            key={`rule-cell-${rules[index].id}`}
                            rule={rules[index]}
                            updateRuleActiveStatus={updateRuleActiveStatus}
                            initializeCloneForm={initializeCloneForm}
                            initializeEditForm={initializeEditForm}
                        />
                    </FormRow>
                )}
            />
        </>
    );
};

export default RulesGrid;
