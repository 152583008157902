import { AuthHeader } from './features/header/auth-header/AuthHeader';
import { FilterDataProvider } from './data/filter-data/filterData.context';
import FilterStep from './pages/rule-form/filter-step/FilterStep';
import { FormStateProvider } from './data/form-state/form.context';
import InfluenceStep from './pages/rule-form/influence-step/InfluenceStep';
import { NotFound } from './pages/not-found/NotFound';
import { RegionStateProvider } from './data/region-state/regionState.context';
import { RuleDataProvider } from './data/rules-data/ruleData.context';
import RuleForm from './pages/rule-form/RuleForm';
import RuleList from './pages/rule-list/RuleList';
import SubmitStep from './pages/rule-form/submit-step/SubmitStep';
import { UnauthHeader } from './features/header/unauth-header/UnauthHeader';

import { APP_PATHS, THEME } from './constants';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Login, Logout } from './features/auth';
import { MainWrapper, ThemeWrapper } from '@flixbus/honeycomb-react';
import React, { useMemo } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';

type Props = {
    isAuthenticated?: boolean;
};
const AppRoutes = ({ isAuthenticated }: Props) => {
    const routes = useMemo(() => {
        if (isAuthenticated) {
            return [
                { path: APP_PATHS.LOGOUT, element: <Logout /> },
                {
                    path: APP_PATHS.FORM_CREATE,
                    element: <RuleForm />,
                    children: [
                        { path: APP_PATHS.FORM_STEP_FILTER, element: <FilterStep /> },
                        { path: APP_PATHS.FORM_STEP_INFLUENCE, element: <InfluenceStep /> },
                        { path: APP_PATHS.FORM_STEP_SUBMIT, element: <SubmitStep /> },
                    ],
                },
                {
                    path: APP_PATHS.FORM_CLONE,
                    element: <RuleForm />,
                    children: [
                        { path: APP_PATHS.FORM_STEP_FILTER, element: <FilterStep /> },
                        { path: APP_PATHS.FORM_STEP_INFLUENCE, element: <InfluenceStep /> },
                        { path: APP_PATHS.FORM_STEP_SUBMIT, element: <SubmitStep /> },
                    ],
                },
                {
                    path: APP_PATHS.FORM_EDIT,
                    element: <RuleForm />,
                    children: [
                        { path: APP_PATHS.FORM_STEP_FILTER, element: <FilterStep /> },
                        { path: APP_PATHS.FORM_STEP_INFLUENCE, element: <InfluenceStep /> },
                        { path: APP_PATHS.FORM_STEP_SUBMIT, element: <SubmitStep isEditing /> },
                    ],
                },
                { path: APP_PATHS.HOME, element: <RuleList /> },
                { path: '*', element: <NotFound /> },
            ];
        }
        return [{ path: '*', element: <Login /> }];
    }, [isAuthenticated]);

    return useRoutes(routes);
};

const App = () => {
    return (
        <Router>
            <ThemeWrapper theme={THEME.name} themes={THEME.theme}>
                <MainWrapper full>
                    <AuthenticatedTemplate>
                        <RegionStateProvider>
                            <FilterDataProvider>
                                <RuleDataProvider>
                                    <FormStateProvider>
                                        <AuthHeader />
                                        <AppRoutes isAuthenticated />
                                    </FormStateProvider>
                                </RuleDataProvider>
                            </FilterDataProvider>
                        </RegionStateProvider>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <UnauthHeader />
                        <AppRoutes />
                    </UnauthenticatedTemplate>
                </MainWrapper>
            </ThemeWrapper>
        </Router>
    );
};

export default App;
