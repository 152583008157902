import React, { CSSProperties } from 'react';

type FlagSizes = 24 | 48 | 72 | 96;

export const getFlagIconUrl = (countryCode: string) => {
    return `https://honeycomb.flixbus.com/dist/4.4.0/img/flags/png/${countryCode}.png`;
};

type Props = {
    countryCode: string;
    size?: FlagSizes;
    style: CSSProperties;
};
export const CountryFlag: React.FC<Props> = ({ countryCode, style, ...rest }) => {
    const styles: CSSProperties = { objectFit: 'cover', ...style };
    return <img alt={`${countryCode} flag`} src={getFlagIconUrl(countryCode)} style={styles} {...rest} />;
};
