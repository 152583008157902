import { CityPairString } from '../../types';
import styles from './CityPairsTable.module.scss';
import { useDebounce } from '../../utils/hooks/useDebounce';
import { useFilterData } from '../../data/filter-data/useFilterData';

import { Button, DataTable, Grid, GridCol, Input, Pager, PagerArrow, PagerItem, Text } from '@flixbus/honeycomb-react';
import { cityPairStringToArray, sortCityPair, symmetricalCityPair } from '../../utils/cityPairs.utils';
import { Icon, IconArrowBigRight, IconClose, IconDelete, IconWidthBigger } from '@flixbus/honeycomb-icons-react';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

const SLICE_SIZE = 1000;

type CityPairTableRow = {
    key: string;
    cells: [string, ReactNode, string, ReactNode | null];
};

type Props = {
    cityPairsData: CityPairString[];
    removableRows?: boolean;
    onRemoveRow?: (removedCities: CityPairString[]) => void;
    pairsAsIds?: boolean;
};

const CityPairsTable: React.FC<Props> = ({
    cityPairsData,
    removableRows = false,
    onRemoveRow = undefined,
    pairsAsIds = false,
}) => {
    // Initialize status of header's search
    const [fromCity, setFromCity] = useState<string>('');
    const [toCity, setToCity] = useState<string>('');

    // Define searchable header
    const headers = {
        cells: [
            <Input
                key="from-city-table-search"
                id="from-city-table-search"
                aria-label="from-city-table-search"
                placeholder="City"
                inlineLabelLeft="From"
                type="search"
                value={fromCity}
                onChange={(e) => setFromCity(e.target.value)}
            />,
            '  ',
            <Input
                key="to-city-table-search"
                id="to-city-table-search"
                aria-label="to-city-table-search"
                placeholder="City"
                inlineLabelLeft="To  "
                type="search"
                value={toCity}
                onChange={(e) => setToCity(e.target.value)}
            />,
            removableRows ? (
                <Button
                    link
                    extraClasses={styles.removeButton}
                    display="square"
                    aria-label="Open Settings"
                    onClick={
                        onRemoveRow &&
                        (() => {
                            onRemoveRow(displayData);
                            setFromCity('');
                            setToCity('');
                        })
                    }
                >
                    <Icon InlineIcon={IconDelete} />
                </Button>
            ) : null,
        ],
    };

    // Filter data based on header fields
    const { filterData } = useFilterData();
    const debouncedFromCity = useDebounce<string>(fromCity, '', 200);
    const debouncedToCity = useDebounce<string>(toCity, '', 200);
    const displayData = useMemo<CityPairString[]>(
        () =>
            cityPairsData.filter((cityPair) => {
                let fromCityName, toCityName;
                if (pairsAsIds) {
                    const [fromCityUuid, toCityUuid] = cityPairStringToArray(cityPair);
                    fromCityName = filterData.cities.get(fromCityUuid)?.name as string;
                    toCityName = filterData.cities.get(toCityUuid)?.name as string;
                } else {
                    [fromCityName, toCityName] = cityPairStringToArray(cityPair);
                }
                const matchesFromCity = fromCityName.toLowerCase().includes(debouncedFromCity.toLowerCase());
                const matchesToCity = toCityName.toLowerCase().includes(debouncedToCity.toLowerCase());
                return matchesFromCity && matchesToCity;
            }),
        [cityPairsData, debouncedFromCity, debouncedToCity, filterData.cities, pairsAsIds],
    );

    // Prepare rows data to display
    const rows = useMemo<CityPairTableRow[]>(() => {
        const cityPairsMap = new Map();
        cityPairsData.forEach((item) => cityPairsMap.set(item, true));
        const existingPair = new Map();
        const rows: CityPairTableRow[] = [];
        for (const row of displayData) {
            const symRow = symmetricalCityPair(row);
            const isExisting = existingPair.get(row) || existingPair.get(symRow);

            if (!isExisting) {
                const isBidirectional = cityPairsMap.get(symRow);
                const pair = cityPairStringToArray(isBidirectional ? sortCityPair(row) : row);
                existingPair.set(row, true);
                const removeRows = [row];
                if (isBidirectional) removeRows.push(symRow);
                let fromCityName = pair[0];
                let toCityName = pair[1];
                if (pairsAsIds) {
                    fromCityName = filterData.cities.get(pair[0])!.name as string;
                    toCityName = filterData.cities.get(pair[1])!.name as string;
                }
                rows.push({
                    key: `row-${pair[0]}-${pair[1]}`,
                    cells: [
                        fromCityName,
                        isBidirectional ? (
                            <Icon InlineIcon={IconWidthBigger} />
                        ) : (
                            <Icon InlineIcon={IconArrowBigRight} />
                        ),
                        toCityName,
                        removableRows && (
                            <Button
                                link
                                extraClasses={styles.removeButton}
                                display="square"
                                aria-label="remove-relation"
                                onClick={onRemoveRow && (() => onRemoveRow(removeRows))}
                            >
                                <Icon InlineIcon={IconClose} />
                            </Button>
                        ),
                    ],
                });
            }
        }

        // Sort city pairs data alphabetically
        rows.sort((a, b) =>
            a.cells[0] === b.cells[0] ? a.cells[2].localeCompare(b.cells[2]) : a.cells[0].localeCompare(b.cells[0]),
        );

        return rows;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayData, onRemoveRow, removableRows]);

    // Pagination
    const pages = Array(Math.ceil(rows.length / SLICE_SIZE))
        .fill(0)
        .map((_, idx) => idx);
    const pagesLength = pages.length;
    const [currentRows, setCurrentRows] = useState(rows.slice(0, SLICE_SIZE));
    const [currentPage, setCurrentPage] = useState(0);
    useEffect(() => {
        setCurrentPage(0);
    }, [rows]);
    useEffect(
        () => setCurrentRows(rows.slice(currentPage * SLICE_SIZE, (currentPage + 1) * SLICE_SIZE)),
        [currentPage, rows],
    );

    return (
        <Grid align="center">
            <GridCol size={12}>
                <DataTable
                    headers={headers}
                    rows={currentRows}
                    scrollable="sticky-header"
                    height={rows.length > 5 ? '300px' : undefined}
                    small
                />
            </GridCol>
            <GridCol size={8} extraClasses={styles.pagerCol}>
                {pagesLength > 1 && (
                    <Pager Elem="button" aria-label="Table pagination">
                        <PagerArrow
                            aria-label="Previous Page"
                            disabled={currentPage === 0}
                            side="prev"
                            onClick={() => setCurrentPage(Math.max(0, currentPage - 1))}
                        />
                        {pagesLength < 6 ? (
                            (pages.map((page) => (
                                <PagerItem
                                    key={`page-${page}`}
                                    active={currentPage === page}
                                    onClick={() => {
                                        setCurrentPage(page);
                                        setCurrentRows(rows.slice(page * SLICE_SIZE, (page + 1) * SLICE_SIZE));
                                    }}
                                >
                                    {page + 1}
                                </PagerItem>
                            )) as any)
                        ) : (
                            <>
                                <PagerItem
                                    key={`page-${1}`}
                                    active={currentPage === 0}
                                    onClick={() => setCurrentPage(0)}
                                >
                                    1
                                </PagerItem>
                                <PagerItem active={currentPage === 1} onClick={() => setCurrentPage(1)}>
                                    2
                                </PagerItem>
                                <PagerItem
                                    Elem="span"
                                    active={currentPage > 1 && currentPage < pagesLength - 2}
                                    aria-label="Expand pagination"
                                >
                                    ...
                                </PagerItem>
                                <PagerItem
                                    active={currentPage === pagesLength - 2}
                                    onClick={() => setCurrentPage(pagesLength - 2)}
                                >
                                    {pagesLength - 2}
                                </PagerItem>
                                <PagerItem
                                    active={currentPage === pagesLength - 1}
                                    onClick={() => setCurrentPage(pagesLength - 1)}
                                >
                                    {pagesLength - 1}
                                </PagerItem>
                            </>
                        )}
                        <PagerArrow
                            aria-label="Next Page"
                            disabled={currentPage === pages.length - 1}
                            side="next"
                            onClick={() => setCurrentPage(Math.min(pages.length - 1, currentPage + 1))}
                        />
                    </Pager>
                )}
            </GridCol>
            <GridCol size={4} extraClasses={styles.textCol}>
                <Text small>{displayData.length} city pairs</Text>
            </GridCol>
        </Grid>
    );
};

export default CityPairsTable;
