import { useEffect, useRef, useState } from 'react';

export function useDebounce<T>(value: T, defaultValue: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(defaultValue);
    const firstDebounce = useRef(true);

    useEffect(() => {
        if (value && firstDebounce.current) {
            setDebouncedValue(value);
            firstDebounce.current = false;
            return;
        }

        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
}
