import { DistanceUnit, Region, REGION_MAP } from '../constants';

function kmToMiles(kmDistance: number): number {
    return kmDistance * 0.62137119;
}

export function milesToKM(milesDistance: number): number {
    return milesDistance * 1.609344;
}

export function kmToCurrRegionDistance(kmDistance: number, region: Region): number {
    if (REGION_MAP[region].distanceUnit === DistanceUnit.MILES) {
        return kmToMiles(kmDistance);
    }

    return kmDistance;
}
