import { DateRange } from '../../../types';
import { dateRangeToString } from '../../../utils/date.utils';
import styles from './MultipleDateRangeInput.module.scss';
import useOutsideClick from '../../../utils/hooks/useOutsideClicks';

import { Calendar, Input, Tag } from '@flixbus/honeycomb-react';
import { Icon, IconCalendar } from '@flixbus/honeycomb-icons-react';
import React, { useEffect, useRef, useState } from 'react';

const DAYS_IN_THE_FUTURE = 366;

type Props = {
    label: string;
    dateRanges: DateRange[];
    includePast?: boolean;
    valid?: boolean;
    infoError?: string;
    onDateRangesChange: (datesRange: DateRange[]) => void;
};

const MultipleDateRangeInput: React.FC<Props> = ({
    label,
    dateRanges,
    includePast = false,
    valid = undefined,
    infoError = '',
    onDateRangesChange,
}) => {
    const calendarContainerRef = useRef<HTMLDivElement>(null);

    // Set internal date range state
    const [internalRange, setInternalRange] = useState<[Date | undefined, Date | undefined]>([undefined, undefined]);

    // Define the calendar range
    const calendarStart = includePast ? new Date(2010, 0, 0) : new Date();
    const calendarEnd = new Date();
    calendarEnd.setDate(calendarEnd.getDate() + DAYS_IN_THE_FUTURE);

    // Define calendart show logic when inputting
    const [show, setShow] = useState<boolean>(false);
    const showCalendar = () => setShow(true);
    const hideCalendar = () => setShow(false);

    // Register callback to close calendar on outside click
    useOutsideClick(calendarContainerRef, () => {
        hideCalendar();
    });

    // Handle date selection
    const onDateSelected = (date: Date) => {
        const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
        if (!internalRange[0]) {
            setInternalRange([d, undefined]);
        } else if (!internalRange[1]) {
            if (d >= internalRange[0]) {
                setInternalRange([internalRange[0], d]);
                hideCalendar();
            }
        } else {
            setInternalRange([date, undefined]);
        }
    };

    // Handle clearing value
    const onClearDateRange = (e: DateRange) => {
        onDateRangesChange(dateRanges.filter((dateRange) => dateRange !== e));
    };

    // TODO: handle deletion of all date ranges

    // Set external date range
    useEffect(() => {
        if (internalRange[0] && internalRange[1]) {
            const newDateRanges = [...dateRanges, [internalRange[0], internalRange[1]]];
            const sortedDateRanges: DateRange[] = newDateRanges.sort(
                (a, b) => a[0].getTime() - b[0].getTime(),
            ) as DateRange[];
            onDateRangesChange(sortedDateRanges);
            setInternalRange([undefined, undefined]);
        }
    }, [dateRanges, internalRange, onDateRangesChange]);

    return (
        <>
            <Input
                id="date-range-input"
                label={label}
                iconLeft={<Icon InlineIcon={IconCalendar} />}
                valid={valid}
                infoError={infoError}
                onClick={showCalendar}
            />
            <div id="floating-calendar" style={{ position: 'absolute', zIndex: 10 }} ref={calendarContainerRef}>
                <Calendar
                    id="date-range-picker"
                    hidden={!show}
                    startDate={calendarStart}
                    endDate={calendarEnd}
                    defaultMonth={new Date()}
                    appearance={'compact'}
                    startSelected={internalRange[0]}
                    endSelected={internalRange[1]}
                    handleSelect={onDateSelected}
                />
            </div>
            {dateRanges.map((dateRange, index) => {
                const dateRangeString = dateRangeToString(dateRange);
                return (
                    <Tag
                        key={`date-range-tag-${dateRangeString.replaceAll(' ', '')}-${index}`}
                        closeProps={{
                            label: 'Close date range tag',
                            onClick: () => onClearDateRange(dateRange),
                        }}
                        extraClasses={styles.dateRangeTag}
                    >
                        {dateRangeString}
                    </Tag>
                );
            })}
        </>
    );
};

export default MultipleDateRangeInput;
