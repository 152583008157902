import { APP_PATHS } from '../../../../constants';
import cn from 'classnames';
import DateRangeInput from '../../../../features/date-range-input/single-date-range-input/DateRangeInput';
import { Link } from 'react-router-dom';
import MultiAutocompleteInput from '../../../../features/autocomplete/MultiAutocompleteInput';
import styles from './FilterHub.module.scss';
import { useFilterData } from '../../../../data/filter-data/useFilterData';

import { AutocompleteOptionType, Button, Divider, Grid, GridCol, Input, Switch } from '@flixbus/honeycomb-react';
import { DateRange, FilterData } from '../../../../types';
import { Icon, IconArrowDown, IconArrowUp, IconDelete, IconMagnifier } from '@flixbus/honeycomb-icons-react';
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';

type Props = {
    search: string;
    onSearchChange: (newSearch: string) => void;
    loadingSearch: boolean;
    tags: string[];
    onTagsChange: (newTags: string[]) => void;
    fromCities: string[];
    onFromCitiesChange: (newFromCities: string[]) => void;
    toCities: string[];
    onToCitiesChange: (newToCity: string[]) => void;
    dateRange: DateRange | undefined;
    onDateRangeChange: (newDateRange: DateRange | undefined) => void;
    lastModifiers: string[];
    onLastModifiersChange: (newLastModifier: string[]) => void;
    active: boolean;
    onActiveChange: (newActive: boolean) => void;
    archieved: boolean;
    onArchievedChange: (newActive: boolean) => void;
    modifiersData: AutocompleteOptionType[];
    onClearFilters: () => void;
};

const FilterHub: React.FC<Props> = ({
    search,
    onSearchChange,
    loadingSearch,
    tags,
    onTagsChange,
    fromCities,
    onFromCitiesChange,
    toCities,
    onToCitiesChange,
    dateRange,
    onDateRangeChange,
    lastModifiers,
    onLastModifiersChange,
    active,
    onActiveChange,
    modifiersData,
    onClearFilters,
}) => {
    const { tagsData, filterData } = useFilterData();

    function formatCitiesData(filterData: FilterData): AutocompleteOptionType[] {
        return Array.from(filterData.cities.values())
            .map((city) => ({ title: city.name }))
            .sort((a, b) => a.title.localeCompare(b.title));
    }

    const cityOptions = useMemo(() => formatCitiesData(filterData), [filterData]);

    // Set internal state
    const [showFilter, setShowFilters] = useState<boolean>(false);
    const showClearFilters =
        tags.length || lastModifiers.length || fromCities.length || toCities.length || dateRange?.length ? true : false;

    return (
        <Grid align="center" aria-live="polite" aria-busy="false">
            <GridCol key="filterhub-title-input" extraClasses={styles.firstRow} size={5}>
                <Input
                    id="search-input"
                    aria-label="search"
                    type="search"
                    iconLeft={<Icon InlineIcon={IconMagnifier} />}
                    value={search}
                    loading={loadingSearch}
                    placeholder="Search in rule titles, tags, cities and users"
                    onChange={(e) => onSearchChange(e.target.value)}
                />
            </GridCol>
            <GridCol key="filterhub-switch" extraClasses={styles.firstRow} size={5}>
                <Switch
                    id="active-switch"
                    label="Active"
                    checked={active}
                    onChange={(e) => onActiveChange(e.target.checked)}
                />
                {/* <Switch
          id="archieved-switch"
          label="Archieved"
          checked={archieved}
          onChange={(e) => onArchievedChange(e.target.checked)}
        /> */}
            </GridCol>
            <GridCol key="filterhub-create-rule-button" extraClasses={styles.firstRow} size={2}>
                <Link to={`${APP_PATHS.FORM_CREATE}/${APP_PATHS.FORM_STEP_FILTER}`} style={{ textDecoration: 'none' }}>
                    <Button appearance="primary" display="block">
                        Create Rule
                    </Button>
                </Link>
            </GridCol>
            <GridCol
                key="filterhub-show-filters-button"
                extraClasses={cn(styles.filtersButtonRow, {
                    [styles.filtersClosed]: !showFilter,
                })}
                size={showClearFilters ? 10 : 12}
            >
                <Button extraClasses={styles.filtersButton} link onClick={() => setShowFilters(!showFilter)}>
                    Filters <Icon InlineIcon={showFilter ? IconArrowUp : IconArrowDown} />
                </Button>
            </GridCol>
            {showClearFilters && (
                <GridCol
                    key="filterhub-clear-button"
                    extraClasses={cn(styles.filtersButtonRow, {
                        [styles.filtersClosed]: !showFilter,
                        [styles.clearFilters]: showClearFilters,
                    })}
                    size={2}
                >
                    <Button link display="block" onClick={onClearFilters}>
                        Clear Filters
                        <Icon InlineIcon={IconDelete} />
                    </Button>
                </GridCol>
            )}
            {showFilter && (
                <GridCol>
                    <Grid>
                        <GridCol key="filter-hub-filters-divider" extraClasses={styles.dividerRow} size={12}>
                            <Divider />
                        </GridCol>
                        <GridCol key="filterhub-filters-first-row" size={12}>
                            <Grid>
                                <GridCol key="filterhub-tags-input" extraClasses={styles.firstFiltersRow} size={4}>
                                    <MultiAutocompleteInput
                                        label="Tags"
                                        placeholder="AND search"
                                        value={tags}
                                        autocompleteData={tagsData}
                                        onValueChange={onTagsChange}
                                    />
                                </GridCol>
                                <GridCol key="filterhub-author-input" extraClasses={styles.firstFiltersRow} size={4}>
                                    <MultiAutocompleteInput
                                        label="Last Modified By"
                                        placeholder="OR search"
                                        value={lastModifiers}
                                        autocompleteData={modifiersData}
                                        onValueChange={onLastModifiersChange}
                                    />
                                </GridCol>
                            </Grid>
                        </GridCol>
                        <GridCol key="filterhub-from-city-input" extraClasses={styles.lastFiltersRow} size={4}>
                            <MultiAutocompleteInput
                                label="From Cities"
                                placeholder="OR search"
                                value={fromCities}
                                autocompleteData={cityOptions}
                                onValueChange={onFromCitiesChange}
                            />
                        </GridCol>
                        <GridCol key="filterhub-to-city-input" extraClasses={styles.lastFiltersRow} size={4}>
                            <MultiAutocompleteInput
                                label="To Cities"
                                placeholder="OR search"
                                value={toCities}
                                autocompleteData={cityOptions}
                                onValueChange={onToCitiesChange}
                            />
                        </GridCol>
                        <GridCol key="filterhub-departures-input" extraClasses={styles.lastFiltersRow} size={4}>
                            <DateRangeInput
                                label="City Departure Dates"
                                dateRange={dateRange}
                                includePast
                                onDateRangeChange={onDateRangeChange}
                            />
                        </GridCol>
                    </Grid>
                </GridCol>
            )}
        </Grid>
    );
};

export default FilterHub;
