import React from 'react';

import { Box, Grid, GridCol, Text } from '@flixbus/honeycomb-react';

import { dateToDEString } from '../../../../utils/date.utils';

import styles from './CustomTooltip.module.scss';

const CustomTooltip = (props: any) => {
    const { active, payload, label, distanceUnit, currencySymbol, displayKPI } = props;
    if (active && payload && payload.length) {
        let base = 0;
        let modification = 0;
        let finalAlpha = 0;
        let PBP = '';
        payload.forEach((item: any) => {
            if (item.dataKey === 'base') {
                base = item.value;
            } else if (item.dataKey === 'increased') {
                modification = item.value;
            } else if (item.dataKey === 'decreased') {
                modification = -item.value;
            } else if (item.dataKey === 'alpha') {
                finalAlpha = item.value;
            } else if (item.dataKey === 'daysBeforeDeparture') {
                PBP = `(${item.value[0]}, ${item.value[1]}]`;
            }
        });
        let forecastPrice = 0;
        let modPrice = 0;
        if (modification == 0) {
            forecastPrice = base;
            modPrice = base;
        } else if (modification > 0) {
            forecastPrice = base;
            modPrice = base + modification;
        } else if (modification < 0) {
            forecastPrice = base - modification;
            modPrice = base;
        }
        const displayMetric = displayKPI === 'yield' ? 'Yield' : 'Price';
        const unit = displayKPI === 'yield' ? `${currencySymbol} / 100${distanceUnit}` : `${currencySymbol}`;
        return (
            <Box highlighted extraClasses={styles.tooltipBox}>
                <Grid>
                    <GridCol size={12}>
                        <Text extraClasses={styles.tooltipTitle}>Departure: {dateToDEString(label)}</Text>
                    </GridCol>
                    <GridCol size={12}>
                        <Text small>{`Modified Forecast ${displayMetric}: ${modPrice.toFixed(2)} ${unit}`}</Text>
                    </GridCol>
                    <GridCol size={12}>
                        <Text small>{`Forecast ${displayMetric}: ${forecastPrice.toFixed(2)} ${unit}`}</Text>
                    </GridCol>
                    <GridCol size={12}>
                        <Text small>Final Alpha: {finalAlpha.toFixed(0)}%</Text>
                    </GridCol>
                    <GridCol size={12}>
                        <Text small>Pre-Booking Period: {PBP}</Text>
                    </GridCol>
                </Grid>
            </Box>
        );
    }
    return null;
};

export default CustomTooltip;
