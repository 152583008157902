import { addDataDogError } from '../../utils/datadog/datadog.utils';
import { ENDPOINT_URLS } from '../../constants';
import { FilterData } from '../../types';
import { useAxios } from '../../utils/transport/useAxios';
import { useToast } from '../../features/toasts-container';

import { useEffect, useState } from 'react';

function generateEmptyFilterData(): FilterData {
    return {
        tags: [],
        cityPairs: [],
        countries: new Map(),
        cities: new Map(),
    } as unknown as FilterData;
}

const parseRulesData = (data: any): FilterData => {
    if (!data.tags || !data.city_pairs || !data.countries) {
        console.error(data);
        throw new Error('Invalid filter data');
    }
    const parsedData = generateEmptyFilterData();
    parsedData['tags'] = data.tags;
    data.city_pairs.forEach((cityPair: any) => {
        parsedData.cityPairs.push({
            fromUuid: cityPair.from_uuid,
            toUuid: cityPair.to_uuid,
            distance: cityPair.distance,
        });
    });
    data.countries.forEach((country: any) => {
        parsedData.countries.set(country.country_code, {
            countryName: country.country,
            countryCode: country.country_code,
            cities: country.cities,
        });
        country.cities.forEach((c: any) => {
            const city = {
                uuid: c.uuid,
                name: c.name,
                countryCode: country.country_code,
            };
            parsedData.cities.set(c.uuid, city);
        });
    });
    return parsedData;
};

export default function useFetchFilterData(): {
    data: FilterData;
    loading: boolean;
} {
    const apiClient = useAxios();
    const { addToast } = useToast();
    const [filterData, setFilterData] = useState<FilterData>(generateEmptyFilterData());
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchFilterData = async () => {
            setLoading(true);
            try {
                const res = await apiClient.get(ENDPOINT_URLS.FILTER_DATA);

                if (res.status !== 200) {
                    throw new Error(`Error with status ${res.status}`);
                }

                const parsedData = parseRulesData(res.data);
                setFilterData(parsedData);
            } catch (error) {
                addDataDogError(error as Error);
                addToast({
                    type: 'danger',
                    content: `Filter data API call failed: ${(error as Error).message}.`,
                });
                setFilterData(generateEmptyFilterData());
            } finally {
                setLoading(false);
            }
        };

        fetchFilterData();
    }, [addToast, apiClient]);

    return {
        data: filterData,
        loading: loading,
    };
}
