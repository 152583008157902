import ChartTitle from '../common/ChartTitle';
import CustomTooltip from './components/CustomTooltip';
import { dateToDEString } from '../../../utils/date.utils';
import styles from './ChartPerPBP.module.scss';
import { visDataToPBPChartData } from '../../../utils/visualization.utils';
import { BarChartDataPoint, CityPairString, DisplayKPIs, VisDataPoint } from '../../../types';
import { Region, REGION_MAP } from '../../../constants';

import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Label,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { Grid, GridCol } from '@flixbus/honeycomb-react';

import React, { useMemo } from 'react';

const TRACE_COLORS = {
    base: styles.basecolor,
    increased: styles.increasecolor,
    decreased: styles.decreasecolor,
    alpha: styles.alphacolor,
};

type Props = {
    cityPair: CityPairString;
    selectedDate: Date | undefined;
    data: VisDataPoint[];
    priceDomain: [number, number];
    alphaDomain: [number, number];
    region: Region;
    exchangeRate: number;
    onClickPreviousDay: (() => void) | undefined;
    onClickNextDay: (() => void) | undefined;
    displayKPI: DisplayKPIs;
};

const ChartPerPBP: React.FC<Props> = ({
    cityPair,
    selectedDate,
    data,
    priceDomain,
    alphaDomain,
    region,
    exchangeRate,
    onClickPreviousDay,
    onClickNextDay,
    displayKPI,
}) => {
    const visData: BarChartDataPoint[] = useMemo(
        function prepareVisData() {
            return visDataToPBPChartData(data, selectedDate, exchangeRate);
        },
        [data, selectedDate, exchangeRate],
    );

    const currencySymbol = REGION_MAP[region].symbol;
    const distanceUnit = REGION_MAP[region].distanceUnit;
    const yLabel =
        displayKPI === 'yield'
            ? `Forecast Yield (${currencySymbol} / 100${distanceUnit})`
            : `Forecast Price (${currencySymbol})`;
    const yPosition = displayKPI === 'yield' ? 75 : 50;

    return (
        <Grid>
            <GridCol size={12}>
                <ChartTitle
                    cityPair={cityPair}
                    dates={selectedDate ? dateToDEString(selectedDate) : ''}
                    onClickPreviousDay={onClickPreviousDay}
                    onClickNextDay={onClickNextDay}
                />
            </GridCol>
            <GridCol size={12}>
                <ResponsiveContainer width="100%" height={275}>
                    <ComposedChart
                        className={styles.chart}
                        data={visData}
                        barSize={24}
                        margin={{
                            top: 30,
                            right: 10,
                            left: 15,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <YAxis yAxisId="left" orientation="left" type="number" domain={priceDomain}>
                            <Label value={yLabel} angle={-90} position="insideLeft" offset={0} dx={5} dy={yPosition} />
                        </YAxis>
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            type="number"
                            domain={alphaDomain}
                            allowDecimals={false}
                        >
                            <Label
                                value="Alpha Influence (%)"
                                angle={-90}
                                position="right"
                                offset={0}
                                dx={-10}
                                dy={50}
                            />{' '}
                        </YAxis>
                        <XAxis dataKey="dayBeforeDeparture" height={60}>
                            <Label value="Pre-booking period" dy={15} />
                        </XAxis>
                        <Tooltip
                            content={
                                <CustomTooltip
                                    distanceUnit={distanceUnit}
                                    currencySymbol={currencySymbol}
                                    displayKPI={displayKPI}
                                />
                            }
                        />
                        <Bar
                            id="ID01"
                            yAxisId="left"
                            stackId="a"
                            dataKey="base"
                            fill={TRACE_COLORS.base}
                            opacity={0.8}
                        />
                        <Bar id="ID02" yAxisId="left" stackId="a" dataKey="increased" fill={TRACE_COLORS.increased} />
                        <Bar id="ID03" yAxisId="left" stackId="a" dataKey="decreased" fill={TRACE_COLORS.decreased} />
                        <Scatter id="ID04" yAxisId="right" type="monotone" dataKey="alpha" fill={TRACE_COLORS.alpha} />
                        {/* TODO: The last scatter plot is hacky way to make 
                      daysBeforeDeparture reach the tooltip.
                      Find a better solution! */}
                        <YAxis yAxisId="PBP" hide={true} />
                        <Scatter id="ID05" yAxisId="PBP" dataKey="daysBeforeDeparture" width={0} />
                    </ComposedChart>
                </ResponsiveContainer>
            </GridCol>
        </Grid>
    );
};

export default ChartPerPBP;
