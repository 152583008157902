import { AlphaInfluenceBox } from './components/alpha-influence-box/AlphaInfluenceBox';
import ImpactAnalysis from './components/impact-analysis/ImpactAnalysis';
import OverlapsBox from './components/overlaps-box/OverlapsBox';
import { PBPBox } from './components/pbp-box/PBPBox';
import styles from './InfluenceStep.module.scss';
import { useFormState } from '../../../data/form-state/useFormState';
import { useRuleFormContext } from '../RuleForm';

import { Button, Grid, GridCol } from '@flixbus/honeycomb-react';
import { Icon, IconArrowLeft, IconArrowRight } from '@flixbus/honeycomb-icons-react';
import React, { useState } from 'react';

const InfluenceStep: React.FC = () => {
    // Get form context state
    const {
        goToSubmitStep,
        goPrevStep,
        alpha,
        setAlpha,
        daysBeforeDeparture,
        setDaysBeforeDeparture,
        validAlpha,
        validPBP,
    } = useFormState();
    const ruleCTX = useRuleFormContext();

    // Get Alpha box height and set PBP box one
    const [alphaBoxHeight, setAlphaBoxHeight] = useState(0);

    return (
        <Grid align="top">
            <GridCol size={12}>
                <Button link extraClasses={styles.backButton} onClick={goPrevStep}>
                    <Icon InlineIcon={IconArrowLeft} /> Back
                </Button>
            </GridCol>
            <GridCol key="alpha-influence-input" size={3}>
                <AlphaInfluenceBox
                    value={alpha}
                    onValueChange={setAlpha}
                    onHeightChange={setAlphaBoxHeight}
                    error={!validAlpha}
                    infoError="This is a mandatory field"
                />
            </GridCol>
            <GridCol key="pre-booking-period-input" size={9}>
                <PBPBox
                    value={daysBeforeDeparture}
                    onValueChange={setDaysBeforeDeparture}
                    height={alphaBoxHeight}
                    error={!validPBP}
                    infoError="The period must be at least one day"
                />
            </GridCol>
            <GridCol key="visualization" size={12}>
                <ImpactAnalysis />
            </GridCol>
            <GridCol key="overlap-info-box" size={12}>
                <OverlapsBox />
            </GridCol>
            <GridCol push={9} size={1}>
                <ruleCTX.CancelButton />
            </GridCol>
            <GridCol size={2}>
                <Button
                    extraClasses={styles.continueButton}
                    appearance="secondary"
                    display="block"
                    onClick={goToSubmitStep}
                >
                    Continue <Icon InlineIcon={IconArrowRight} />
                </Button>
            </GridCol>
        </Grid>
    );
};

export default InfluenceStep;
