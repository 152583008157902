import React from 'react';

import { Heading, Notification, NotificationContainer, Text } from '@flixbus/honeycomb-react';

import { Toast } from './ToastsProvider';

type Props = {
    toasts: Toast[];
    removeToast: (id: number) => void;
};

const ToastsContainerComp: React.FC<Props> = ({ removeToast, toasts }) => {
    const topToasts: Toast[] = [];
    const bottomToasts: Toast[] = [];

    toasts.forEach((t) => {
        if (t.position === 'bottom-left') {
            bottomToasts.push(t);
        } else {
            topToasts.push(t);
        }
    });

    return (
        <div style={{ whiteSpace: 'pre-line' }}>
            <NotificationContainer position={'top-right'}>
                {topToasts.map((t) => {
                    const onCloseClick = () => removeToast(t.id);
                    const closeProps = {
                        onClick: onCloseClick,
                        'aria-label': `${t.type}-notification-${t.id}`,
                    };

                    return (
                        <Notification key={t.id} toast={false} appearance={t.type} closeProps={closeProps}>
                            {t.description ? (
                                <Heading size={4} flushSpace>
                                    {t.content}
                                </Heading>
                            ) : (
                                <Text>{t.content}</Text>
                            )}
                            {t.description && <Text small>{t.description}</Text>}
                        </Notification>
                    );
                })}
            </NotificationContainer>
            <NotificationContainer position={'bottom-left'}>
                {bottomToasts.map((t) => {
                    const onCloseClick = () => removeToast(t.id);
                    const closeProps = {
                        onClick: onCloseClick,
                        'aria-label': `${t.type}-notification-${t.id}`,
                    };

                    return (
                        <Notification key={t.id} toast={false} appearance={t.type} closeProps={closeProps}>
                            {t.description ? (
                                <Heading size={4} flushSpace>
                                    {t.content}
                                </Heading>
                            ) : (
                                <Text>{t.content}</Text>
                            )}
                            {t.description && <Text>{t.description}</Text>}
                        </Notification>
                    );
                })}
            </NotificationContainer>
        </div>
    );
};

export const ToastsContainer = React.memo(ToastsContainerComp);
