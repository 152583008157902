import React from 'react';

import cn from 'classnames';

import { SelectGroup } from '@flixbus/honeycomb-react';

import { DaysOfTheWeek } from '../../types';
import styles from './DaysOfTheWeekInput.module.scss';

const daysOfTheWeekOptions = [
    { id: 1, value: 1, name: 'Monday', label: 'Mon' },
    { id: 2, value: 2, name: 'Tuesday', label: 'Tue' },
    { id: 3, value: 3, name: 'Wednesday', label: 'Wed' },
    { id: 4, value: 4, name: 'Thursday', label: 'Thu' },
    { id: 5, value: 5, name: 'Friday', label: 'Fri' },
    { id: 6, value: 6, name: 'Saturday', label: 'Sat' },
    { id: 7, value: 7, name: 'Sunday', label: 'Sun' },
];

type Props = {
    label?: string;
    daysOfTheWeek: DaysOfTheWeek;
    valid?: boolean;
    infoError?: string;
    onClick?: (daysOfTheWeek: DaysOfTheWeek) => void;
};

const DaysOfTheWeekInput: React.FC<Props> = ({
    label = undefined,
    daysOfTheWeek,
    valid = undefined,
    infoError = undefined,
    onClick = undefined,
}) => {
    const onDayOfTheWeekClick = onClick
        ? (event: React.MouseEvent) => {
              const internatDaysOfTheWeek = daysOfTheWeek;
              const target = event.target as HTMLInputElement;
              internatDaysOfTheWeek[(target.value as any) - 1] = target.checked;
              onClick(internatDaysOfTheWeek);
          }
        : (event: React.MouseEvent) => {
              event.preventDefault();
              event.stopPropagation();
          };

    const selectOptions = [];
    for (let i = 0; i < 7; i++) {
        selectOptions[i] = {
            ...daysOfTheWeekOptions[i],
            defaultChecked: daysOfTheWeek[i],
            onClick: onDayOfTheWeekClick,
        };
    }

    return (
        <SelectGroup
            multi={true}
            label={label}
            aria-label="days-of-the-week"
            options={selectOptions}
            error={valid !== undefined ? !valid : undefined}
            infoError={infoError}
            extraClasses={cn(styles.daysOfTheWeek, { [styles.displayElement]: !onClick })}
        />
    );
};

export default DaysOfTheWeekInput;
