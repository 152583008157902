import { cityPairArrayToString } from '../../utils/cityPairs.utils';
import cn from 'classnames';
import { CSVLink } from 'react-csv';
import { dateToDEString } from '../../utils/date.utils';
import { kmToCurrRegionDistance } from '../../utils/distance.utils';
import Papa from 'papaparse';
import { reportPreferredCityPairsSelectionMethod } from '../../utils/datadog/datadog.utils';
import styles from './CSVUpload.module.scss';
import { useDropzone } from 'react-dropzone';
import { useFilterData } from '../../data/filter-data/useFilterData';
import { useRegionState } from '../../data/region-state/useRegionState';

import { Box, Button, Grid, GridCol, List, Tag, Text } from '@flixbus/honeycomb-react';
import { CityPairString, FilterData } from '../../types';
import { Icon, IconClose, IconUpload } from '@flixbus/honeycomb-icons-react';
import React, { useEffect, useRef, useState } from 'react';
import { Region, REGION_MAP } from '../../constants';

const ALLOWED_MAX_FILE_SIZE_MB = 10;
const isValidUUID = (s: string): boolean => {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return s.match(regexExp) ? true : false;
};

type Props = {
    addCityPairs: (cityPairs: CityPairString[]) => void;
    uploadedFileName: string;
    setUploadedFileName: (uploadedFileName: string) => void;
};

const CSVUpload: React.FC<Props> = ({ addCityPairs, uploadedFileName, setUploadedFileName }) => {
    // Get filter data
    const { filterData, getCityPairNames } = useFilterData();

    // Get region state
    const { region } = useRegionState();

    // Define internal state
    const [validFile, setValidFile] = useState<boolean>(true);
    const [infoError, setInfoError] = useState<string>('');
    const [uploadDisabled, setUploadDisabled] = useState<boolean>(false);
    const [uploadSuccessInfo, setUploadSuccesInfo] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        uploadedFileName !== '' ? setUploadDisabled(true) : setUploadDisabled(false);
    }, [uploadedFileName]);

    const inputRef = useRef<HTMLInputElement>(null);
    const handleClick = () => {
        if (inputRef.current) {
            // Open file input box on click of other element
            inputRef.current.click();
        }
    };

    const handleFileUpload = (fileObj: File) => {
        if (!fileObj) {
            setLoading(false);
            return;
        }
        // // Reset file input
        // e.target.value = null;
        // Reject file if it is too big
        if (fileObj.size > ALLOWED_MAX_FILE_SIZE_MB * 1000000) {
            setLoading(false);
            setValidFile(false);
            setInfoError(
                `The uploaded file ${fileObj.name} is bigger than the
                 maximum allowed of ${ALLOWED_MAX_FILE_SIZE_MB}MB`,
            );
            return;
        }
        // Parse CSV
        Papa.parse(fileObj, {
            complete: (result: { data: string[] }) => {
                const data: any[] = [];
                let totalRows = 0;
                let acceptedRows = 0;
                result.data.forEach((row) => {
                    /* Ignore empty rows and header row (identified by
                       containing keyword 'from' on the first column name) */
                    if (row.length > 0 && row[0] && !/^from/i.test(row[0].toLowerCase())) {
                        totalRows += 1;
                        if (row.length >= 2) {
                            if (isValidUUID(row[0]) && isValidUUID(row[1])) {
                                const cityPair = cityPairArrayToString([row[0], row[1]]);
                                if (getCityPairNames(cityPair)) {
                                    acceptedRows += 1;
                                    data.push(cityPair);
                                }
                            }
                        }
                    }
                });
                setLoading(false);
                if (data.length === 0) {
                    setValidFile(false);
                    setInfoError(`The uploaded file ${fileObj.name} has no valid entries`);
                } else {
                    reportPreferredCityPairsSelectionMethod('CSVUpload');
                    setValidFile(true);
                    setInfoError('');
                    setUploadedFileName(fileObj.name);
                    addCityPairs(data);
                    setUploadSuccesInfo(`${acceptedRows}/${totalRows} valid rows`);
                }
            },
        });
    };

    const onSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true);
        if (e.target.files) {
            handleFileUpload(e.target.files[0]);
        }
    };

    const onDrop = (e: File[]) => {
        setLoading(true);
        handleFileUpload(e[0]);
    };

    const onDropRejected = () => {
        setValidFile(false);
        setInfoError('The dropped file must be a CSV');
        return;
    };

    // Set up Drag & Drop functionality
    const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
        accept: {
            'text/csv': ['.csv'],
        },
        maxFiles: 1,
        multiple: false,
        disabled: uploadDisabled,
        onDropAccepted: onDrop,
        onDropRejected: onDropRejected,
        noClick: true,
        noKeyboard: true,
    });

    const prepareCSVDownloadData = (filterData: FilterData, region: Region): any => {
        const distanceUnit = REGION_MAP[region].distanceUnit;
        const csvData = [
            // Header data
            [
                'from_city_uuid',
                'to_city_uuid',
                'from_city',
                'to_city',
                `distance_${distanceUnit}`,
                'from_country',
                'to_country',
            ],
        ];
        if (filterData.cityPairs) {
            filterData.cityPairs.forEach((cityPair) =>
                csvData.push([
                    cityPair.fromUuid,
                    cityPair.toUuid,
                    filterData.cities.get(cityPair.fromUuid)?.name as string,
                    filterData.cities.get(cityPair.toUuid)?.name as string,
                    kmToCurrRegionDistance(cityPair.distance, region).toFixed(0),
                    filterData.cities.get(cityPair.fromUuid)?.countryCode as string,
                    filterData.cities.get(cityPair.toUuid)?.countryCode as string,
                ]),
            );
            return csvData;
        }
    };

    return (
        <div {...getRootProps()}>
            <Box
                extraClasses={cn(
                    styles.uploadBox,
                    { [styles.boxError]: !validFile || isDragReject },
                    { [styles.boxAcceptedDrag]: isDragAccept },
                )}
            >
                {!uploadedFileName ? (
                    <Grid>
                        <GridCol key={'csv-upload-header'} size={12}>
                            <div>
                                <Text extraClasses={styles.dragAndDropText}>
                                    <Button
                                        extraClasses={styles.uploadButton}
                                        appearance="tertiary"
                                        onClick={handleClick}
                                        loading={loading}
                                    >
                                        <Icon InlineIcon={IconUpload} /> Browse CSV file
                                    </Button>
                                    <input
                                        {...getInputProps()}
                                        style={{ display: 'none' }}
                                        ref={inputRef}
                                        type="file"
                                        accept=".csv"
                                        disabled={uploadDisabled}
                                        onChange={onSubmit}
                                    />
                                    or drag & drop it here
                                </Text>
                            </div>
                        </GridCol>
                        <GridCol key={'csv-upload-instructions'} size={12}>
                            <List extraClasses={styles.instructionList}>
                                <li key="csv-instruction-1">
                                    <Text small extraClasses={styles.instructionText}>
                                        The CSV file must have two columns: the first one with From City UUIDs and the
                                        second one with To City UUIDs
                                    </Text>
                                </li>
                                <li key="csv-instruction-2">
                                    <Text small extraClasses={styles.instructionText}>
                                        Only the first two columns will be considered, the rest will be ignored
                                    </Text>
                                </li>
                                <li key="csv-instruction-3">
                                    <Text small extraClasses={styles.instructionText}>
                                        Any row with empty values or any value different to a valid UUID will be
                                        ignored, including the header
                                    </Text>
                                </li>
                                <li key="csv-instruction-4">
                                    <Text small extraClasses={styles.instructionText}>
                                        <CSVLink
                                            className="flix-link"
                                            data={prepareCSVDownloadData(filterData, region)}
                                            filename={`OD_city_pairs_${dateToDEString(new Date())}.csv`}
                                        >
                                            Download template CSV file
                                        </CSVLink>{' '}
                                        for all city pairs with an interconnection elasticity forecast
                                    </Text>
                                </li>
                            </List>
                        </GridCol>
                    </Grid>
                ) : (
                    <Grid align="bottom">
                        <GridCol size={8}>
                            <>
                                <Text extraClasses={styles.dragAndDropText}>Uploaded file: </Text>
                                <Tag
                                    extraClasses={styles.fileTag}
                                    Elem="button"
                                    onClick={() => setUploadedFileName('')}
                                >
                                    {uploadedFileName}
                                    <Icon InlineIcon={IconClose} />
                                </Tag>
                            </>
                        </GridCol>
                        <GridCol size={4}>
                            <Text small extraClasses={styles.successText}>
                                {uploadSuccessInfo}{' '}
                            </Text>
                        </GridCol>
                    </Grid>
                )}
            </Box>
            {!validFile && (
                <Grid align="bottom">
                    <GridCol key="error-text" size={11} extraClasses={styles.errorRow}>
                        <Text small extraClasses={styles.errorText}>
                            {infoError}
                        </Text>
                    </GridCol>
                </Grid>
            )}
        </div>
    );
};

export default CSVUpload;
