import { milesToKM } from '../../utils/distance.utils';
import { Region } from '../../constants';
import { reportPreferredCityPairsSelectionMethod } from '../../utils/datadog/datadog.utils';
import styles from './CitiesFilter.module.scss';
import { useFilterData } from '../../data/filter-data/useFilterData';
import { useRegionState } from '../../data/region-state/useRegionState';

import { Button, Checkbox, Divider, Grid, GridCol, Input } from '@flixbus/honeycomb-react';
import CityCountryAutocompleteInput, { CityCountryOption } from './CityCountryAutocompleteInput';
import { CityPair, CityPairString } from '../../types';
import { cityPairArrayToString, symmetricalCityPair } from '../../utils/cityPairs.utils';
import { Icon, IconArrowDown, IconArrowUp, IconPlus } from '@flixbus/honeycomb-icons-react';
import React, { useEffect, useMemo, useState } from 'react';

type Props = {
    addCityPairs: (cityPairs: CityPairString[]) => void;
};

type DistanceFilter = { min?: number; max?: number };

const CitiesFilter: React.FC<Props> = ({ addCityPairs }) => {
    const { filterData, getCityPairNames } = useFilterData();

    // Intialize relation state data
    const [selectedFrom, setSelectedFrom] = useState<CityCountryOption>();
    const [selectedTo, setSelectedTo] = useState<CityCountryOption>();
    const [fromCityPlaceholder, setFromCityPlaceholder] = useState<string>('');
    const [toCityPlaceholder, setToCityPlaceholder] = useState<string>('');
    const [includeReturn, setIncludeReturn] = useState<boolean>(true);
    const [disableAdd, setDisableAdd] = useState<boolean>(true);
    const [distanceFilter, setDistanceFilter] = useState<DistanceFilter>({ min: undefined, max: undefined });
    const [showAdvancedFilters, setShowAdvancedFilters] = useState<boolean>(false);

    // Set filter usability hints
    useEffect(() => (selectedTo ? setFromCityPlaceholder('Everywhere') : setFromCityPlaceholder('')), [selectedTo]);
    useEffect(() => (selectedFrom ? setToCityPlaceholder('Everywhere') : setToCityPlaceholder('')), [selectedFrom]);
    useEffect(
        () => (selectedTo || selectedFrom ? setDisableAdd(false) : setDisableAdd(true)),
        [selectedFrom, selectedTo],
    );

    type RelationDirection = 'outbound' | 'inbound';

    function satisfiesDistanceFilter(cp: CityPair): boolean {
        if (distanceFilter.min !== undefined && cp.distance < distanceFilter.min) {
            return false;
        }
        if (distanceFilter.max !== undefined && cp.distance > distanceFilter.max) {
            return false;
        }
        return true;
    }

    function findAllConnections(direction: RelationDirection, cityUuid?: string, countryCode?: string): string[] {
        const uniqueCities: Set<string> = new Set();
        filterData.cityPairs.forEach((cityPair) => {
            if (direction === 'outbound') {
                if (countryCode) {
                    const actualCountryCode = filterData.cities.get(cityPair.fromUuid)?.countryCode;
                    if (actualCountryCode === countryCode) {
                        if (satisfiesDistanceFilter(cityPair)) {
                            uniqueCities.add(cityPair.toUuid);
                        }
                    }
                } else if (!cityUuid || cityUuid === cityPair.fromUuid) {
                    if (satisfiesDistanceFilter(cityPair)) {
                        uniqueCities.add(cityPair.toUuid);
                    }
                }
            } else {
                if (countryCode) {
                    const actualCountryCode = filterData.cities.get(cityPair.toUuid)?.countryCode;
                    if (actualCountryCode === countryCode) {
                        if (satisfiesDistanceFilter(cityPair)) {
                            uniqueCities.add(cityPair.fromUuid);
                        }
                    }
                } else if (!cityUuid || cityUuid === cityPair.toUuid) {
                    if (satisfiesDistanceFilter(cityPair)) {
                        uniqueCities.add(cityPair.fromUuid);
                    }
                }
            }
        });
        return Array.from(uniqueCities);
    }

    function deriveOptionsFromCityPairsAndCountries(direction: RelationDirection, selected?: CityCountryOption) {
        let uniqueCities: string[] = [];
        if (selected?.isCountry) {
            uniqueCities = findAllConnections(direction, undefined, selected?.uuid);
        } else {
            uniqueCities = findAllConnections(direction, selected?.uuid);
        }
        const cityOptions = uniqueCities.map((val) => {
            return {
                title: filterData.cities.get(val)!.name,
                isCountry: false,
                uuid: val,
            };
        });
        const countriesWithCities = uniqueCities
            .map((cityUuid) => filterData.cities.get(cityUuid)?.countryCode)
            .filter((cc) => !!cc) as string[];
        const uniqueCountries = new Set(countriesWithCities);
        const countryOptions = Array.from(uniqueCountries)
            .map((countryCode) => filterData.countries.get(countryCode))
            .filter((country) => !!country)
            .map((country) => {
                return {
                    title: country!.countryName,
                    uuid: country!.countryCode,
                    isCountry: true,
                };
            });
        const countriesAndCities = [...countryOptions, ...cityOptions].sort((a, b) => {
            return a.title.localeCompare(b.title);
        });
        return countriesAndCities;
    }

    const fromCitiesData: CityCountryOption[] = useMemo(() => {
        return deriveOptionsFromCityPairsAndCountries('inbound', selectedTo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData, selectedTo, distanceFilter]);

    const toCitiesData: CityCountryOption[] = useMemo(() => {
        return deriveOptionsFromCityPairsAndCountries('outbound', selectedFrom);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData, selectedFrom, distanceFilter]);

    const onAddClick = () => {
        reportPreferredCityPairsSelectionMethod('CitiesFilter');

        function findCityPairsFromCountryToCountry(fromCountryCode: string, toCountryCode: string): CityPairString[] {
            const cityPairs: CityPairString[] = [];
            const fromCities = filterData.countries.get(fromCountryCode)?.cities.map((c) => c.uuid);
            fromCities?.forEach((fromCity) => {
                const possibleDestinations = findAllConnections('outbound', fromCity);
                possibleDestinations.forEach((destinationUuid) => {
                    const destinationCountry = filterData.cities.get(destinationUuid)?.countryCode;
                    if (destinationCountry === toCountryCode) {
                        cityPairs.push(cityPairArrayToString([fromCity, destinationUuid]));
                    }
                });
            });
            return cityPairs;
        }

        function findCityPairsFromCountryToCity(fromCountryCode: string, toUuid: string): CityPairString[] {
            return findCityPairsByCountryAndCity(fromCountryCode, toUuid, 'inbound');
        }

        function findCityPairsFromCityToCountry(fromUuid: string, toCountryCode: string): CityPairString[] {
            return findCityPairsByCountryAndCity(toCountryCode, fromUuid, 'outbound');
        }

        function findCityPairsByCountryAndCity(
            countryCode: string,
            cityUuid: string,
            direction: RelationDirection,
        ): CityPairString[] {
            const cityPairs: CityPairString[] = [];
            const possibleCities = findAllConnections(direction, cityUuid);
            possibleCities.forEach((foundCityUuid) => {
                const connectedCountry = filterData.cities.get(foundCityUuid)?.countryCode;
                if (connectedCountry === countryCode) {
                    if (direction === 'inbound') {
                        cityPairs.push(cityPairArrayToString([foundCityUuid, cityUuid]));
                    } else {
                        cityPairs.push(cityPairArrayToString([cityUuid, foundCityUuid]));
                    }
                }
            });
            return cityPairs;
        }

        function findCityPairsFromCountryToAnywhere(fromCountryCode: string): CityPairString[] {
            return findCityPairsByAnywhereAndCountry(fromCountryCode, 'outbound');
        }

        function findCityPairsFromAnywhereToCountry(toCountryCode: string): CityPairString[] {
            return findCityPairsByAnywhereAndCountry(toCountryCode, 'inbound');
        }

        function findCityPairsByAnywhereAndCountry(
            countryCode: string,
            direction: RelationDirection,
        ): CityPairString[] {
            const cityPairs: CityPairString[] = [];
            const citiesOfCountry = filterData.countries.get(countryCode)?.cities.map((c) => c.uuid);
            citiesOfCountry?.forEach((cityUuid) => {
                const connectionsForCity = findAllConnections(direction, cityUuid);
                connectionsForCity.forEach((connectedCityUuid) => {
                    if (direction === 'inbound') {
                        cityPairs.push(cityPairArrayToString([connectedCityUuid, cityUuid]));
                    } else {
                        cityPairs.push(cityPairArrayToString([cityUuid, connectedCityUuid]));
                    }
                });
            });
            return cityPairs;
        }

        let newCities: CityPairString[] = [];
        if (selectedFrom && selectedTo) {
            if (selectedFrom.isCountry) {
                if (selectedTo.isCountry) {
                    newCities = findCityPairsFromCountryToCountry(selectedFrom.uuid, selectedTo.uuid);
                } else {
                    newCities = findCityPairsFromCountryToCity(selectedFrom.uuid, selectedTo.uuid);
                }
            } else if (selectedTo.isCountry) {
                newCities = findCityPairsFromCityToCountry(selectedFrom.uuid, selectedTo.uuid);
            } else {
                newCities = [cityPairArrayToString([selectedFrom.uuid, selectedTo.uuid])];
            }
        } else if (selectedFrom && !selectedTo) {
            if (selectedFrom.isCountry) {
                newCities = findCityPairsFromCountryToAnywhere(selectedFrom.uuid);
            } else {
                newCities = toCitiesData
                    .filter((el) => !el.isCountry)
                    .map((elem) => cityPairArrayToString([selectedFrom.uuid, elem.uuid]));
            }
        } else if (!selectedFrom && selectedTo) {
            if (selectedTo.isCountry) {
                newCities = findCityPairsFromAnywhereToCountry(selectedTo.uuid);
            } else {
                newCities = fromCitiesData
                    .filter((el) => !el.isCountry)
                    .map((elem) => cityPairArrayToString([elem.uuid, selectedTo.uuid]));
            }
        }

        if (includeReturn) {
            newCities.forEach((newCity) => {
                const returnCityPair = symmetricalCityPair(newCity);
                if (getCityPairNames(returnCityPair)) {
                    newCities.push(returnCityPair);
                }
            });
        }

        addCityPairs(newCities);
    };

    return (
        <Grid align="bottom">
            <GridCol size={12}>
                <Grid align="bottom">
                    <GridCol key="from-cities-input">
                        <CityCountryAutocompleteInput
                            label="From City/Country"
                            value={selectedFrom}
                            placeholder={fromCityPlaceholder}
                            autocompleteData={fromCitiesData}
                            onValueChange={setSelectedFrom}
                        />
                    </GridCol>
                    <GridCol key="to-cities-input">
                        <CityCountryAutocompleteInput
                            label="To City/Country"
                            value={selectedTo}
                            placeholder={toCityPlaceholder}
                            autocompleteData={toCitiesData}
                            onValueChange={setSelectedTo}
                        />
                    </GridCol>
                    <GridCol key="add-button" size={1}>
                        <Button
                            aria-label="add-button"
                            appearance="tertiary"
                            disabled={disableAdd}
                            display="square"
                            onClick={onAddClick}
                        >
                            <Icon InlineIcon={IconPlus} />
                        </Button>
                    </GridCol>
                </Grid>
            </GridCol>
            <GridCol key="advanced-filters" size={12}>
                <Button
                    extraClasses={styles.filtersButton}
                    link
                    onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                >
                    Advanced Filters <Icon InlineIcon={showAdvancedFilters ? IconArrowUp : IconArrowDown} />
                </Button>
                {showAdvancedFilters && (
                    <Grid extraClasses={styles.advancedFilterAccordion}>
                        <GridCol size={12}>
                            <Divider />
                            <Checkbox
                                extraClasses={styles.checkbox}
                                label="Include Return"
                                id="include-return-checkbox"
                                value="includeReturn"
                                defaultChecked={includeReturn}
                                onChange={(e) => setIncludeReturn(e.target.checked)}
                            />
                        </GridCol>
                        <GridCol size={12}>
                            <DistanceInput
                                disabled={
                                    !!selectedTo && !!selectedFrom && !selectedTo.isCountry && !selectedFrom.isCountry
                                }
                                distanceFilter={distanceFilter}
                                setDistanceFilter={setDistanceFilter}
                            />
                        </GridCol>
                    </Grid>
                )}
            </GridCol>
        </Grid>
    );
};

export default CitiesFilter;

type DistanceInputProps = {
    disabled: boolean;
    distanceFilter: DistanceFilter;
    setDistanceFilter: (value: React.SetStateAction<DistanceFilter>) => void;
};

function DistanceInput(props: DistanceInputProps) {
    const { region } = useRegionState();
    const unitLabel = region === Region.USA ? 'Miles' : 'KM';

    function parseAndUpdate(e: React.ChangeEvent<HTMLInputElement>, minOrMax: 'min' | 'max') {
        const localizedValue = parseInt(e.target.value, 10);
        const kmDistance = region === Region.USA ? milesToKM(localizedValue) : localizedValue;
        props.setDistanceFilter({ ...props.distanceFilter, [minOrMax]: kmDistance });
    }

    return (
        <Grid>
            <GridCol size={6}>
                <Input
                    id="min-distance"
                    label="Minimum distance"
                    type="number"
                    inlineLabelRight={unitLabel}
                    defaultValue="0"
                    step="50"
                    onChange={(e) => parseAndUpdate(e, 'min')}
                    max={props.distanceFilter?.max || 10000}
                    disabled={props.disabled}
                />
            </GridCol>
            <GridCol size={6}>
                <Input
                    id="max-distance"
                    label="Maximum distance"
                    type="number"
                    inlineLabelRight={unitLabel}
                    step="50"
                    onChange={(e) => parseAndUpdate(e, 'max')}
                    min={props.distanceFilter?.min || 1}
                    disabled={props.disabled}
                />
            </GridCol>
        </Grid>
    );
}
