import React from 'react';

import { useMsal } from '@azure/msal-react';

import { Box, Button, Heading, MainWrapper, PageContainer, Text } from '@flixbus/honeycomb-react';

import { apiAccessRequest } from '../AuthProvider';

import styles from './Login.module.scss';

const LoginComp: React.FC = () => {
    const { instance } = useMsal();
    const handleLogin = () => {
        instance.loginRedirect(apiAccessRequest);
    };

    return (
        <MainWrapper full extraClasses={styles.container}>
            <PageContainer>
                <Box highlighted>
                    <Heading size={1}>Welcome to TurboTouch</Heading>
                    <Text>
                        Dear visitor,
                        <br />
                        You need to login in order to continue. Please click in the button below to login to the
                        application.
                    </Text>
                    <div className={styles.buttonContainer}>
                        <Button appearance="secondary" onClick={handleLogin}>
                            Login to TurboTouch
                        </Button>
                    </div>
                </Box>
            </PageContainer>
        </MainWrapper>
    );
};

export const Login = React.memo(LoginComp);
