import { AutocompleteOptionType } from '@flixbus/honeycomb-react';
import useFetchFilterData from './useFetchFilterData';

import { CityPairArray, CityPairString, FilterData } from '../../types';
import { cityPairArrayToString, cityPairStringToArray } from '../../utils/cityPairs.utils';
import React, { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

function formatCitiesDistanceData(filterData: FilterData): Record<string, number> {
    const result: Record<CityPairString, number> = {};
    filterData.cityPairs.forEach((cityPair) => {
        result[cityPairArrayToString([cityPair.fromUuid, cityPair.toUuid])] = cityPair.distance;
    });
    return result;
}

type FilterDataContext = {
    loading: boolean;
    filterData: FilterData;
    tagsData: AutocompleteOptionType[];
    // Update data
    addNewTag: (newTag: string) => void;
    // Function to get city pairs UUIDs, distances, etc.
    getCityPairUUIDs: (cityPairs: CityPairString[]) => CityPairArray[];
    getCityPairNames: (cityPairs: CityPairString) => CityPairString;
    getCityPairDistance: (cityPair: CityPairString) => number;
};

export const FilterDataContext = createContext<FilterDataContext | undefined>(undefined);

export function FilterDataProvider({ children }: { children?: ReactNode }) {
    const { data, loading } = useFetchFilterData();

    // Setup tag related filter state
    const [tagsData, setTagsData] = useState<AutocompleteOptionType[]>([]);
    useEffect(() => {
        setTagsData(data.tags.sort((a, b) => a.localeCompare(b)).map((tag) => ({ title: tag })));
    }, [data]);
    const addNewTag = useCallback(
        (newTag: string) => {
            const currentTags: string[] = tagsData.map((elem) => elem.title);
            if (!currentTags.includes(newTag)) {
                setTagsData([...tagsData, { title: newTag }]);
            }
        },
        [tagsData],
    );

    // Setup cities related filter state
    const [mapUUIDstoName, mapNamesToUUID] = useMemo(() => {
        const mapUUIDstoName = new Map();
        data.cityPairs.forEach((cityPair) => {
            const fromName = data.cities.get(cityPair.fromUuid)?.name as string;
            const toName = data.cities.get(cityPair.toUuid)?.name as string;
            mapUUIDstoName.set(
                cityPairArrayToString([cityPair.fromUuid, cityPair.toUuid]),
                cityPairArrayToString([fromName, toName]),
            );
        });
        const mapNamesToUUID = new Map(Array.from(mapUUIDstoName, (entry) => [entry[1], entry[0]]));
        return [mapUUIDstoName, mapNamesToUUID];
    }, [data]);

    const getCityPairDistance = useMemo(() => {
        const cityPairsDistance = formatCitiesDistanceData(data);
        const getCityPairDistance = (cityPair: CityPairString) => cityPairsDistance[cityPair];
        return getCityPairDistance;
    }, [data]);

    return (
        <FilterDataContext.Provider
            value={{
                filterData: data,
                tagsData: tagsData,
                addNewTag: addNewTag,
                getCityPairUUIDs: (cityPairs) => {
                    const result: CityPairArray[] = [];
                    cityPairs.forEach((cityPair) => {
                        const uuids = mapNamesToUUID.get(cityPair);
                        if (uuids) {
                            result.push(cityPairStringToArray(uuids));
                        }
                    });
                    return result;
                },
                getCityPairNames: (cityUUIDs) => mapUUIDstoName.get(cityUUIDs),
                getCityPairDistance: getCityPairDistance,
                loading: loading,
            }}
        >
            {children}
        </FilterDataContext.Provider>
    );
}
