import React, { createContext, ReactNode, useMemo } from 'react';

import Axios, { AxiosInstance } from 'axios';

import { acquireAccessToken } from '../../features/auth';
import { addDataDogError } from '../datadog/datadog.utils';
import { TURBOTOUCH_API } from '../../constants';
import { useToast } from '../../features/toasts-container';

const baseAxios = Axios.create({
    // For development builds we can use the http proxy defined in package.json
    baseURL: process.env.NODE_ENV === 'production' ? TURBOTOUCH_API : undefined,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const AxiosContext = createContext<AxiosInstance>(baseAxios);

export function AxiosProvider({ children }: { children?: ReactNode }) {
    const { addToast } = useToast();

    const axios = useMemo(() => {
        baseAxios.interceptors.request.use(async (config) => {
            const authToken = await acquireAccessToken();
            // Read token for anywhere, in this case directly from localStorage
            if (authToken) {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${authToken}`,
                };
            }

            return config;
        });

        baseAxios.interceptors.response.use(
            (response) => response,
            function (error) {
                addDataDogError(new Error(error));
                if (401 === error.response.status) {
                    addToast({
                        type: 'danger',
                        content: 'You are not authorised or your token expired',
                    });
                    window.location.pathname = '/login';
                } else {
                    addToast({
                        type: 'danger',
                        content: error.response,
                    });
                    return Promise.reject(error);
                }
            },
        );

        return baseAxios;
    }, [addToast]);

    return <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>;
}
