import React, { useEffect, useState } from 'react';

import { Box, Grid, GridCol, List, Range, Text } from '@flixbus/honeycomb-react';

import { DaysBeforeDeparture } from '../../../../../types';
import HeadingWithTooltip from '../../../../../features/heading-with-tooltip/HeadingWithTooltip';
import { STANDARD_PBP_BOUNDARIES } from '../../../../../constants';

import styles from './PBPBox.module.scss';

const SLIDER_PBP_BOUNDARIES = STANDARD_PBP_BOUNDARIES.reverse();

const TOOLTIP = (
    <>
        <List>
            <li key="pbp-tooltip-list-1">
                <Text small extraClasses={styles.tooltipText}>
                    Pre-Booking Period (PBP) refers to a time period before the departure date-time of a given city
                    pair.
                </Text>
            </li>
            <li key="pbp-tooltip-list-2">
                <Text small extraClasses={styles.tooltipText}>
                    The PBP is defined by a range of days, being the right boundary of the range included and the left
                    one not.
                </Text>
            </li>
            <li key="pbp-tooltip-list-3">
                <Text small extraClasses={styles.tooltipText}>
                    For example, PBP (1, 0] refers to the 24 hours before the departure of a given city pair.
                </Text>
            </li>
        </List>
    </>
);

type Props = {
    value: DaysBeforeDeparture;
    onValueChange: (value: DaysBeforeDeparture) => void;
    height: number;
    error: boolean;
    infoError: string;
};

const PBPBoxComp: React.FC<Props> = ({ value, onValueChange, height, error, infoError }) => {
    // Initialize and set state with period length information
    const [periodLength, setPeriodLength] = useState<number>(0);
    useEffect(() => {
        setPeriodLength(value[0] - value[1]);
    }, [value]);

    return (
        <Box extraClasses={styles.box}>
            <div style={{ height: height }}>
                <Grid align="center">
                    <GridCol key="pbp-box-heading" size={12}>
                        <HeadingWithTooltip title="Pre-Booking Period" tooltip={TOOLTIP} tooltipSize="large" />
                    </GridCol>
                    <GridCol key="pbp-range-input" size={10}>
                        <Range
                            id="Range2"
                            aria-label="Pre-Booking Period"
                            format={(val) => `Day ${SLIDER_PBP_BOUNDARIES[val]}`}
                            defaultValue={SLIDER_PBP_BOUNDARIES.findIndex((item) => item === value[0])}
                            displayValues
                            min={0}
                            max={SLIDER_PBP_BOUNDARIES.length - 1}
                            step={1}
                            showSteps
                            small
                            onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                                const target = e.target as HTMLInputElement;
                                onValueChange([SLIDER_PBP_BOUNDARIES[parseInt(target.value)], value[1]]);
                            }}
                            rangeEnd={{
                                'aria-label': 'Last available time',
                                defaultValue: SLIDER_PBP_BOUNDARIES.findIndex((item) => item === value[1]),
                                onClick: (e: React.MouseEvent<HTMLInputElement>) => {
                                    const target = e.target as HTMLInputElement;
                                    onValueChange([value[0], SLIDER_PBP_BOUNDARIES[parseInt(target.value)]]);
                                },
                            }}
                        />
                    </GridCol>
                    <GridCol key="pbp-info" size={2}>
                        <Text extraClasses={styles.infoText}>
                            ({periodLength.toFixed(0)} {periodLength === 1 ? 'day' : 'days'})
                        </Text>
                    </GridCol>
                    {error && (
                        <GridCol extraClasses={styles.errorRow}>
                            <Text small extraClasses={styles.errorText}>
                                {infoError}
                            </Text>
                        </GridCol>
                    )}
                </Grid>
            </div>
        </Box>
    );
};

export const PBPBox = React.memo(PBPBoxComp);
