import CitiesFilter from '../../../../../features/cities-filter/CitiesFilter';
import CityPairsTable from '../../../../../features/city-pairs-table/CityPairsTable';
import { CityPairString } from '../../../../../types';
import CSVUpload from '../../../../../features/csv-upload/CSVUpload';
import HeadingWithTooltip from '../../../../../features/heading-with-tooltip/HeadingWithTooltip';
import styles from './CityPairsBox.module.scss';

import { Divider, Fieldset, Grid, GridCol, Radio, Text } from '@flixbus/honeycomb-react';
import React, { useState } from 'react';

// Tooltip with information about the CityPairs Box
const TOOLTIP = (
    <>
        <Text small>
            Only interconnection city pairs with an elasticity forecast (with sufficient historical data) can be
            selected.
        </Text>
    </>
);

type Props = {
    cityPairs: CityPairString[];
    addCityPairs: (cityPairs: CityPairString[]) => void;
    removeCityPairs: (cityPairs: CityPairString[]) => void;
    uploadedFileName: string;
    setUploadedFileName: (uploadedFileName: string) => void;
    showCities: boolean;
    valid: boolean;
    infoError: string;
};

const CityPairsBoxComp: React.FC<Props> = ({
    cityPairs,
    addCityPairs,
    removeCityPairs,
    uploadedFileName,
    setUploadedFileName,
    showCities,
    valid,
    infoError,
}) => {
    // Initialize internal state
    const [selectMode, setSelectMode] = useState<string>('cities-filter');

    return (
        <div className={styles.container}>
            <Grid align="top">
                <GridCol key="city-pairs-box-header" size={12}>
                    <HeadingWithTooltip title="City Pairs" tooltip={TOOLTIP} tooltipSize="medium" />
                </GridCol>
                <GridCol key="cities-input-radio-selector" size={12}>
                    <Fieldset horizontal extraClasses={styles.fieldset}>
                        <Radio
                            small
                            checked={selectMode === 'cities-filter'}
                            label="Cities Filter"
                            id="cities-filter-radio"
                            name="cities-filter"
                            value="Cities Filter"
                            key="cities-filter-radio"
                            onChange={(e) => setSelectMode(e.target.name)}
                        />
                        <Radio
                            small
                            checked={selectMode === 'csv-upload'}
                            label="CSV Upload"
                            id="csv-upload-radio"
                            name="csv-upload"
                            value="CSV Upload"
                            key="csv-upload-radio"
                            onChange={(e) => setSelectMode(e.target.name)}
                        />
                    </Fieldset>
                </GridCol>
                <GridCol key="cities-input-component" size={12} extraClasses={styles.lastRow}>
                    {
                        {
                            'cities-filter': <CitiesFilter addCityPairs={addCityPairs} />,
                            'csv-upload': (
                                <CSVUpload
                                    addCityPairs={addCityPairs}
                                    uploadedFileName={uploadedFileName}
                                    setUploadedFileName={setUploadedFileName}
                                />
                            ),
                        }[selectMode]
                    }
                </GridCol>
                <GridCol key="city-pairs-info" extraClasses={styles.infoRow}>
                    Added {cityPairs.length} city pairs
                </GridCol>
            </Grid>
            {!showCities ? (
                <></>
            ) : (
                <>
                    <GridCol key="city-pairs-box-divider" extraClasses={styles.row}>
                        <Divider extraClasses={styles.mainDivider} />
                    </GridCol>
                    <GridCol key="city-pairs-box-table" extraClasses={styles.middleRow}>
                        <CityPairsTable
                            removableRows
                            cityPairsData={cityPairs}
                            onRemoveRow={removeCityPairs}
                            pairsAsIds={true}
                        />
                    </GridCol>
                </>
            )}
            {!valid && (
                <div className={styles.errorRow}>
                    <Text small extraClasses={styles.errorText}>
                        {infoError}
                    </Text>
                </div>
            )}
        </div>
    );
};

export const CityPairsBox = React.memo(CityPairsBoxComp);
