import { CountryFlag } from '../../utils/flag-icon.utils';
import { Dropdown } from '@flixbus/honeycomb-react';
import { REGION_MAP } from '../../constants';
import { useRegionState } from '../../data/region-state/useRegionState';

import { Icon, IconArrowDown } from '@flixbus/honeycomb-icons-react';
import React, { useMemo } from 'react';

const RegionSwitcherComp = () => {
    const { region, setRegion } = useRegionState();
    const currRegion = REGION_MAP[region];

    const currencies = useMemo(() => {
        return Object.values(REGION_MAP).map((currency) => ({
            text: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CountryFlag countryCode={currency.countryCode} style={{ marginRight: 12, height: 24 }} />{' '}
                    {currency.name}
                </div>
            ),
            key: currency.isoCode,
            activeItem: currency.isoCode === currRegion.isoCode,
            onClick: () => setRegion(currency.isoCode),
        }));
    }, [currRegion.isoCode, setRegion]);

    return (
        <Dropdown links={currencies} openOnHover>
            <div style={{ marginRight: 12, fontWeight: 500 }}>
                {currRegion.isoCode}
                <Icon InlineIcon={IconArrowDown} />
            </div>
        </Dropdown>
    );
};

export const RegionSwitcher = React.memo(RegionSwitcherComp);
