import React from 'react';

import { Grid, GridCol, Heading, Text } from '@flixbus/honeycomb-react';

import DaysOfTheWeekInput from '../../../../../features/days-of-the-week-input/DaysOfTheWeekInput';
import MultipleDateRangeInput from '../../../../../features/date-range-input/multiple-date-range-input/MultipleDateRangeInput';
import { DateRange, DaysOfTheWeek } from '../../../../../types';

import styles from './DepartureDatesBox.module.scss';

type Props = {
    includedDates: DateRange[];
    onIncludedDatesChange: (includedDates: DateRange[]) => void;
    excludedDates: DateRange[];
    onExcludedDatesChange: (excludedDates: DateRange[]) => void;
    daysOfTheWeek: DaysOfTheWeek;
    onDaysOfTheWeekChange: (daysOfTheWeek: DaysOfTheWeek) => void;
    valid: boolean;
    infoError: string;
    validIncludedDates: boolean;
    infoIncludedDatesError: string;
    validExcludedDates: boolean;
    infoExcludedDatesError: string;
    validDaysOfTheWeek: boolean;
    infoDaysOfTheWeekError: string;
};

const DeparturesBoxComp: React.FC<Props> = ({
    includedDates,
    onIncludedDatesChange,
    excludedDates,
    onExcludedDatesChange,
    daysOfTheWeek,
    onDaysOfTheWeekChange,
    valid,
    infoError,
    validIncludedDates,
    infoIncludedDatesError,
    validExcludedDates,
    infoExcludedDatesError,
    validDaysOfTheWeek,
    infoDaysOfTheWeekError,
}) => {
    return (
        <div className={styles.container}>
            <Grid align="top">
                <GridCol key="departure-dates-box-header" size={12}>
                    <Heading sectionHeader size={4} extraClasses={styles.heading}>
                        Departure Dates
                    </Heading>
                </GridCol>
                <GridCol key="departure-dates-box-included-input" size={6} extraClasses={styles.row}>
                    <MultipleDateRangeInput
                        label="Included Departures"
                        dateRanges={includedDates}
                        valid={validIncludedDates ? undefined : false}
                        infoError={infoIncludedDatesError}
                        onDateRangesChange={onIncludedDatesChange}
                    />
                </GridCol>
                <GridCol key="departure-dates-box-excluded-input" size={6} extraClasses={styles.row}>
                    <MultipleDateRangeInput
                        label="Excluded Departures"
                        dateRanges={excludedDates}
                        valid={validExcludedDates ? undefined : false}
                        infoError={infoExcludedDatesError}
                        onDateRangesChange={onExcludedDatesChange}
                    />
                </GridCol>
                <GridCol key="departure-dates-box-dow-input" size={12} extraClasses={styles.lastRow}>
                    <DaysOfTheWeekInput
                        label="Weekdays"
                        daysOfTheWeek={daysOfTheWeek}
                        valid={validDaysOfTheWeek ? undefined : false}
                        infoError={infoDaysOfTheWeekError}
                        onClick={(e: DaysOfTheWeek) => onDaysOfTheWeekChange([...e])}
                    />
                </GridCol>
            </Grid>
            {!valid && (
                <div className={styles.errorRow}>
                    <Text small extraClasses={styles.errorText}>
                        {infoError}
                    </Text>
                </div>
            )}
        </div>
    );
};

export const DeparturesBox = React.memo(DeparturesBoxComp);
