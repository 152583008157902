import React from 'react';

import { Infobox, MainWrapper, PageContainer, ThemeWrapper } from '@flixbus/honeycomb-react';

import { ErrorBoundary } from 'react-error-boundary';

import { addDataDogError } from '../datadog.utils';

import styles from './ErrorBoundary.module.scss';

type Props = {
    children: React.ReactNode;
};

const MemoErrorBoundary: React.FC<Props> = ({ children }) => (
    <ErrorBoundary
        fallbackRender={({ error }) => (
            <ThemeWrapper>
                <MainWrapper>
                    <PageContainer>
                        <Infobox extraClasses={styles.infobox} aria-label="error-found" appearance="danger">
                            <p>Fatal error happened: {error.message}. Please, reload the page and try again.</p>
                        </Infobox>
                    </PageContainer>
                </MainWrapper>
            </ThemeWrapper>
        )}
        onError={addDataDogError}
    >
        {children}
    </ErrorBoundary>
);

export const DatadogErrorBoundary = React.memo(MemoErrorBoundary);
