import _ from 'lodash';
import { addDataDogError } from '../../utils/datadog/datadog.utils';
import { ENDPOINT_URLS } from '../../constants';
import { parseRulesData } from '../rules-data/ruleData.context';
import { useAxios } from '../../utils/transport/useAxios';
import { useToast } from '../../features/toasts-container';

import { CityPairArray, DateRange, DaysBeforeDeparture, DaysOfTheWeek, RuleData } from '../../types';
import { dateRangesToISORanges, departuresLength } from '../../utils/date.utils';
import { useEffect, useState } from 'react';

const CITY_PAIRS_PER_CALL = 2500;

export function useFetchOverlappingRules(
    selectedCityPair: CityPairArray[],
    includedDates: DateRange[],
    excludedDates: DateRange[],
    daysOfTheWeek: DaysOfTheWeek,
    daysBeforeDeparture: DaysBeforeDeparture,
    excludeRuleId: number | undefined = undefined,
): {
    data: RuleData[];
    loading: boolean;
} {
    const apiClient = useAxios();
    const { addToast } = useToast();

    const [loading, setLoading] = useState<boolean>(false);
    const [totalCalls, setTotalCalls] = useState<number | null>(null);
    const [callCounter, setCallCounter] = useState<number | null>(null);
    const [rules, setRules] = useState<RuleData[]>([]);

    const fetchOverlappingRules = async () => {
        if (totalCalls != null && callCounter !== null) {
            if (selectedCityPair.length >= 1 && includedDates) {
                try {
                    const request: Record<string, any> = {
                        city_pairs: selectedCityPair.slice(
                            callCounter * CITY_PAIRS_PER_CALL,
                            (callCounter + 1) * CITY_PAIRS_PER_CALL,
                        ),
                        included_dates: dateRangesToISORanges(includedDates),
                        excluded_dates: dateRangesToISORanges(excludedDates),
                        days_of_the_week: daysOfTheWeek,
                        days_before_departure:
                            daysBeforeDeparture[0] === 365 && daysBeforeDeparture[1] === 0 ? [] : daysBeforeDeparture,
                    };
                    if (excludeRuleId) {
                        request['exclude_rule_id'] = excludeRuleId;
                    }
                    const res = await apiClient.post(ENDPOINT_URLS.GET_OVERLAPPING_RULES, request);

                    if (res.status < 200 && res.status >= 30) {
                        throw new Error(`Error with status ${res.status}`);
                    }

                    const parsedData: RuleData[] = parseRulesData(res.data);
                    const existingIds = rules.map((rule) => rule.id);
                    const allRules = rules;
                    parsedData.forEach((rule) => {
                        if (!existingIds.includes(rule.id)) {
                            allRules.push(rule);
                        }
                    });
                    setRules([...allRules]);
                } catch (error) {
                    addDataDogError(error as Error);
                    addToast({
                        type: 'danger',
                        content: `Overlapping rules API call failed: ${(error as Error).message}.`,
                    });
                } finally {
                    setCallCounter(callCounter + 1);
                }
            } else {
                setLoading(false);
                setRules([]);
                setTotalCalls(null);
                setCallCounter(null);
            }
        }
    };

    useEffect(() => {
        if (selectedCityPair.length >= 1 && departuresLength(includedDates, excludedDates, daysOfTheWeek) >= 1) {
            setRules([]);
            setLoading(true);
            setTotalCalls(Math.ceil(selectedCityPair.length / CITY_PAIRS_PER_CALL));
            setCallCounter(0);
        } else {
            setRules([]);
            setLoading(false);
            setTotalCalls(null);
            setCallCounter(null);
        }
    }, [selectedCityPair, includedDates, excludedDates, daysOfTheWeek, daysBeforeDeparture]);

    useEffect(() => {
        if (callCounter !== null && totalCalls !== null) {
            if (callCounter !== totalCalls) {
                fetchOverlappingRules();
            } else {
                // Last API call is finalized
                setLoading(false);
                setTotalCalls(null);
                setCallCounter(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callCounter, totalCalls]);

    return {
        data: rules,
        loading: loading,
    };
}
