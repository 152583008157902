import ChartPerDeparture from '../../../../../features/charts/chart-per-departure/ChartPerDeparture';
import ChartPerPBP from '../../../../../features/charts/chart-per-pbp/ChartPerPBP';
import ChartPerWeekday from '../../../../../features/charts/chart-per-weekday/ChartPerWeekday';
import { compareDates } from '../../../../../utils/date.utils';
import { kmToCurrRegionDistance } from '../../../../../utils/distance.utils';
import styles from './ImpactAnalysis.module.scss';
import { useDebounce } from '../../../../../utils/hooks/useDebounce';
import { useFetchVisData } from '../../../../../data/visualization-data/useFetchVisData';
import { useFilterData } from '../../../../../data/filter-data/useFilterData';
import { useFormState } from '../../../../../data/form-state/useFormState';
import { useRegionState } from '../../../../../data/region-state/useRegionState';

import {
    Autocomplete,
    AutocompleteInput,
    AutocompleteOptions,
    AutocompleteOptionType,
    Box,
    Divider,
    Grid,
    GridCol,
    Heading,
    Select,
    SelectOption,
    Spinner,
} from '@flixbus/honeycomb-react';
import {
    cityPairArrayToString,
    cityPairStringToArray,
    prepareCityPairsVisData,
} from '../../../../../utils/cityPairs.utils';
import { CityPairString, DateRange, DaysBeforeDeparture, DisplayKPIs } from '../../../../../types';
import {
    findAlphaDomain,
    findPriceDomain,
    removePastPBPs,
    visDataPriceToYield,
    visDataToChartData,
} from '../../../../../utils/visualization.utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const TIME_AGGREAGTION = [
    { displayName: 'Current departures', value: 0 },
    { displayName: 'Weekdays (current departures)', value: 1 },
    { displayName: 'Pre-Booking Period', value: 2 },
];
const KPIS = [
    { displayName: 'Price', value: 'price' as DisplayKPIs },
    { displayName: 'Yield', value: 'yield' as DisplayKPIs },
];

const ImpactAnalysis = () => {
    // Get form context state
    const {
        cityPairs,
        includedDates,
        excludedDates,
        daysOfTheWeek,
        daysBeforeDeparture,
        alpha,
        refreshCounterVisData,
        ruleId,
    } = useFormState();

    // Get current region state and filter data
    const { region, exchangeRate } = useRegionState();
    const { getCityPairDistance, filterData, getCityPairNames, getCityPairUUIDs } = useFilterData();

    // Prepare filters
    const cityPairVisData = useMemo(
        () => prepareCityPairsVisData(cityPairs, filterData.cities, getCityPairDistance, region),
        [cityPairs, getCityPairDistance, region, filterData.cities],
    );

    const getAlphabeticallyFirstPair = useCallback((): CityPairString => {
        const cityPairNames = cityPairs.map((cp) => getCityPairNames(cp));
        cityPairNames.sort((a, b) => a.localeCompare(b));
        const alphabeticallyFirst = cityPairNames[0];
        const pairAsUuids = getCityPairUUIDs([alphabeticallyFirst])[0];
        return cityPairArrayToString(pairAsUuids);
    }, [cityPairs, getCityPairNames, getCityPairUUIDs]);

    const [selectedCityPair, setSelectedCityPair] = useState<CityPairString>(getAlphabeticallyFirstPair());
    useEffect(() => {
        setSelectedCityPair(getAlphabeticallyFirstPair());
    }, [cityPairs, getAlphabeticallyFirstPair]);

    const [timeAggregation, setTimeAggregation] = useState<number>(TIME_AGGREAGTION[0].value);

    const [selectedKPI, setSelectedKPI] = useState<DisplayKPIs>(KPIS[0].value);

    // Fetch visualization data
    const debouncedAlpha = useDebounce<number>(alpha, alpha, 200);
    const debouncedDaysBeforeDeparture = useDebounce<DaysBeforeDeparture>(
        daysBeforeDeparture,
        daysBeforeDeparture,
        200,
    );
    const selectCityPairUUID = useMemo(() => cityPairStringToArray(selectedCityPair), [selectedCityPair]);
    const { data, loading } = useFetchVisData(
        selectCityPairUUID,
        includedDates,
        excludedDates,
        daysOfTheWeek,
        debouncedDaysBeforeDeparture,
        debouncedAlpha,
        refreshCounterVisData,
        ruleId,
    );

    // Process visualization data
    const cityPairName = cityPairStringToArray(selectedCityPair)
        .map((cityUuid) => filterData.cities.get(cityUuid)?.name)
        .join('::') as CityPairString;
    const distance: number = useMemo(
        function getDistance() {
            const distance = getCityPairDistance(selectedCityPair);
            return kmToCurrRegionDistance(distance, region);
        },
        [getCityPairDistance, region, selectedCityPair],
    );

    const chartData = useMemo(
        function prepareVisualizationData() {
            if (selectedKPI === 'yield') {
                return visDataPriceToYield(removePastPBPs(data), distance);
            } else {
                return removePastPBPs(data);
            }
        },
        [data, distance, selectedKPI],
    );
    const priceDomain: [number, number] = useMemo(
        function preparePriceDomain() {
            return findPriceDomain(chartData, exchangeRate);
        },
        [chartData, exchangeRate],
    );
    const alphaDomain = useMemo(
        function prepareAlphaDoma() {
            return findAlphaDomain(visDataToChartData(chartData, exchangeRate));
        },
        [chartData, exchangeRate],
    );

    // Handle current departures to be visualized
    const selectedDates: Date[] = useMemo(
        function setSelectedDates() {
            return chartData.reduce<Date[]>((r, o) => {
                if (r.length === 0 || r[r.length - 1] < o.date) r.push(o.date);
                return r;
            }, []);
        },
        [chartData],
    );
    const [selectedDateIndex, setSelectedDateIndex] = useState<number>(0);
    const onClickCurrentDeparturesChart = (e: any) => {
        const clickedIndex = selectedDates.findIndex((d) => compareDates(d, e.activeLabel));
        if (clickedIndex !== -1) {
            setSelectedDateIndex(clickedIndex);
            setTimeAggregation(2);
        }
    };
    const onClickPreviousDay = useMemo(() => {
        if (selectedDates) {
            if (selectedDates[selectedDateIndex] !== selectedDates[0]) {
                return () => setSelectedDateIndex(selectedDateIndex - 1);
            }
        }
        return undefined;
    }, [selectedDates, selectedDateIndex]);
    const onClickNextDay = useMemo(() => {
        if (selectedDates) {
            if (selectedDates[selectedDateIndex] !== selectedDates[selectedDates.length - 1]) {
                return () => setSelectedDateIndex(selectedDateIndex + 1);
            }
        }
        return undefined;
    }, [selectedDates, selectedDateIndex]);

    // Handle weekdays chart onClick
    const [selectedWeekday, setSelectedWeekday] = useState<string>('');
    const onClickWeekdaysChart = (e: any) => {
        setSelectedWeekday(e.activeLabel);
        setTimeAggregation(0);
    };
    const onClickWeekdayTag = () => {
        setSelectedWeekday('');
    };

    const displayDates: DateRange | undefined =
        selectedDates.length > 0 ? [selectedDates[0], selectedDates[selectedDates.length - 1]] : undefined;

    return (
        <Box extraClasses={styles.box}>
            <Grid align="bottom">
                <GridCol size={12}>
                    <Heading extraClasses={styles.heading} sectionHeader size={4}>
                        Impact Analysis
                    </Heading>
                </GridCol>
                <GridCol size={7}>
                    <CityPairAutocomplete
                        label="Visualization City Pair"
                        placeholder="Type city pair (name)"
                        autocompleteData={Array.from(cityPairVisData.keys())
                            .map((key) => ({ title: key }))
                            .sort((a, b) => a.title.localeCompare(b.title))}
                        onValueChange={(option) => {
                            if (option?.title) {
                                // Trigger visualization for selected city pair
                                setSelectedCityPair(cityPairVisData.get(option.title) as CityPairString);
                            }
                        }}
                    />
                </GridCol>
                <GridCol size={3}>
                    <Select
                        id="time-aggregation-selector"
                        label="Time aggregation"
                        value={timeAggregation}
                        onChange={(e: any) => setTimeAggregation(e.target.value)}
                    >
                        {TIME_AGGREAGTION.map((item) => (
                            <SelectOption key={`time-aggregation-select-${item.value}`} value={item.value}>
                                {item.displayName}
                            </SelectOption>
                        ))}
                    </Select>
                </GridCol>
                <GridCol>
                    <Select
                        id="kpi-selector"
                        label="Forecast KPI"
                        value={selectedKPI}
                        onChange={(e: any) => setSelectedKPI(e.target.value)}
                    >
                        {KPIS.map((item) => (
                            <SelectOption key={`kpi-select-${item.value}`} value={item.value}>
                                {item.displayName}
                            </SelectOption>
                        ))}
                    </Select>
                </GridCol>
                <GridCol size={12}>
                    <Divider extraClasses={styles.divider} />
                </GridCol>
                {loading ? (
                    <GridCol key="loading-visualization" size={12} extraClasses={styles.spinnerRow}>
                        <Spinner size="md" />
                    </GridCol>
                ) : (
                    <GridCol key="visualization" size={12} extraClasses={styles.chartRow}>
                        {
                            {
                                0: (
                                    <ChartPerDeparture
                                        cityPair={cityPairName}
                                        data={chartData}
                                        displayDates={displayDates}
                                        selectedWeekday={selectedWeekday}
                                        priceDomain={priceDomain}
                                        alphaDomain={alphaDomain}
                                        region={region}
                                        exchangeRate={exchangeRate}
                                        onClickChart={onClickCurrentDeparturesChart}
                                        onClickWeekdayTag={onClickWeekdayTag}
                                        displayKPI={selectedKPI}
                                    />
                                ),
                                1: (
                                    <ChartPerWeekday
                                        cityPair={cityPairName}
                                        data={chartData}
                                        displayDates={displayDates}
                                        priceDomain={priceDomain}
                                        region={region}
                                        exchangeRate={exchangeRate}
                                        onClickWeekdaysChart={onClickWeekdaysChart}
                                        displayKPI={selectedKPI}
                                    />
                                ),
                                2: (
                                    <ChartPerPBP
                                        cityPair={cityPairName}
                                        data={chartData}
                                        selectedDate={selectedDates && selectedDates[selectedDateIndex]}
                                        priceDomain={priceDomain}
                                        alphaDomain={alphaDomain}
                                        region={region}
                                        exchangeRate={exchangeRate}
                                        onClickPreviousDay={onClickPreviousDay}
                                        onClickNextDay={onClickNextDay}
                                        displayKPI={selectedKPI}
                                    />
                                ),
                            }[timeAggregation]
                        }
                    </GridCol>
                )}
            </Grid>
        </Box>
    );
};

type CityPairAutocompleteProps = {
    label: string;
    placeholder?: string;
    autocompleteData: AutocompleteOptionType[];
    onValueChange: (value?: AutocompleteOptionType) => void;
};

const CityPairAutocomplete = ({ label, placeholder, autocompleteData, onValueChange }: CityPairAutocompleteProps) => {
    const [data, setData] = useState<AutocompleteOptionType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const initialValue = autocompleteData.length > 0 ? autocompleteData[0].title : '';
    const [value, setValue] = useState<string>(initialValue);
    const [typedValue, setTypedValue] = useState<string>(initialValue);

    function filterData(searchQuery: string, data: AutocompleteOptionType[]): Promise<AutocompleteOptionType[]> {
        return new Promise((resolve) => {
            setTimeout(() => {
                const res = data.filter((item) => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
                resolve(res);
            }, 200);
        });
    }

    return (
        <Autocomplete
            options={data}
            value={typedValue}
            onDebounce={(e: any) => {
                setLoading(true);
                if (!e.target.value) {
                    onValueChange();
                    setLoading(false);
                } else {
                    filterData(e.target.value, autocompleteData).then((options) => {
                        setData(options);
                        setLoading(false);
                    });
                }
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLoading(true);
                setTypedValue(e.target.value);
            }}
            onSelect={(item: any) => {
                setTypedValue(item?.title);
                setValue(item?.title);
                onValueChange(item);
                setData([]);
            }}
        >
            <AutocompleteInput
                id="autocomplete"
                label={label}
                placeholder={placeholder}
                loading={loading}
                onBlur={() => setTypedValue(value)}
            />
            <AutocompleteOptions label={label} optionsToDisplay={4} optionHasSubtitle={false} />
        </Autocomplete>
    );
};

export default ImpactAnalysis;
