import { useContext } from 'react';

import { RegionStateContext } from './regionState.context';
import { useToast } from '../../features/toasts-container/useToast';

export function useRegionState() {
    const { addToast } = useToast();
    const useRegionState = useContext(RegionStateContext);

    if (useRegionState === undefined) {
        const message = 'Error! useRegionState hook used outside of the context provider.';
        addToast({
            type: 'danger',
            content: message,
        });
        throw new Error(message);
    }

    return useRegionState;
}
