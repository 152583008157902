import React from 'react';

import { NavLink } from 'react-router-dom';

import { Header, HeaderBrand } from '@flixbus/honeycomb-react';

import { BRAND } from '../../../constants';

const UnauthHeaderMemo = () => {
    return (
        <Header fixed={false}>
            <HeaderBrand
                key="header-brand"
                alt={BRAND.alt}
                src={BRAND.image}
                href={BRAND.href}
                RouterLink={NavLink}
                appearance="tall"
                width="124"
            />
        </Header>
    );
};

export const UnauthHeader = React.memo(UnauthHeaderMemo);
