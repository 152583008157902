import { Autocomplete, AutocompleteInput, AutocompleteOptions, AutocompleteOptionType } from '@flixbus/honeycomb-react';
import React, { useState } from 'react';

type Props = {
    label: string;
    value?: CityCountryOption;
    placeholder?: string;
    autocompleteData: CityCountryOption[];
    onValueChange: (value?: CityCountryOption) => void;
};

export type CityCountryOption = {
    title: string;
    isCountry: boolean;
    uuid: string;
};

const CityCountryAutocompleteInput: React.FC<Props> = ({
    label,
    value,
    placeholder,
    autocompleteData,
    onValueChange,
}) => {
    const [data, setData] = useState<AutocompleteOptionType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [typedValue, setTypedValue] = useState<string>('');

    const filterData = (searchQuery: string, data: AutocompleteOptionType[]): Promise<AutocompleteOptionType[]> =>
        new Promise((resolve) => {
            setTimeout(() => {
                const res = data.filter((item) => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
                resolve(res);
            }, 200);
        });

    return (
        <Autocomplete
            id="autocomplete-input"
            options={data}
            value={typedValue}
            onDebounce={(e) => {
                setLoading(true);
                if (!e.target.value) {
                    onValueChange();
                    setLoading(false);
                } else {
                    filterData(e.target.value, autocompleteData).then((options) => {
                        setData(options);
                        setLoading(false);
                    });
                }
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLoading(true);
                setTypedValue(e.target.value);
            }}
            onSelect={(item) => {
                setTypedValue(item?.title);
                onValueChange(item as CityCountryOption);
                setData([]);
            }}
            onFocus={(event) => {
                event.target.setAttribute('autocomplete', 'off');
            }}
        >
            <AutocompleteInput
                id="autocomplete"
                label={label}
                placeholder={placeholder}
                loading={loading}
                onBlur={() => {
                    if (value === undefined) setTypedValue('');
                    else setTypedValue(value.title);
                }}
            />
            <AutocompleteOptions
                label={label}
                optionsToDisplay={4}
                optionHasSubtitle={false}
                renderOption={(option) => {
                    const opt = option as CityCountryOption;
                    return <div style={{ fontWeight: opt.isCountry ? 600 : 200 }}>{option.title}</div>;
                }}
            />
        </Autocomplete>
    );
};

export default CityCountryAutocompleteInput;
