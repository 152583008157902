import { CityPairsBox } from './components/city-pairs-box/CityPairsBox';
import { DeparturesBox } from './components/departure-dates-box/DepartureDatesBox';
import React from 'react';
import styles from './FilterStep.module.scss';
import { useFormState } from '../../../data/form-state/useFormState';
import { useRuleFormContext } from '../RuleForm';

import { Box, Button, Grid, GridCol } from '@flixbus/honeycomb-react';
import { Icon, IconArrowRight } from '@flixbus/honeycomb-icons-react';

const FilterStep: React.FC = () => {
    // Get form context state
    const {
        cityPairs,
        addCityPairs,
        removeCityPairs,
        includedDates,
        setIncludedDates,
        excludedDates,
        setExcludedDates,
        daysOfTheWeek,
        setDaysOfTheWeek,
        validCityPairs,
        validIncludedDates,
        infoErrorIncludedDates,
        validExcludedDates,
        infoErrorExcludedDates,
        validDaysOfTheWeek,
        validDepartures,
        uploadedFileName,
        setUploadedFileName,
        goToInfluenceStep,
    } = useFormState();
    const ruleCTX = useRuleFormContext();

    return (
        <Grid>
            <GridCol size={12}>
                <Box extraClasses={styles.box}>
                    <Grid align="top">
                        <GridCol size={6}>
                            <CityPairsBox
                                cityPairs={cityPairs}
                                addCityPairs={addCityPairs}
                                removeCityPairs={removeCityPairs}
                                uploadedFileName={uploadedFileName}
                                setUploadedFileName={setUploadedFileName}
                                showCities={cityPairs.length > 0}
                                valid={validCityPairs}
                                infoError="At least one city pair is required"
                            />
                        </GridCol>
                        <GridCol size={6}>
                            <DeparturesBox
                                includedDates={includedDates}
                                onIncludedDatesChange={setIncludedDates}
                                excludedDates={excludedDates}
                                onExcludedDatesChange={setExcludedDates}
                                daysOfTheWeek={daysOfTheWeek}
                                onDaysOfTheWeekChange={setDaysOfTheWeek}
                                valid={validDepartures}
                                infoError="The chosen departures are empty"
                                validIncludedDates={validIncludedDates}
                                infoIncludedDatesError={infoErrorIncludedDates}
                                validExcludedDates={validExcludedDates}
                                infoExcludedDatesError={infoErrorExcludedDates}
                                validDaysOfTheWeek={validDaysOfTheWeek}
                                infoDaysOfTheWeekError="This is a mandatory field"
                            />
                        </GridCol>
                    </Grid>
                </Box>
            </GridCol>
            <GridCol push={9} size={1}>
                <ruleCTX.CancelButton />
            </GridCol>
            <GridCol size={2}>
                <Button
                    extraClasses={styles.continueButton}
                    display="block"
                    appearance="secondary"
                    onClick={goToInfluenceStep}
                >
                    Continue <Icon InlineIcon={IconArrowRight} />
                </Button>
            </GridCol>
        </Grid>
    );
};

export default FilterStep;
