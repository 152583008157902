import { DateRange, DaysOfTheWeek } from '../types';

const TITLE_REGEX =
    /[2-3]{1}[0-9]{1}[0-1]{1}[0-9]{1}[0-3]{1}[0-9]{1}-[2-3]{1}[0-9]{1}[0-1]{1}[0-9]{1}[0-3]{1}[0-9]{1}_[^_]*_[^_]*/;

function checkTitleConvention(title: string): boolean {
    return TITLE_REGEX.test(title);
}

function dateIntoConventionFormat(date: Date): string {
    return [
        date.getFullYear().toString().substr(-2),
        ('0' + (date.getMonth() + 1)).slice(-2),
        ('0' + date.getDate()).slice(-2),
    ].join('');
}

function weekdaysIntoConventionFormat(daysOfTheWeek: DaysOfTheWeek): string {
    if (daysOfTheWeek.every((day) => day === true)) {
        return 'AllWD';
    } else {
        const weekdaysArray: string[] = [];
        daysOfTheWeek.forEach((day, index) => {
            if (day) {
                weekdaysArray.push(['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'][index]);
            }
        });
        return weekdaysArray.join('-');
    }
}

export function preFillTitle(existingTitle: string, includedDates: DateRange[], daysOfTheWeek: DaysOfTheWeek): string {
    const firstDateRange = includedDates[0];
    const lastDateRange = includedDates[includedDates.length - 1];
    if (firstDateRange && lastDateRange) {
        const start_date = dateIntoConventionFormat(firstDateRange[0]);
        const end_date = dateIntoConventionFormat(lastDateRange[1]);
        const weekdays = weekdaysIntoConventionFormat(daysOfTheWeek);

        if (existingTitle === '') {
            return `${start_date}-${end_date}_add-your-purpose-here_${weekdays}`;
        } else if (checkTitleConvention(existingTitle)) {
            const purpose = existingTitle.split('_')[1];
            const extraInfo = existingTitle.split('_')[3];
            const finalTitle = `${start_date}-${end_date}_${purpose}_${weekdays}`;
            return extraInfo ? finalTitle + '_' + extraInfo : finalTitle;
        } else {
            return existingTitle;
        }
    }

    return existingTitle;
}
