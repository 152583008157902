import React from 'react';

import { Box, Grid, GridCol, Heading, Input, Switch } from '@flixbus/honeycomb-react';

import MultiAutoCompleteInput from '../../../../../features/autocomplete/MultiAutocompleteInput';
import { useFilterData } from '../../../../../data/filter-data/useFilterData';

import styles from './GeneralInfoBox.module.scss';

type Props = {
    title: string;
    onTitleChange: (newTitle: string) => void;
    tags: string[];
    onTagsChange: (newTags: string[]) => void;
    active: boolean;
    onActiveChange: (newActiveStatus: boolean) => void;
    validTitle: boolean | undefined;
    infoErrorTitle: string;
};

const GeneralInfoBoxComp: React.FC<Props> = ({
    title,
    onTitleChange,
    tags,
    onTagsChange,
    active,
    onActiveChange,
    validTitle,
    infoErrorTitle,
}) => {
    // Get filter data
    const { tagsData } = useFilterData();

    return (
        <Box extraClasses={styles.box}>
            <Grid align="center">
                <GridCol key="heading-general-info" size={12} extraClasses={styles.heading}>
                    <Heading sectionHeader size={4}>
                        General Information
                    </Heading>
                </GridCol>
                <GridCol key="heading-general-title-input" size={12} extraClasses={styles.topRow}>
                    <Input
                        id="title-input"
                        label="Title"
                        value={title}
                        onChange={(e) => onTitleChange(e.target.value)}
                        valid={validTitle === true ? undefined : validTitle}
                        info="The title naming convention is: YYMMDD-YYMMDD_Purpose_Weekdays_*"
                        infoError={infoErrorTitle}
                    />
                </GridCol>
                <GridCol key="heading-general-tags-input" size={12} extraClasses={styles.middleRow}>
                    <MultiAutoCompleteInput
                        label="Tags"
                        autocompleteData={tagsData}
                        info="The tagging convention is: Business Region; RM/PM; Category; Name;"
                        value={tags}
                        onValueChange={(v) => onTagsChange(v)}
                        allowAddingNew
                    />
                </GridCol>
                <GridCol key="active-status-input" size={12} extraClasses={styles.lastRow}>
                    <Switch
                        label="Active"
                        id="switch-active-status"
                        stacked
                        checked={active}
                        onChange={(e) => onActiveChange(e.target.checked)}
                    />
                </GridCol>
            </Grid>
        </Box>
    );
};

export const GeneralInfoBox = React.memo(GeneralInfoBoxComp);
