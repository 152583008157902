import { datadogRum } from '@datadog/browser-rum';

export const initDataDog = () => {
    datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
        site: 'datadoghq.eu',
        service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
        env: process.env.REACT_APP_BRANCH === 'master' ? 'prod' : 'stg',
        version: process.env.REACT_APP_TT_VERSION,
        sampleRate: 100,
        premiumSampleRate: 0,
        trackInteractions: false,
        trackResources: true,
    });
};

export const addDataDogError = (error: Error) => {
    datadogRum.addError(error);
};

export const reportPreferredCityPairsSelectionMethod = (selectionMethod: 'CitiesFilter' | 'CSVUpload') => {
    datadogRum.addAction('preferredCityPairsSelectionMethod', {
        selectionMethod: selectionMethod,
    });
};

export const reportTurboTouchRuleSubmitted = (isEditing = false) => {
    if (isEditing) {
        datadogRum.addAction('turboTouchRuleEdited');
    } else {
        datadogRum.addAction('turboTouchRuleCreated');
    }
};
