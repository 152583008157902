import React from 'react';

import { Tag } from '@flixbus/honeycomb-react';

type Props = {
    alpha: number;
};

const AlphaPill: React.FC<Props> = ({ alpha }) => {
    const formatedAlpha = `${alpha > 0 ? '+' : '-'}${Math.ceil(Math.abs(100 * alpha))}%`;
    const tagAppearence = alpha > 0 ? 'success' : 'warning';

    return (
        <Tag appearance={tagAppearence} outlined>
            {formatedAlpha}
        </Tag>
    );
};

export default AlphaPill;
