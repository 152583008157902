import { cityPairStringToArray } from '../../utils/cityPairs.utils';
import { preFillTitle } from '../../utils/title.utils';
import { useFetchOverlappingRules } from '../overlaps-data/useFetchOverlappingRules';

import { APP_PATHS, StepsType } from '../../constants';
import { CityPairString, DateRange, DaysBeforeDeparture, DaysOfTheWeek, RuleData } from '../../types';
import { departuresLength, hasOverlappingRanges, isAPastDateRange } from '../../utils/date.utils';
import React, { createContext, ReactNode, useEffect, useMemo, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function cityPairsReducer(
    state: CityPairString[],
    action: { type: string; payload: CityPairString[] },
): CityPairString[] {
    switch (action.type) {
        case 'add':
            return Array.from(new Set([...state, ...action.payload]));
        case 'remove':
            // eslint-disable-next-line no-case-declarations
            const removedCityPairs = new Set(action.payload);
            return state.filter((cityPair) => !removedCityPairs.has(cityPair));
        case 'init':
            return action.payload;
        default:
            throw new Error(`cityPairsReducer does not have action ${action.type}`);
    }
}

enum FormSteps {
    FILTER = 0,
    INFLUENCE = 1,
    SUBMIT = 2,
}

// Note: this context is quite big and might lead to performance
// issues in the creation form because of forcing rerenders.
// In case this happens, consider spliting in multiple contexts
// or using a flux-like approach!!
type FormState = {
    currentStep: StepsType;
    goPrevStep: () => void;
    goToFilterStep: () => void;
    goToInfluenceStep: () => void;
    goToSubmitStep: () => void;
    ruleId: number | undefined;
    setRuleId: (newRuleId: number | undefined) => void;
    cityPairs: CityPairString[];
    addCityPairs: (newCityPairs: CityPairString[]) => void;
    removeCityPairs: (removeCityPairs: CityPairString[]) => void;
    includedDates: DateRange[];
    setIncludedDates: (newIncludedDates: DateRange[]) => void;
    excludedDates: DateRange[];
    setExcludedDates: (newExcludedDates: DateRange[]) => void;
    daysOfTheWeek: DaysOfTheWeek;
    setDaysOfTheWeek: (newDaysOfTheWeek: DaysOfTheWeek) => void;
    daysBeforeDeparture: DaysBeforeDeparture;
    setDaysBeforeDeparture: (newDaysBeforeDeparture: DaysBeforeDeparture) => void;
    alpha: number;
    setAlpha: (newAlpha: number) => void;
    title: string;
    setTitle: (newTitle: string) => void;
    tags: string[];
    setTags: (newTags: string[]) => void;
    active: boolean;
    setActive: (newActiveStatus: boolean) => void;
    validCityPairs: boolean;
    validIncludedDates: boolean;
    infoErrorIncludedDates: string;
    validExcludedDates: boolean;
    infoErrorExcludedDates: string;
    validDaysOfTheWeek: boolean;
    validDepartures: boolean;
    validAlpha: boolean;
    validPBP: boolean;
    uploadedFileName: string;
    setUploadedFileName: (newUploadedFileName: string) => void;
    overlappingRules: RuleData[];
    setOverlappingRules: (newOverlappingRules: RuleData[]) => void;
    overlapsDataLoading: boolean;
    refreshCounterVisData: number;
    refreshVisData: () => void;
    initializeForm: (
        ruleId?: number,
        cityPairs?: CityPairString[],
        includedDates?: DateRange[],
        excludedDates?: DateRange[],
        daysOfTheWeek?: DaysOfTheWeek,
        daysBeforeDeparture?: DaysBeforeDeparture,
        alpha?: number,
        title?: string,
        tags?: string[],
        active?: boolean,
    ) => void;
};

export const FormStateContext = createContext<FormState | undefined>(undefined);

export function FormStateProvider({ children }: { children?: ReactNode }) {
    // Set form content state
    const [ruleId, setRuleId] = useState<number | undefined>(undefined);
    const [cityPairs, dispatchCityPairs] = useReducer(cityPairsReducer, []);
    const [includedDates, setIncludedDates] = useState<DateRange[]>([]);
    const [excludedDates, setExcludedDates] = useState<DateRange[]>([]);
    const [daysOfTheWeek, setDaysOfTheWeek] = useState<DaysOfTheWeek>([true, true, true, true, true, true, true]);
    const [daysBeforeDeparture, setDaysBeforeDeparture] = useState<DaysBeforeDeparture>([365, 0]);
    const [alpha, setAlpha] = useState<number>(0);
    const [title, setTitle] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const [active, setActive] = useState<boolean>(true);
    const [refreshCounterVisData, setRefreshCounter] = useState<number>(0);

    // Initialize validation states
    const [validCityPairs, setValidCityPairs] = useState<boolean>(true);
    const [validIncludedDates, setValidIncludedDates] = useState<boolean>(true);
    const [infoErrorIncludedDates, setInfoErrorIncludedDates] = useState<string>('');
    const [validExcludedDates, setValidExcludedDates] = useState<boolean>(true);
    const [infoErrorExcludedDates, setInfoErrorExcludedDates] = useState<string>('');
    const [validDaysOfTheWeek, setValidIsDaysOfTheWeek] = useState<boolean>(true);
    const [validDepartures, setValidDepartures] = useState<boolean>(true);
    const [validAlpha, setValidAlpha] = useState<boolean>(true);
    const [validPBP, setValidPBP] = useState<boolean>(true);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setValidIncludedDates(true);
        setInfoErrorIncludedDates('');
        if (includedDates.length > 0) {
            if (hasOverlappingRanges(includedDates)) {
                setValidIncludedDates(false);
                setInfoErrorIncludedDates('Invalid ranges: no overlaps can be present');
            } else if (!ruleId && includedDates.some((dateRange) => isAPastDateRange(dateRange))) {
                setValidIncludedDates(false);
                setInfoErrorIncludedDates('Invalid range(s): dates must be in the future');
            }
        }

        setValidExcludedDates(true);
        setInfoErrorExcludedDates('');
        if (excludedDates.length > 0) {
            if (hasOverlappingRanges(excludedDates)) {
                setValidExcludedDates(false);
                setInfoErrorExcludedDates('Invalid ranges: no overlaps can be present');
            } else if (excludedDates.some((dateRange) => isAPastDateRange(dateRange))) {
                setValidExcludedDates(false);
                setInfoErrorExcludedDates('Invalid range(s): dates must be in the future');
            } else if (!excludedDates.every((r1) => includedDates.some((r2) => r1[0] >= r2[0] && r1[1] <= r2[1]))) {
                setValidExcludedDates(false);
                setInfoErrorExcludedDates('Invalid range: not contained on included departures');
            }
        }
    }, [includedDates, excludedDates, ruleId]);
    useEffect(() => {
        if (daysOfTheWeek.some((day) => day)) {
            setValidIsDaysOfTheWeek(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [daysOfTheWeek]);
    useEffect(() => {
        if (!validDepartures) {
            if (departuresLength(includedDates, excludedDates, daysOfTheWeek) > 0) {
                setValidDepartures(true);
            }
        }
    }, [includedDates, excludedDates, daysOfTheWeek, validDepartures]);
    useEffect(() => {
        if (cityPairs.length > 0) {
            setValidCityPairs(true);
        }
    }, [cityPairs]);
    useEffect(() => {
        if (alpha !== 0) {
            setValidAlpha(true);
        }
    }, [alpha]);
    useEffect(() => {
        if (daysBeforeDeparture[0] > daysBeforeDeparture[1]) {
            setValidPBP(true);
        }
    }, [daysBeforeDeparture]);

    const validateFilterStep = (): boolean => {
        let canContionue = true;
        if (includedDates.length === 0 || !validIncludedDates) {
            setValidIncludedDates(false);
            canContionue = false;
        }
        if (daysOfTheWeek.every((day) => day === false)) {
            setValidIsDaysOfTheWeek(false);
            canContionue = false;
        } else {
            setValidIsDaysOfTheWeek(true);
        }
        if (!validExcludedDates) canContionue = false;
        if (canContionue) {
            if (departuresLength(includedDates, excludedDates, daysOfTheWeek) === 0) {
                setValidDepartures(false);
                canContionue = false;
            }
        }
        if (cityPairs.length === 0) {
            setValidCityPairs(false);
            canContionue = false;
        } else {
            setValidCityPairs(true);
        }
        return canContionue;
    };
    const validateInfluenceStep = (): boolean => {
        let canContinue = true;
        if (alpha === 0) {
            setValidAlpha(false);
            canContinue = false;
        }
        if (daysBeforeDeparture[0] <= daysBeforeDeparture[1]) {
            setValidPBP(false);
            canContinue = false;
        }
        return canContinue;
    };

    // Set form navigation step
    const formPath = location.pathname.split('/')[1];
    const [currentStep, setCurrentState] = useState<StepsType>(0);
    const goPrevState = () => {
        if (currentStep === FormSteps.SUBMIT) {
            goToInfluenceStep();
        } else if (currentStep === FormSteps.INFLUENCE) {
            goToFilterStep();
        }
    };
    const goToFilterStep = () => {
        window.scrollTo(0, 0);
        setCurrentState(FormSteps.FILTER);
        navigate(`${formPath}/${APP_PATHS.FORM_STEP_FILTER}`);
    };
    const goToInfluenceStep = () => {
        if (validateFilterStep()) {
            window.scrollTo(0, 0);
            setCurrentState(FormSteps.INFLUENCE);
            navigate(`${formPath}/${APP_PATHS.FORM_STEP_INFLUENCE}`);
        } else {
            goToFilterStep();
        }
    };
    const goToSubmitStep = () => {
        if (validateFilterStep()) {
            if (validateInfluenceStep()) {
                window.scrollTo(0, 0);
                setCurrentState(FormSteps.SUBMIT);
                navigate(`${formPath}/${APP_PATHS.FORM_STEP_SUBMIT}`);
            } else {
                goToInfluenceStep();
            }
        } else {
            goToFilterStep();
        }
    };

    // Set CSV upload state
    const [uploadedFileName, setUploadedFileName] = useState<string>('');

    // Set overlaps state
    const selectCityPairUUIDs = useMemo(() => cityPairs.map((cp) => cityPairStringToArray(cp)), [cityPairs]);
    const { data: APIoverlappingRules, loading: overlapsDataLoading } = useFetchOverlappingRules(
        selectCityPairUUIDs,
        includedDates,
        excludedDates,
        daysOfTheWeek,
        daysBeforeDeparture,
        ruleId,
    );
    const [overlappingRules, setOverlappingRules] = useState<RuleData[]>([]);
    useEffect(
        () =>
            setOverlappingRules([...APIoverlappingRules, ...overlappingRules.filter((rule) => rule.active === false)]),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [APIoverlappingRules, setOverlappingRules],
    );

    useEffect(
        function syncStateWithRoute() {
            if (location.pathname.endsWith(APP_PATHS.FORM_STEP_FILTER) && currentStep !== FormSteps.FILTER) {
                setCurrentState(FormSteps.FILTER);
            }
            if (location.pathname.endsWith(APP_PATHS.FORM_STEP_INFLUENCE) && currentStep !== FormSteps.INFLUENCE) {
                navigate(`${APP_PATHS.FORM_CREATE}/${APP_PATHS.FORM_STEP_FILTER}`);
            }
            if (location.pathname.endsWith(APP_PATHS.FORM_STEP_SUBMIT) && currentStep !== FormSteps.SUBMIT) {
                navigate(`${APP_PATHS.FORM_CREATE}/${APP_PATHS.FORM_STEP_FILTER}`);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location.pathname, currentStep],
    );

    // Function to initialize form state
    function initilizeForm(
        ruleId?: number,
        cityPairs?: CityPairString[],
        includedDates?: DateRange[],
        excludedDates?: DateRange[],
        daysOfTheWeek?: DaysOfTheWeek,
        daysBeforeDeparture?: DaysBeforeDeparture,
        alpha?: number,
        title?: string,
        tags?: string[],
        active?: boolean,
    ) {
        setCurrentState(0);
        setRuleId(ruleId);
        dispatchCityPairs({ type: 'init', payload: cityPairs ? cityPairs : [] });
        setIncludedDates(includedDates ? includedDates : []);
        setExcludedDates(excludedDates ? excludedDates : []);
        setDaysOfTheWeek(daysOfTheWeek ? daysOfTheWeek : [true, true, true, true, true, true, true]);
        setDaysBeforeDeparture(daysBeforeDeparture ? daysBeforeDeparture : [365, 0]);
        setAlpha(alpha ? alpha : 0);
        setTitle(title ? title : '');
        setTags(tags ? tags : []);
        setActive(active !== undefined ? active : true);
        setUploadedFileName('');
        setOverlappingRules([]);
        setValidCityPairs(true);
        setValidIncludedDates(true);
        setInfoErrorIncludedDates('');
        setValidExcludedDates(true);
        setInfoErrorExcludedDates('');
        setValidIsDaysOfTheWeek(true);
        setValidDepartures(true);
        setValidAlpha(true);
        setValidPBP(true);
    }

    // Pre-fill title automatically based on title standarn
    useEffect(() => {
        const preFilledTitle = preFillTitle(title, includedDates, daysOfTheWeek);
        setTitle(preFilledTitle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [includedDates, daysOfTheWeek]);

    return (
        <FormStateContext.Provider
            value={{
                currentStep: currentStep,
                goPrevStep: goPrevState,
                goToFilterStep: goToFilterStep,
                goToInfluenceStep: goToInfluenceStep,
                goToSubmitStep: goToSubmitStep,
                ruleId: ruleId,
                setRuleId: setRuleId,
                cityPairs: cityPairs,
                addCityPairs: (cityPairs) => dispatchCityPairs({ type: 'add', payload: cityPairs }),
                removeCityPairs: (cityPairs) => dispatchCityPairs({ type: 'remove', payload: cityPairs }),
                includedDates: includedDates,
                setIncludedDates: setIncludedDates,
                excludedDates: excludedDates,
                setExcludedDates: setExcludedDates,
                daysOfTheWeek: daysOfTheWeek,
                setDaysOfTheWeek: setDaysOfTheWeek,
                daysBeforeDeparture: daysBeforeDeparture,
                setDaysBeforeDeparture: setDaysBeforeDeparture,
                alpha: alpha,
                setAlpha: setAlpha,
                title: title,
                setTitle: setTitle,
                tags: tags,
                setTags: setTags,
                active: active,
                setActive: setActive,
                validCityPairs: validCityPairs,
                validIncludedDates: validIncludedDates,
                infoErrorIncludedDates: infoErrorIncludedDates,
                validExcludedDates: validExcludedDates,
                infoErrorExcludedDates: infoErrorExcludedDates,
                validDaysOfTheWeek: validDaysOfTheWeek,
                validDepartures: validDepartures,
                validAlpha: validAlpha,
                validPBP: validPBP,
                uploadedFileName: uploadedFileName,
                setUploadedFileName: setUploadedFileName,
                overlappingRules: overlappingRules,
                setOverlappingRules: setOverlappingRules,
                overlapsDataLoading: overlapsDataLoading,
                refreshCounterVisData: refreshCounterVisData,
                refreshVisData: () => setRefreshCounter(refreshCounterVisData + 1),
                initializeForm: initilizeForm,
            }}
        >
            {children}
        </FormStateContext.Provider>
    );
}
