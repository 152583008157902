import ChartTitle from '../common/ChartTitle';
import CustomTooltip from './components/CustomTooltip';
import { dateToDEString } from '../../../utils/date.utils';
import styles from './ChartPerWeekday.module.scss';
import { visDataToWeekdayChartData } from '../../../utils/visualization.utils';
import { BoxChartDataPoint, CityPairString, DateRange, DisplayKPIs, VisDataPoint } from '../../../types';

import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Label,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { Grid, GridCol } from '@flixbus/honeycomb-react';
import React, { useMemo } from 'react';
import { Region, REGION_MAP } from '../../../constants';

const TRACE_COLORS = {
    base: styles.basecolor,
    increased: styles.increasecolor,
    decreased: styles.decreasecolor,
    alpha: styles.alphacolor,
};

type PriceScatterProps = {
    cx?: number;
    cy?: number;
    payload?: any;
    isTurbo?: boolean;
    isDateRangeView?: boolean;
};

const PriceScatterChartPoint = ({ cx = 0, cy = 0, payload }: PriceScatterProps) => {
    if (!payload) {
        return null;
    }

    return <circle r={4.5} cx={cx - 14} cy={cy} fill={TRACE_COLORS.alpha} />;
};

type PriceModScatterProps = {
    cx?: number;
    cy?: number;
    payload?: any;
    isTurbo?: boolean;
    isDateRangeView?: boolean;
};

const PriceModScatterChartPoint = ({ cx = 0, cy = 0, payload }: PriceModScatterProps) => {
    if (!payload) {
        return null;
    }

    if (payload.medianPriceOpt === payload.medianPriceOptMod) {
        return null;
    }

    return (
        <circle
            r={4.5}
            cx={cx + 14}
            cy={cy}
            fill={payload.medianPriceOptMod > payload.medianPriceOpt ? TRACE_COLORS.increased : TRACE_COLORS.decreased}
        />
    );
};

function calculateBarOrdinates(x: number, y: number, height: number, width: number) {
    const leftOffset = width / 2;
    // add the offset to X for aligning the line to the center of bar width
    const xValue = x + leftOffset;
    const mainLineTop = y;
    const mainLineBottom = y + height;
    const leftOffsetX = xValue - 4;
    const rightOffsetX = xValue + 4;
    const mainLineOrdinates = {
        x1: xValue,
        y1: mainLineTop,
        x2: xValue,
        y2: mainLineBottom,
    };
    const topLineOrdinates = {
        x1: leftOffsetX,
        y1: mainLineTop,
        x2: rightOffsetX,
        y2: mainLineTop,
    };
    const bottomLineOrdinates = {
        x1: leftOffsetX,
        y1: mainLineBottom,
        x2: rightOffsetX,
        y2: mainLineBottom,
    };

    return [mainLineOrdinates, topLineOrdinates, bottomLineOrdinates];
}

type BarChartLineBarProp = {
    index: number;
    x: number;
    y: number;
    height: number;
    width: number;
    payload?: any;
};

const BarChartLineBar = ({ index, x, y, height, width }: BarChartLineBarProp) => {
    const [mainLineOrdinates, topLineOrdinates, bottomLineOrdinates] = calculateBarOrdinates(x, y, height, width);

    return (
        <svg key={index} className={styles.linesContainer}>
            <line {...mainLineOrdinates} stroke={TRACE_COLORS.alpha} strokeWidth={2} />
            <line {...topLineOrdinates} stroke={TRACE_COLORS.alpha} strokeWidth={2} />
            <line {...bottomLineOrdinates} stroke={TRACE_COLORS.alpha} strokeWidth={2} />
        </svg>
    );
};

const BarChartLineBarMod = ({ index, x, y, height, width, payload }: BarChartLineBarProp) => {
    if (payload.medianPriceOpt === payload.medianPriceOptMod) {
        return <svg />;
    }

    const [mainLineOrdinates, topLineOrdinates, bottomLineOrdinates] = calculateBarOrdinates(x, y, height, width);
    const color = payload.medianPriceOpt < payload.medianPriceOptMod ? TRACE_COLORS.increased : TRACE_COLORS.decreased;

    return (
        <svg key={index} className={styles.linesContainer}>
            <line {...mainLineOrdinates} stroke={color} strokeWidth={2} />
            <line {...topLineOrdinates} stroke={color} strokeWidth={2} />
            <line {...bottomLineOrdinates} stroke={color} strokeWidth={2} />
        </svg>
    );
};

type Props = {
    cityPair: CityPairString;
    data: VisDataPoint[];
    displayDates: DateRange | undefined;
    priceDomain: [number, number];
    region: Region;
    exchangeRate: number;
    onClickWeekdaysChart: (e: any) => void;
    displayKPI: DisplayKPIs;
};

const ChartPerWeekday: React.FC<Props> = ({
    cityPair,
    data,
    displayDates,
    priceDomain,
    region,
    exchangeRate,
    onClickWeekdaysChart,
    displayKPI,
}) => {
    const finalVisData: BoxChartDataPoint[] = visDataToWeekdayChartData(data, exchangeRate);

    const dateTitle = useMemo(() => {
        return `${dateToDEString(displayDates ? displayDates[0] : new Date())} - ${dateToDEString(
            displayDates ? displayDates[1] : new Date(),
        )} (Weekdays)`;
    }, [displayDates]);

    const currencySymbol = REGION_MAP[region].symbol;
    const distanceUnit = REGION_MAP[region].distanceUnit;
    const yLabel =
        displayKPI === 'yield'
            ? `Forecast Yield (${currencySymbol} / 100${distanceUnit})`
            : `Forecast Price (${currencySymbol})`;
    const yPosition = displayKPI === 'yield' ? 75 : 50;

    return (
        <Grid>
            <GridCol size={12}>
                <ChartTitle cityPair={cityPair} dates={dateTitle} includeAlpha={false} />
            </GridCol>
            <GridCol size={12}>
                <ResponsiveContainer width="100%" height={275}>
                    <ComposedChart
                        className={styles.chart}
                        data={finalVisData}
                        barSize={24}
                        margin={{
                            top: 30,
                            right: 10,
                            left: 15,
                            bottom: 5,
                        }}
                        onClick={onClickWeekdaysChart}
                    >
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <YAxis yAxisId="left" orientation="left" type="number" domain={priceDomain}>
                            <Label value={yLabel} angle={-90} position="insideLeft" offset={0} dx={5} dy={yPosition} />
                        </YAxis>
                        <XAxis dataKey="weekday" height={60} padding={{ left: 50, right: 50 }} />
                        <Tooltip
                            content={
                                <CustomTooltip
                                    distanceUnit={distanceUnit}
                                    currencySymbol={currencySymbol}
                                    displayKPI={displayKPI}
                                />
                            }
                        />
                        <Scatter
                            yAxisId="left"
                            dataKey="medianPriceOpt"
                            type="monotone"
                            shape={<PriceScatterChartPoint />}
                        />
                        <Bar yAxisId="left" dataKey="range" type="monotone" shape={BarChartLineBar} />
                        <Scatter
                            yAxisId="left"
                            dataKey="medianPriceOptMod"
                            type="monotone"
                            shape={<PriceModScatterChartPoint />}
                        />
                        <Bar yAxisId="left" dataKey="rangeMod" type="monotone" shape={BarChartLineBarMod} />
                    </ComposedChart>
                </ResponsiveContainer>
            </GridCol>
        </Grid>
    );
};

export default ChartPerWeekday;
