import App from './app/App';
import { AuthProvider } from './app/features/auth';
import { AxiosProvider } from './app/utils/transport/axios.context';
import { DatadogErrorBoundary } from './app/utils/datadog/error-boundary/ErrorBoundary';
import { initDataDog } from './app/utils/datadog/datadog.utils';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastsProvider } from './app/features/toasts-container';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.css';

if (process.env.NODE_ENV === 'production') {
    initDataDog();
} else if (process.env.REACT_APP_USE_MOCK_BACKEND === 'TRUE') {
    console.info('Environment: Using mock backend', process.env.REACT_APP_USE_MOCK_BACKEND);
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const mockServer = require('./mock-server/server').mockServer;
    mockServer({ environment: process.env.NODE_ENV || 'test' });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <DatadogErrorBoundary>
            <AuthProvider>
                <AxiosProvider>
                    <ToastsProvider>
                        <App />
                    </ToastsProvider>
                </AxiosProvider>
            </AuthProvider>
        </DatadogErrorBoundary>
    </React.StrictMode>,
);

serviceWorkerRegistration.register();
