import { useContext } from 'react';

import { FormStateContext } from './form.context';
import { useToast } from '../../features/toasts-container/useToast';

export function useFormState() {
    const { addToast } = useToast();
    const useFormState = useContext(FormStateContext);

    if (useFormState === undefined) {
        const message = 'Error! useFormState hook used outside of the context provider.';
        addToast({
            type: 'danger',
            content: message,
        });
        throw new Error(message);
    }

    return useFormState;
}
