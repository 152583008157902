import React from 'react';

import { FormRow, Grid, GridCol, Skeleton } from '@flixbus/honeycomb-react';

import styles from './RulesGridSkeleton.module.scss';

const N_RULE_SKELETONS = 10;

const RulesGridSkeleton = () => {
    return (
        <>
            <FormRow extraClasses={styles.headerRow}>
                <Grid align="bottom">
                    <GridCol key="rule-length-indicator" size={1}>
                        <Skeleton extraClasses={styles.fullWidthSkeleton} height="sm" />
                    </GridCol>
                    <GridCol key="rule-sorting" size={1} push={10}>
                        <Skeleton extraClasses={styles.fullWidthSkeleton} height="sm" />
                    </GridCol>
                </Grid>
            </FormRow>
            {Array.from({ length: N_RULE_SKELETONS }, (_x, i) => i).map((item) => (
                <Skeleton key={`rule-skeleton-${item}`} extraClasses={styles.ruleSkeleton} />
            ))}
        </>
    );
};

export default RulesGridSkeleton;
