import { getWeek } from '../../../../utils/date.utils';
import React from 'react';
import styles from './CustomTicks.module.scss';

const STROKE_COLOR = styles.strokecolor;

const CustomTicks = (pros: any) => {
    const { x, y, payload, data } = pros;
    const { value, index } = payload;
    // The following check make sure no labels are displayed for invalid dates and
    // date parsing  will not be broken
    // For eg: during loading recharts pass in values [0, 'auto'] by default
    if (!(value && value instanceof Date)) {
        return null;
    }
    const day = value.getUTCDate();
    const isWeekend = value && !(value.getUTCDay() % 6);
    const prevDateInfo = data[index - 1];

    const prevDateWeek = prevDateInfo ? getWeek(prevDateInfo.date) : '';
    const currDateWeek = index > 0 && value && getWeek(value);
    const withWeekNo = currDateWeek && currDateWeek !== prevDateWeek ? currDateWeek : undefined;

    const prevMonth = prevDateInfo ? prevDateInfo.date.getUTCMonth() : '';
    const currMoth = index > 0 && value && value.getUTCMonth();
    const withMonth = (currMoth !== undefined && currMoth !== prevMonth) || index === 0 ? true : false;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={12} y={0} dy={12} textAnchor="end" fontWeight={isWeekend ? 'bold' : ''}>
                {`${day < 10 ? '0' : ''}${day}.`}
            </text>
            {withWeekNo && (
                <>
                    <line x1={-15} x2={-15} y1={0} y2={32} stroke={STROKE_COLOR} strokeWidth={1} />
                    <text x={15} y={20} dy={12} textAnchor="end">
                        w{withWeekNo}
                    </text>
                </>
            )}
            {withMonth && (
                <>
                    <line x1={-15} x2={-15} y1={0} y2={58} stroke={STROKE_COLOR} strokeWidth={1} />
                    <text x={15} y={42} dy={12} textAnchor="end">
                        {value.toLocaleString('en-US', { month: 'short' })}
                    </text>
                </>
            )}
        </g>
    );
};

export default CustomTicks;
