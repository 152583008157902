import { APP_PATHS } from '../../../constants';
import { RuleData } from '../../../types';
import styles from './ActionDropdown.module.scss';
import useOutsideClick from '../../../utils/hooks/useOutsideClicks';
import { usePutRuleActiveStatus } from '../../../data/update-active-status/usePutRuleActiveStatus';

import { Button, Dropdown, Heading, Popup, PopupSection } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconAttention,
    IconCopy,
    IconEdit,
    IconKebab,
    IconMinusStrong,
    IconOffer,
} from '@flixbus/honeycomb-icons-react';

import { useNavigate } from 'react-router-dom';
import React, { useRef, useState } from 'react';

type Props = {
    ruleId: number;
    title: string;
    activeStatus: boolean;
    updateRuleActiveStatus?: (ruleId: number, update: Partial<RuleData>) => void;
    initializeCloneForm?: (ruleId: number) => void;
    initializeEditForm?: (ruleId: number) => void;
};

const ActionsDropdown: React.FC<Props> = ({
    ruleId,
    title,
    activeStatus,
    updateRuleActiveStatus,
    initializeCloneForm,
    initializeEditForm,
}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const { updateActiveStatus } = usePutRuleActiveStatus();
    const navigate = useNavigate();
    const dropdownOptions = [];
    if (updateRuleActiveStatus) {
        dropdownOptions.push({
            text: activeStatus ? ' Deactivate' : ' Activate',
            InlineIcon: activeStatus ? IconMinusStrong : IconOffer,
            onClick: async (event: any) => {
                event.preventDefault();
                setShowPopup(true);
            },
        });
    }
    if (initializeCloneForm) {
        dropdownOptions.push({
            text: ' Clone',
            InlineIcon: IconCopy,
            onClick: async (event: any) => {
                event.preventDefault();
                initializeCloneForm && initializeCloneForm(ruleId);
                navigate(`${APP_PATHS.FORM_CLONE}/${APP_PATHS.FORM_STEP_FILTER}`);
            },
        });
    }
    if (initializeEditForm) {
        dropdownOptions.push({
            text: ' Edit',
            InlineIcon: IconEdit,
            onClick: async (event: any) => {
                event.preventDefault();
                initializeEditForm && initializeEditForm(ruleId);
                navigate(`${APP_PATHS.FORM_EDIT}/${APP_PATHS.FORM_STEP_FILTER}`);
            },
        });
    }
    const onUpdateStatusClick = async () => {
        if (updateRuleActiveStatus) {
            const success = await updateActiveStatus(ruleId, !activeStatus);
            if (success) {
                updateRuleActiveStatus(ruleId, { active: !activeStatus });
            }
        }
    };

    // Register callback to close dropdown on outside click
    const dropdownContainerRef = useRef<HTMLDivElement>(null);
    useOutsideClick(dropdownContainerRef, () => {
        setShowDropdown(false);
    });

    return (
        <>
            {showDropdown ? (
                <div ref={dropdownContainerRef}>
                    <Dropdown active links={dropdownOptions} xPosition="left" yPosition="bottom">
                        <Button link onClick={() => setShowDropdown(false)}>
                            <Icon InlineIcon={IconKebab} />
                        </Button>
                    </Dropdown>
                </div>
            ) : (
                <Button link onClick={() => setShowDropdown(true)}>
                    <Icon InlineIcon={IconKebab} />
                </Button>
            )}
            {/* Below the popup component to double-check with the user the action taken */}
            <Popup
                id="popup-second-example"
                active={showPopup}
                // onOverlayClick={() => setShowPopup(false)}
            >
                <PopupSection type="icon">
                    <Icon InlineIcon={IconAttention} size={8} />
                </PopupSection>
                <PopupSection type="title">
                    <Heading size={2}>Rule {activeStatus ? 'deactivation' : 'activation'}</Heading>
                </PopupSection>
                <PopupSection type="content">
                    Are you sure you want to {activeStatus ? 'deactivate' : 'activate'} and apply rule {title} globally?
                </PopupSection>
                <PopupSection type="actions">
                    <Button
                        appearance="primary"
                        onClick={() => {
                            onUpdateStatusClick();
                            setShowPopup(false);
                            setShowDropdown(false);
                        }}
                    >
                        {activeStatus ? 'Deactivate' : 'Activate'}{' '}
                    </Button>
                    <Button
                        extraClasses={styles.popupButton}
                        appearance="tertiary"
                        onClick={() => {
                            setShowPopup(false);
                            setShowDropdown(false);
                        }}
                    >
                        Cancel
                    </Button>
                </PopupSection>
            </Popup>
        </>
    );
};

export default ActionsDropdown;
