import { addDataDogError } from '../../utils/datadog/datadog.utils';
import { ENDPOINT_URLS } from '../../constants';
import { useAxios } from '../../utils/transport/useAxios';
import { useToast } from '../../features/toasts-container';

export function usePutRuleActiveStatus() {
    const apiClient = useAxios();
    const { addToast } = useToast();

    const updateActiveStatus = async (ruleId: number, newActiveStatus: boolean): Promise<boolean> => {
        let success = false;
        try {
            const res = await apiClient.put(ENDPOINT_URLS.UPDATE_RULE_ACTIVE_STATUS, {
                rule_id: ruleId,
                update_status: newActiveStatus,
            });

            if (res.status !== 200) {
                throw new Error(`Error with status ${res.status}`);
            }

            success = true;
        } catch (error) {
            addDataDogError(error as Error);
            addToast({
                type: 'danger',
                content: `Update active status API call failed: ${(error as Error).message}.`,
            });
        }

        return success;
    };

    return { updateActiveStatus };
}
