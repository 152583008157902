import React from 'react';
import styles from './HeadingWithTooltip.module.scss';

import { Button, Heading, Tooltip } from '@flixbus/honeycomb-react';
import { Icon, IconInfo } from '@flixbus/honeycomb-icons-react';

type Props = {
    title: string;
    tooltip: React.ReactNode;
    tooltipSize: 'content-fit' | 'medium' | 'large';
};

const HeadingWithTooltip: React.FC<Props> = ({ title, tooltip, tooltipSize }) => {
    return (
        <>
            <Heading extraClasses={styles.heading} sectionHeader size={4}>
                {title}
            </Heading>
            <Tooltip
                extraClasses={styles.tooltip}
                id="tooltip-bot"
                content={tooltip}
                position="top"
                smartPosition
                size={tooltipSize}
            >
                <Button aria-label="info-button" extraClasses={styles.headingInfoButton} link>
                    <Icon InlineIcon={IconInfo} />
                </Button>
            </Tooltip>
        </>
    );
};

export default HeadingWithTooltip;
