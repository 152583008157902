import React from 'react';

import { PageContainer } from '@flixbus/honeycomb-react';

const MemoNotFound = () => (
    <PageContainer>
        <h1>Not Found 404</h1>
        <p>
            <strong>Try something else</strong>
        </p>
    </PageContainer>
);

export const NotFound = React.memo(MemoNotFound);
