import { GeneralInfoBox } from './components/general-info-box/GeneralInfoBox';
import { MAX_TITLE_LENGTH } from '../../../constants';
import { OverlapsSummary } from './components/overlaps-summary/OverlapsSummary';
import { reportTurboTouchRuleSubmitted } from '../../../utils/datadog/datadog.utils';
import { RuleSummary } from './components/rule-summary/RuleSummary';
import styles from './SubmitStep.module.scss';
import { useFetchRuleExists } from '../../../data/rule-exists/useFetchRuleExists';
import { useFilterData } from '../../../data/filter-data/useFilterData';
import { useFormState } from '../../../data/form-state/useFormState';
import { usePostCreateRule } from '../../../data/create-rule/usePostCreateRule';
import { usePutEditRule } from '../../../data/edit-rule/usePutEditRule';
import { useRuleFormContext } from '../RuleForm';
import { useRules } from '../../../data/rules-data/ruleData.context';

import { Button, Grid, GridCol, Heading, Popup, PopupSection } from '@flixbus/honeycomb-react';
import { Icon, IconArrowLeft, IconCheckmarkStrong, IconCrossed } from '@flixbus/honeycomb-icons-react';

import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

type PopupType = 'success' | 'error' | 'hidden';

type Props = {
    isEditing?: boolean;
};

const SubmitStep: React.FC<Props> = ({ isEditing = false }) => {
    // Get filter data
    const { addNewTag } = useFilterData();

    // Get form context state
    const {
        goPrevStep,
        ruleId,
        cityPairs,
        includedDates,
        excludedDates,
        daysOfTheWeek,
        daysBeforeDeparture,
        alpha,
        title,
        setTitle,
        tags,
        setTags,
        active,
        setActive,
    } = useFormState();

    // Set internal state
    const [validTitle, setValidTitle] = useState<boolean | undefined>(undefined);
    const [infoErrorTitle, setInfoErrorTitle] = useState<string>('');
    const [submitRuleIsLoading, setSubmitRuleIsLoading] = useState<boolean>(false);
    const [submitRuleFinished, setSubmitRuleFinished] = useState<boolean>(false);
    const [submitRuleError, setSubmitRuleError] = useState<string>('');
    const [popupType, setPopupType] = useState<PopupType>('hidden');
    const [popupActive, setPopupActive] = useState<boolean>(false);
    const { createRule } = usePostCreateRule();
    const { editRule } = usePutEditRule();
    const { checkRuleExists } = useFetchRuleExists();
    const { refreshRulesData } = useRules();
    const ruleCTX = useRuleFormContext();

    // Validate title
    useEffect(() => {
        if (validTitle !== false) {
            if (title.length === 0) {
                setValidTitle(undefined);
            } else if (title.length > MAX_TITLE_LENGTH) {
                setValidTitle(false);
                setInfoErrorTitle(`The maximum title length is ${MAX_TITLE_LENGTH} characters`);
            } else if (title.length > 0) {
                setValidTitle(true);
            }
        }
    }, [title, validTitle]);
    useEffect(() => {
        if (validTitle === false) {
            setValidTitle(true);
            setInfoErrorTitle('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title]);

    const onClickCreateOrEdit = async () => {
        if (validTitle) {
            if (!title) {
                setValidTitle(false);
                setInfoErrorTitle('This is a mandatory field');
                window.scrollTo(0, 0);
            } else {
                // Title must not be unique while editing existing rules
                const ruleExists = isEditing ? false : await checkRuleExists(title);

                if (ruleExists) {
                    setValidTitle(false);
                    setInfoErrorTitle('The title name must be unique');
                } else {
                    setSubmitRuleIsLoading(true);
                    ruleCTX.setIsCreating(true);

                    const [success, errorMessage] = isEditing
                        ? await editRule(
                              ruleId,
                              title,
                              tags,
                              active,
                              cityPairs,
                              includedDates,
                              excludedDates,
                              daysOfTheWeek,
                              daysBeforeDeparture,
                              alpha,
                          )
                        : await createRule(
                              title,
                              tags,
                              active,
                              cityPairs,
                              includedDates,
                              excludedDates,
                              daysOfTheWeek,
                              daysBeforeDeparture,
                              alpha,
                          );

                    setSubmitRuleIsLoading(false);

                    if (success) {
                        // Add the new tags to the filter data
                        tags.forEach((tag) => addNewTag(tag));
                        refreshRulesData();
                        setPopupType('success');
                        setPopupActive(true);
                        setSubmitRuleFinished(true);
                        reportTurboTouchRuleSubmitted(isEditing);
                    } else {
                        setPopupActive(true);
                        setPopupType('error');
                        setSubmitRuleError(errorMessage);
                    }
                }
            }
        }
    };

    const successPopup = (
        <Popup id="success-popup" active={popupActive}>
            <PopupSection type="icon">
                <Icon InlineIcon={IconCheckmarkStrong} size={8} />
            </PopupSection>
            <PopupSection type="title">
                <Heading size={2}>Success!</Heading>
            </PopupSection>
            <PopupSection type="content">
                The rule {title} has been {isEditing ? 'edited' : 'created'} and it will be applied as soon as possible.
            </PopupSection>
            <PopupSection type="actions">
                <Link to="/">
                    <Button appearance={'primary'} onClick={() => ruleCTX.setConfirmedNavigation(true)}>
                        Finish
                    </Button>
                </Link>
            </PopupSection>
        </Popup>
    );

    const errorPopup = (
        <Popup id="error-popup" active={popupActive}>
            <PopupSection type="icon">
                <Icon InlineIcon={IconCrossed} size={8} />
            </PopupSection>
            <PopupSection type="title">
                <Heading size={2}>{isEditing ? 'Editing' : 'Creation'} failed!</Heading>
            </PopupSection>
            <PopupSection type="content">
                Error: &quot;{submitRuleError}&quot; happened while {isEditing ? 'editing' : 'creating'} rule {title}.
            </PopupSection>
            <PopupSection type="actions">
                <Link to="/">
                    <Button appearance="primary" onClick={() => ruleCTX.setConfirmedNavigation(true)}>
                        Leave
                    </Button>
                </Link>
                <Button
                    extraClasses={styles.popupButton}
                    appearance="tertiary"
                    onClick={() => {
                        setPopupActive(false);
                        ruleCTX.setIsCreating(false);
                    }}
                >
                    Stay
                </Button>
            </PopupSection>
        </Popup>
    );

    return (
        <>
            <Grid align="top">
                <GridCol key="confirm-step-column1" size={7}>
                    <Grid>
                        <GridCol size={12}>
                            <Button link extraClasses={styles.backButton} onClick={goPrevStep}>
                                <Icon InlineIcon={IconArrowLeft} /> Back
                            </Button>
                        </GridCol>
                        <GridCol key="general-info-box" size={12}>
                            <GeneralInfoBox
                                title={title}
                                onTitleChange={setTitle}
                                tags={tags}
                                onTagsChange={setTags}
                                active={active}
                                onActiveChange={setActive}
                                validTitle={validTitle}
                                infoErrorTitle={infoErrorTitle}
                            />
                        </GridCol>
                        <GridCol key="overlap-info-box" size={12}>
                            <OverlapsSummary />
                        </GridCol>
                    </Grid>
                </GridCol>
                <GridCol key="confirm-step-column2" size={5} extraClasses={styles.summaryCol}>
                    <Grid>
                        <GridCol key="summary-box" size={12}>
                            <RuleSummary
                                cityPairs={cityPairs}
                                includedDates={includedDates}
                                excludedDates={excludedDates}
                                daysOfTheWeek={daysOfTheWeek}
                                daysBeforeDeparture={daysBeforeDeparture}
                                alpha={alpha}
                            />
                        </GridCol>
                        <GridCol size={3}>
                            <ruleCTX.CancelButton />
                        </GridCol>
                        <GridCol size={9}>
                            <Button
                                extraClasses={styles.createOrEditButton}
                                display="block"
                                appearance="primary"
                                loading={submitRuleIsLoading}
                                disabled={submitRuleIsLoading || submitRuleFinished}
                                onClick={onClickCreateOrEdit}
                            >
                                {isEditing ? 'Edit Rule' : 'Create Rule'}
                            </Button>
                        </GridCol>
                    </Grid>
                </GridCol>
            </Grid>
            {
                /* Below here: creation status information popups */
                {
                    success: successPopup,
                    error: errorPopup,
                    hidden: undefined,
                }[popupType]
            }
        </>
    );
};

export default SubmitStep;
