import styles from './RuleForm.module.scss';
import { useFilterData } from '../../data/filter-data/useFilterData';
import { useFormState } from '../../data/form-state/useFormState';
import { useNavigationBlocker } from '../../utils/hooks/useNavigationBlocker';

import { APP_PATHS, steps } from '../../constants';
import {
    Button,
    Grid,
    GridCol,
    Heading,
    MainWrapper,
    PageContainer,
    Popup,
    PopupSection,
    ProgressTracker,
    ProgressTrackerItem,
    Spinner,
} from '@flixbus/honeycomb-react';
import { Icon, IconAttention } from '@flixbus/honeycomb-icons-react';
import { Link, Location, Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

type RuleFormContext = {
    setConfirmedNavigation: React.Dispatch<React.SetStateAction<boolean>>;
    setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
    CancelButton: () => JSX.Element;
};
export function useRuleFormContext() {
    return useOutletContext<RuleFormContext>();
}

const RuleForm = () => {
    const { loading } = useFilterData();
    const navigate = useNavigate();
    const currLocation = useLocation();
    // Get form context state
    const { currentStep, goToFilterStep, goToInfluenceStep, goToSubmitStep, initializeForm } = useFormState();

    // Canceling creation
    const [isCancelling, setIsCancelling] = useState<boolean>(false);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(false);

    function initializeWithFilterStep() {
        if (
            currentStep === undefined ||
            currLocation.pathname.endsWith(APP_PATHS.FORM_CREATE) ||
            currLocation.pathname.endsWith(APP_PATHS.FORM_CLONE) ||
            currLocation.pathname.endsWith(APP_PATHS.FORM_EDIT)
        ) {
            navigate(`${APP_PATHS.FORM_CREATE}/${APP_PATHS.FORM_STEP_FILTER}`);
        }
    }
    useEffect(initializeWithFilterStep, [currentStep, navigate, currLocation.pathname]);

    // Handle navigation blocking prompt
    const [lastLocation, setLastLocation] = useState<any>(null);

    function leaveForm() {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.location.pathname);
            initializeForm();
        }
    }
    useEffect(leaveForm, [confirmedNavigation, lastLocation, navigate, leaveForm]);

    const preventLeavingForm = useCallback(
        (transition: { retry: () => void; location: Location }) => {
            const isLeavingForm = !(
                transition.location.pathname.includes(APP_PATHS.FORM_CREATE) ||
                transition.location.pathname.includes(APP_PATHS.FORM_CLONE) ||
                transition.location.pathname.includes(APP_PATHS.FORM_EDIT)
            );
            if (isLeavingForm) {
                if (!confirmedNavigation) {
                    if (!isCreating) {
                        setIsCancelling(true);
                    }
                    setLastLocation(transition);
                    return false;
                }
                return true;
            } else {
                transition.retry();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [confirmedNavigation, isCreating, currLocation.pathname],
    );
    useNavigationBlocker(preventLeavingForm, true);

    function CancelButton() {
        return (
            <Link to="/">
                <Button link display="block" extraClasses={styles.cancelButton} onClick={() => setIsCancelling(true)}>
                    Cancel
                </Button>
            </Link>
        );
    }

    return loading ? (
        <div className={styles.spinnerContainer}>
            <Spinner size="xl" />
        </div>
    ) : (
        <MainWrapper full extraClasses={styles.formContainer}>
            <PageContainer>
                <Grid>
                    <GridCol key="step-progress-tracker" size={12}>
                        <ProgressTracker extraClasses={styles.progressTracker}>
                            {steps.map((step, idx) => (
                                <ProgressTrackerItem
                                    onClick={[goToFilterStep, goToInfluenceStep, goToSubmitStep][idx]}
                                    completedSrText="completed"
                                    extraClasses={styles.pressable}
                                    key={idx}
                                    completed={currentStep > idx}
                                    current={idx === currentStep}
                                >
                                    {step.text}
                                </ProgressTrackerItem>
                            ))}
                        </ProgressTracker>
                    </GridCol>
                    <GridCol key="current-step" size={12}>
                        <Outlet context={{ setConfirmedNavigation, setIsCreating, CancelButton }} />
                    </GridCol>
                </Grid>
                {/* Below here: cancelation confirmation popup */}
                {isCancelling && (
                    <Popup
                        id="popup-second-example"
                        active={isCancelling}
                        onOverlayClick={() => setIsCancelling(false)}
                    >
                        <PopupSection type="icon">
                            <Icon InlineIcon={IconAttention} size={8} />
                        </PopupSection>
                        <PopupSection type="title">
                            <Heading size={2}>Cancel creation</Heading>
                        </PopupSection>
                        <PopupSection type="content">
                            Are you sure you want to leave the creation of the new rule?
                        </PopupSection>
                        <PopupSection type="actions">
                            <Button appearance="primary" onClick={() => setConfirmedNavigation(true)}>
                                Leave
                            </Button>
                            <Button
                                extraClasses={styles.popupButton}
                                appearance="tertiary"
                                onClick={() => setIsCancelling(false)}
                            >
                                Stay
                            </Button>
                        </PopupSection>
                    </Popup>
                )}
            </PageContainer>
        </MainWrapper>
    );
};

export default RuleForm;
